import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Col, Container, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { IPortfolioBudget, list } from "../../../models/portfolioBudget";

import * as assumptions from "../../../models/userAssumption";
import { IMaskInput } from "react-imask";
import { percentBlock } from "../../../models/constants";
import { ProjectionComponent } from "./projection";

import { ETFProjectionComponent } from "./etfProjection";
import { DisclaimerComponent } from "../../../components/disclaimer";
import { PPORProjectionComponent } from "./pporProjection";
import { faInfoCircle, faLineChart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrbitProgress } from "react-loading-indicators";

interface PortfolioProjectionPageProps extends BaseProps {}

export interface Amortisation {
  monthlyPayment: number;
  interest: number;
  principal: number;
  balance: number;
}

export const PortfolioProjectionPage = (props: PortfolioProjectionPageProps) => {
  const [portfolios, setPortfolios] = useState<IPortfolioBudget[]>([]);
  const [portfolio, setPortfolio] = useState<IPortfolioBudget>();
  const [assumption, setAssumption] = useState<assumptions.IUserAssumption>();
  const [loading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (portfolio) {
      let all = [...portfolios];
      let index = all?.findIndex((z) => z?.id === portfolio.id);
      if (index !== -1) {
        all[index] = portfolio;
      } else {
        all.push(portfolio);
      }
      setPortfolios(all);
    }
  }, [portfolio]);

  useEffect(() => {
    if (props.profile?.id) {
      list(props.profile?.id).then((response) => {
        let portfolios: IPortfolioBudget[] = response.data?.portfolioBudgets as IPortfolioBudget[];
        setPortfolios(portfolios);
        assumptions.get(props.profile?.id).then((assumptionResponse) => {
          let assumption: assumptions.IUserAssumption = assumptionResponse.data.userAssumption;
          if (assumption === null) {
            assumptions.create({ ownerId: props.profile?.id }).then(() => {
              assumptions.get(props.profile?.id).then((assumptionResponse) => {
                setAssumption(assumptionResponse.data.userAssumption);
              });
            });
          } else {
            setAssumption(assumption);
          }
          setIsLoading(false);
        });
      });
      let q = localStorage.getItem("prj");
      if (q) {
        setActiveTab(parseInt(q));
      }
    }
  }, [props.profile]);

  useEffect(() => {
    if (portfolios && portfolios.length > 0) {
      localStorage.setItem("prj", activeTab?.toString());
    }
  }, [activeTab]);

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Portfolio projections | Asset manager</title>
      </Helmet>

      <Container className="mt-5 navbarContainer">
        <>
          <h1>
            <FontAwesomeIcon className="mr-2" icon={faLineChart}></FontAwesomeIcon>Portfolio Projections
          </h1>
          <hr />
          <Alert color="info">
            <p>
              <FontAwesomeIcon className="mr-2" icon={faInfoCircle}></FontAwesomeIcon>This page will show your estimated projections on all the investment{" "}
              <u>
                <a href="/portfolio-budget">portfolios</a>
              </u>{" "}
              you have created so far.
            </p>
          </Alert>
          <Row className="mb-3">
            <Col>
              <Label for="txtAnnualAssumption">Estimated anual property price appreciation</Label>
              <IMaskInput
                id="txtAnnualAssumption"
                className="form-control"
                value={assumption?.annualAppreciation?.toString()}
                placeholder={"0%"}
                mask="num %"
                lazy={false}
                unmask={true}
                blocks={percentBlock}
                onAccept={async (value: string) => {
                  try {
                    let a = { ...assumption };
                    a.annualAppreciation = value ? parseFloat(value) : 0;
                    if (a.annualAppreciation !== assumption.annualAppreciation) {
                      await assumptions.update(a);
                      setAssumption(a);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
              ></IMaskInput>
            </Col>
            <Col>
              <Label for="txtAnnualRentRise">Estimated anuall rent rise</Label>
              <IMaskInput
                id="txtAnnualRentRise"
                className="form-control"
                value={assumption?.annualRentRise?.toString()}
                placeholder={"0%"}
                mask="num %"
                lazy={false}
                unmask={true}
                blocks={percentBlock}
                onAccept={async (value: string) => {
                  try {
                    let a = { ...assumption };
                    a.annualRentRise = value ? parseFloat(value) : 0;
                    if (a.annualRentRise !== assumption.annualRentRise) {
                      await assumptions.update(a);
                      setAssumption(a);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
              ></IMaskInput>
            </Col>
          </Row>
          <Nav tabs>
            {portfolios?.map((x, i) => {
              return (
                <NavItem key={i}>
                  <NavLink href="#" active={activeTab == i} onClick={() => setActiveTab(i)}>
                    {x.address}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
          <TabContent activeTab={activeTab}>
            {portfolios.length > 0 &&
              assumption &&
              portfolios?.map((x, i) => {
                return (
                  <TabPane key={i} tabId={i}>
                    {x.investmentType === "Investment" && <ProjectionComponent {...props} portfolio={x} assumption={assumption} />}
                    {x.investmentType === "PPOR" && <PPORProjectionComponent {...props} portfolio={x} assumption={assumption} />}
                    {x.investmentType === "Stocks/ETF" && <ETFProjectionComponent {...props} portfolio={x} assumption={assumption} />}
                  </TabPane>
                );
              })}
          </TabContent>
        </>
        <DisclaimerComponent />
      </Container>
    </>
  );
};
