import React, { useEffect, useState } from "react";
import { ISuburbSelection, update } from "../../../models/suburbSelection";
import { Input } from "reactstrap";

interface HistoricRationaleControlProps {
  suburbSelection: ISuburbSelection;
}

export const HistoricRationaleControl = (props: HistoricRationaleControlProps) => {
  const [suburbSelection, setSuburbSelection] = useState<ISuburbSelection>(props.suburbSelection);
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
  useEffect(() => {
    if (triggerUpdate && suburbSelection.historicTrendRationale !== props.suburbSelection.historicTrendRationale) {
      update(suburbSelection).then(() => setTriggerUpdate(false));
    }
  }, [triggerUpdate]);

  return (
    <Input
      title={suburbSelection.historicTrendRationale}
      type="textarea"
      value={suburbSelection.historicTrendRationale}
      onChange={(e) => {
        e.persist();
        setSuburbSelection((x) => ({ ...x, historicTrendRationale: e.target.value }));
      }}
      onBlur={() => setTriggerUpdate(true)}
    ></Input>
  );
};
