import axios from "axios";
import { headers, jwt } from "./constants";
import * as yup from "yup";

export interface IUserPropertyValuation {
  id?: string;
  name?: string;
  ownerId?: string;
  sortOrder?: number;
}

export const userPropertyValuationSchema = yup.object().shape({
  name: yup.string().nullable().required("Name is required"),
});

export let list = async (ownerId: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/userPropertyValuation/list/", {
      headers: headers,
      params: { ownerId: ownerId },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let get = async (id: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/userPropertyValuation/get/", {
      headers: headers,
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let create = async (userPropertyValuation: IUserPropertyValuation) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/userPropertyValuation/create",
      { data: userPropertyValuation },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let update = async (userPropertyValuation: IUserPropertyValuation) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/userPropertyValuation/update",
      { data: userPropertyValuation },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let deleteOne = async (userPropertyValuation: IUserPropertyValuation) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/userPropertyValuation/delete",
      { data: userPropertyValuation },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};
