import React, { Fragment, useEffect, useState } from "react";
import { Table } from "reactstrap";

import { ISuburbSelection, list } from "../../../models/suburbSelection";
import { periorityMapping } from "../../../models/suburbVariable";
import { TargetControl } from "./targetControl";
import { TargetRationaleControl } from "./targetRationaleControl";
import { HistoricControl } from "./historicControl";
import { HistoricRationaleControl } from "./historicRationaleControl";

interface SuburbShorlistingControlProps {
  userSuburbSelectionId: string;
}

export const SuburbShorlistingControl = (props: SuburbShorlistingControlProps) => {
  const [suburbSelections, setsuburbSelections] = useState<ISuburbSelection[]>([]);

  useEffect(() => {
    list(props.userSuburbSelectionId).then((response) => {
      let suburbSelections: ISuburbSelection[] = response.data.suburbSelections;
      setsuburbSelections(suburbSelections);
    });
  }, []);

  return (
    <>
      <Table responsive striped bordered>
        <colgroup>
          <col className="col-md-1"></col>
          <col className="col-md-1"></col>
          <col className="col-md-2"></col>
          <col className="col-md-2"></col>
          <col className="col-md-2"></col>
          <col className="col-2"></col>
          <col className="col-2"></col>
        </colgroup>
        <thead>
          <tr className="bg-info text-light">
            <th colSpan={3} style={{ verticalAlign: "top" }}>
              Suburb selection worksheet
              <br /> (only fill this out for the top 10-15 suburbs out of the DSR Tool process)
            </th>
            <th colSpan={4} style={{ verticalAlign: "top" }}>
              Overall, relative to the other suburbs in the shortlist I would rate this suburb: [x/10] Why: [insert key pro's & cons]
            </th>
          </tr>
          <tr>
            <th>Supply or Demand</th>
            <th>Quantitative or Qualitative</th>
            <th>Factors</th>
            <th>Meets target?</th>
            <th>Rationale and observations</th>
            <th> Is historical trend encouraging?</th>
            <th>Rationale and observations</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(periorityMapping).map((key, j) => {
            {
              let filteredSelection = suburbSelections?.filter((z) => z.priority === key);
              return (
                <Fragment key={j}>
                  <tr>
                    <td className="bg-secondary text-light" colSpan={7}>
                      {periorityMapping[key]}
                    </td>
                  </tr>
                  {filteredSelection &&
                    filteredSelection.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td>{x.supplyOrDemand}</td>
                          <td>{x.type}</td>
                          <td>{x.name}</td>
                          <TargetControl suburbSelection={x} />
                          <td>{<TargetRationaleControl suburbSelection={x} />}</td>
                          <HistoricControl suburbSelection={x} />
                          <td>{<HistoricRationaleControl suburbSelection={x} />}</td>
                        </tr>
                      );
                    })}
                </Fragment>
              );
            }
          })}
        </tbody>
      </Table>
    </>
  );
};
