import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Alert,
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { IPortfolioBudget, create, deleteOne, list, portfolioBudgetSchema } from "../../../models/portfolioBudget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight, faHouseCircleCheck, faInfoCircle, faPlus, faSave, faStop } from "@fortawesome/free-solid-svg-icons";
import { IPortfolioOwner, portfolioOwnerSchema, create as createPortfolioOwner, list as listPortfolioOwners } from "../../../models/portfolioOwner";
import { PortfolioOwnerComponent } from "./portfolio-owner";
import { ICalculatedPortfolio, PortfolioComponent } from "./portfolio";
import { DepreciationComponent } from "./depreciation";

import { PPORComponent } from "./ppor";
import { ETFComponent } from "./etf";
import { DisclaimerComponent } from "../../../components/disclaimer";
import { OrbitProgress } from "react-loading-indicators";

interface PortfolioBudgetPageProps extends BaseProps {}

export const PortfolioBudgetPage = (props: PortfolioBudgetPageProps) => {
  const [portfolioOwners, setPortfolioOwners] = useState<IPortfolioOwner[]>([]);
  const [portfolioOwner, setPortfolioOwner] = useState<IPortfolioOwner>();
  const [portfolios, setPortfolios] = useState<IPortfolioBudget[]>([]);
  const [portfolio, setPortfolio] = useState<IPortfolioBudget>({ investmentType: "Investment" });

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [portfolioOwnerModalIsOpen, setPortfolioOwnerModalIsOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<IPortfolioBudget>({});
  const [portfolioOwnerErrors, setPortfolioOwnerErrors] = useState<IPortfolioOwner>({});
  const [activeTab, setActiveTab] = useState<number>(-1);
  const [calculatedPortfolios, setCalculatedPortfolios] = useState<ICalculatedPortfolio[]>([]);
  const [calculatedPortfolio, setCalculatedPortfolio] = useState<ICalculatedPortfolio>();
  const [addressLabel, setAddressLabel] = useState<string>("Address");

  useEffect(() => {
    if (calculatedPortfolio) {
      let allCalculated = [...calculatedPortfolios];
      let index = allCalculated?.findIndex((z) => z?.id === calculatedPortfolio.id);
      if (index !== -1) {
        allCalculated[index] = calculatedPortfolio;
      } else {
        allCalculated.push(calculatedPortfolio);
      }
      setCalculatedPortfolios(allCalculated);
    }
  }, [calculatedPortfolio]);

  useEffect(() => {
    if (portfolio) {
      let all = [...portfolios];
      let index = all?.findIndex((z) => z?.id === portfolio.id);
      if (index !== -1) {
        all[index] = portfolio;
      } else {
        all.push(portfolio);
      }
      setPortfolios(all);
    }
  }, [portfolio]);

  useEffect(() => {
    if (props.profile?.id) {
      listPortfolioOwners(props.profile?.id).then((response) => {
        let portfolioOwners: IPortfolioOwner[] = response.data?.portfolioOwners;
        setPortfolioOwners(portfolioOwners);
      });
    }
  }, [props.profile]);

  useEffect(() => {
    if (props.profile?.id) {
      list(props.profile?.id).then((response) => {
        let portfolios: IPortfolioBudget[] = response.data?.portfolioBudgets as IPortfolioBudget[];
        let investmentPortfolios = portfolios.filter((x) => x.investmentType === "Investment");
        setPortfolios(portfolios);
        let calculatedPortfolios: ICalculatedPortfolio[] = [];
        investmentPortfolios?.map((x) => {
          let c = {
            id: x.id,
            portfolioOwnerId: x.portfolioOwnerId,
            loanPercentage: 0,
            loanPreLMI: 0,
            loanPostLMI: 0,
            depositAmount: 0,
            totalCapitalRequired: 0,
            annualPropertyManagementFee: 0,
            annualInitialLettingFee: 0,
            annualEstimatedTotals: 0,
            annualInterestPayment: 0,
            annualVacancy: 0,
            annualMaintanance: 1500,
            annualTotalExpense: 0,
            annualLowerRentCashflow: 0,
            annualHigherRentCashflow: 0,
            annualLowerRentProfitBeforeTax: 0,
            annualHigherRentProfitBeforeTax: 0,
            annualLowerRentProfitAfterTax: 0,
            annualHigherRentProfitAfterTax: 0,
            annualHigherRent: 0,
          };
          calculatedPortfolios.push(c);
        });
        setCalculatedPortfolios(calculatedPortfolios);
        setIsLoading(false);
      });
      let q = localStorage.getItem("pbt");
      if (q) {
        setActiveTab(parseInt(q));
      }
    }
  }, [props.profile]);

  useEffect(() => {
    if (!loading) {
      localStorage.setItem("pbt", activeTab?.toString());
    }
  }, [activeTab]);

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  return (
    <>
      <Modal isOpen={portfolioOwnerModalIsOpen} toggle={(x) => setPortfolioOwnerModalIsOpen(x != x)}>
        <ModalHeader>Create a new portfolio owner</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="txtPortFolioOwnerName">Name</Label>
              {portfolioOwnerErrors?.name && (
                <>
                  <br />
                  <span className="text-danger">{portfolioOwnerErrors.name}</span>
                </>
              )}
              <Input
                id="txtPortFolioOwnerName"
                value={portfolioOwner?.name}
                onChange={(e) => {
                  e.persist();
                  setPortfolioOwner((x) => ({ ...x, name: e.target.value }));
                }}
              ></Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await portfolioOwnerSchema.validateSync(portfolioOwner, {
                  abortEarly: false,
                });
                await createPortfolioOwner(portfolioOwner);
                window.location.reload();
              } catch (yupErrors) {
                let errors: IPortfolioOwner = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setPortfolioOwnerErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
            Save
          </Button>
          <Button color="secondary" onClick={() => setPortfolioOwnerModalIsOpen(false)}>
            <FontAwesomeIcon icon={faStop} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalIsOpen} toggle={(x) => setModalIsOpen(x != x)}>
        <ModalHeader>Create a new portfolio budget</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="txtAddressCreate">{addressLabel}</Label>
              {errors?.address && (
                <>
                  <br />
                  <span className="text-danger">{errors.address}</span>
                </>
              )}
              <Input
                id="txtAddressCreate"
                value={portfolio?.address}
                onChange={(e) => {
                  e.persist();
                  setPortfolio((x) => ({ ...x, address: e.target.value }));
                }}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label for="ddlPortFolioOwnerCreate">Portfolio Owner</Label>
              {errors?.portfolioOwnerId && (
                <>
                  <br />
                  <span className="text-danger">{errors.portfolioOwnerId}</span>
                </>
              )}
              <select
                className="form-control"
                id="ddlPortFolioOwnerCreate"
                value={portfolio?.portfolioOwnerId}
                onChange={(e) => {
                  e.persist();
                  setPortfolio((x) => ({ ...x, portfolioOwnerId: e.target.value }));
                }}
              >
                <option value={null}>Select...</option>
                {portfolioOwners?.map((x, i) => {
                  return (
                    <option key={i} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label for="ddlType">Investment type</Label>
              <select
                defaultValue={"Investment"}
                className="form-control"
                onChange={(e) => {
                  e.persist();
                  if (e.target.value === "Stocks/ETF") {
                    setAddressLabel("Name");
                  } else {
                    setAddressLabel("Address");
                  }
                  setPortfolio((x) => ({ ...x, investmentType: e.target.value as any }));
                }}
              >
                <option value={"Investment"}>Investment property</option>
                <option value={"PPOR"}>Primary place of resicence</option>
                <option value={"Stocks/ETF"}>Stocks or ETF</option>
              </select>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await portfolioBudgetSchema.validateSync(portfolio, {
                  abortEarly: false,
                });
                await create(portfolio);
                window.location.reload();
              } catch (yupErrors) {
                let errors: IPortfolioBudget = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
            Save
          </Button>
          <Button color="secondary" onClick={() => setModalIsOpen(false)}>
            <FontAwesomeIcon icon={faStop} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Portfolio summary | Asset manager</title>
      </Helmet>

      <Container className="mt-5 navbarContainer">
        <>
          <h1>
            <FontAwesomeIcon className="mr-2" icon={faHouseCircleCheck}></FontAwesomeIcon> Portfolio Summary
          </h1>
          <hr />
          {portfolioOwners?.length === 0 && (
            <Alert color="info">
              <p>
                <FontAwesomeIcon className="mr-2" icon={faHandPointRight}></FontAwesomeIcon>Start by adding a portfolio owner
                <br />
                <small className="">
                  <FontAwesomeIcon className="mr-2" icon={faInfoCircle}></FontAwesomeIcon>A portfolio owner can be you, your spouse or can be held together as a
                  joint ownership with your spouse or someone else
                </small>
              </p>
            </Alert>
          )}
          {portfolioOwners?.length > 0 && portfolios?.length === 0 && (
            <Alert color="info">
              <p>
                <FontAwesomeIcon className="mr-2" icon={faHandPointRight}></FontAwesomeIcon>Add a new investment by clicking on the Add a new portfolio button
                <br />
              </p>
            </Alert>
          )}
          <Button
            color="primary"
            className="mb-2"
            onClick={() => {
              setErrors({});
              setPortfolioOwner({
                ownerId: props.profile?.id,
              });
              setPortfolioOwnerModalIsOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>Add a new portfolio owner
          </Button>
          {portfolioOwners?.length > 0 && (
            <Button
              color="primary"
              className="mb-2 ml-2"
              onClick={() => {
                setErrors({});
                setPortfolio({ ownerId: props.profile?.id, defaultRentYeild: "High", investmentType: "Investment" });
                setModalIsOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>Add a new portfolio
            </Button>
          )}
          {portfolios?.length > 0 && portfolios?.findIndex((x) => x.id) !== -1 && (
            <>
              <Nav tabs>
                <NavItem>
                  <NavLink href="#" active={activeTab == -1} onClick={() => setActiveTab(-1)}>
                    Portfolio summary
                  </NavLink>
                </NavItem>
                {portfolios?.map((x, i) => {
                  return (
                    <NavItem key={i}>
                      <NavLink href="#" active={activeTab == i} onClick={() => setActiveTab(i)}>
                        {x.address}
                      </NavLink>
                    </NavItem>
                  );
                })}
                {/* <NavItem>
              <NavLink href="#" active={activeTab == -2} onClick={() => setActiveTab(-2)}>
                Depreciation
              </NavLink>
            </NavItem> */}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={-1}>
                  <PortfolioOwnerComponent
                    portfolioOwners={portfolioOwners}
                    portfolios={portfolios.filter((x) => x.investmentType === "Investment")}
                    calculatedPortfolios={calculatedPortfolios}
                    profile={props.profile}
                  />
                </TabPane>
                {portfolios.length > 0 &&
                  portfolios?.map((x, i) => {
                    let calculatedPortfolio = calculatedPortfolios?.find((z) => z?.id === x.id);
                    return (
                      <TabPane key={i} tabId={i}>
                        {x.investmentType === "Investment" && (
                          <PortfolioComponent
                            profile={props.profile}
                            portfolio={x}
                            calculatedPortfolio={calculatedPortfolio}
                            portfolioOwners={portfolioOwners}
                            onChange={(p, c) => {
                              setTimeout(() => {
                                setPortfolio(p);
                                setCalculatedPortfolio(c);
                              }, 0);
                            }}
                          ></PortfolioComponent>
                        )}
                        {x.investmentType === "PPOR" && <PPORComponent profile={props.profile} portfolio={x} portfolioOwners={portfolioOwners}></PPORComponent>}
                        {x.investmentType === "Stocks/ETF" && (
                          <ETFComponent profile={props.profile} portfolio={x} portfolioOwners={portfolioOwners}></ETFComponent>
                        )}
                      </TabPane>
                    );
                  })}
                {/* <TabPane tabId={-2}>
              <DepreciationComponent profile={props.profile} portfolios={portfolios} />
            </TabPane> */}
              </TabContent>
            </>
          )}
        </>
        <DisclaimerComponent />
      </Container>
    </>
  );
};
