import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Col, Row } from "reactstrap";
import "./sticky.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

export interface StickySaveProps {
  onClick: () => void;
}

export const StickySave = (props: StickySaveProps) => {
  const [scrolled, setScrolled] = React.useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  return (
    <>
      <Alert className={`navigation ${scrolled ? "scrolled" : ""}`} id="navbar" color="warning">
        <Row>
          <Col> You have unsaved changes</Col>
          <Col className="text-right">
            <Button
              color="primary"
              onClick={async () => {
                props.onClick();
              }}
            >
              <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>Save
            </Button>
          </Col>
        </Row>
      </Alert>
    </>
  );
};
