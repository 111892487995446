import axios from "axios";
import { headers, jwt } from "./constants";
import * as yup from "yup";

export const numberOfWeeks: number = 52;
export const numberOfMonths: number = 12;

export interface IPortfolioBudget {
  id?: string;
  address?: string;
  defaultRentYeild?: string;
  valuation?: number;
  purchasePrice?: number;
  lendersMortgageInsurance?: number;
  stampDuty?: number;
  legalFees?: number;
  renovationCost?: number;
  bankCheckFee?: number;
  settlementFee?: number;
  depreciationSchedule?: number;
  buildingAndPestInspection?: number;
  waterEfficiencyTest?: number;
  cleaningCost?: number;
  removalistFee?: number;
  buyersAgentFee?: number;
  registrationAndTransferFee?: number;
  bankLegalFee?: number;
  bankRegistrationAndDischargeFee?: number;
  deposit?: number;
  propertyManagement?: number;
  interestRate?: number;
  taxRate?: number;
  settlementDate?: Date;
  bankName?: string;
  interestOnlyPeriod?: number;
  pAndIPeriod?: number;
  buildingInsuranceNumber?: string;
  landLordInsuranceNumber?: string;
  propertyManagementAgency?: string;
  propertyManager?: string;
  agencyNumber?: string;
  agentNumber?: string;
  agentEmail?: string;
  terminationPeriod?: string;
  annualCouncilRate?: number;
  annualBuildingInsurance?: number;
  annualLandlordInsurance?: number;
  annualAdvertisingFee?: number;
  monthlyAdminFee?: number;
  annualPoolMaintananceFee?: number;
  annualBankCharges?: number;
  annualBankProPackFee?: number;
  weeklyLowerRent?: number;
  weeklyHigherRent?: number;
  annualDepreciation?: number;
  insuranceStartDate?: Date;
  insuranceEndDate?: Date;
  leaseStartDate?: Date;
  leaseEndDate?: Date;
  termiteInspectionDueDate?: Date;
  lastInspectionDate?: Date;
  lastAirConServiceDate?: Date;
  investmentType?: "Investment" | "PPOR" | "Stocks/ETF";
  etfPrincipalAmount?: number;
  etfRegularAmount?: number;
  etfRegularAmountFrequency?: string;
  etfInvestmentYears?: number;
  offsetAmount?: number;
  ownerId?: string;
  portfolioOwnerId?: string;
  sortOrder?: number;
}

export const portfolioBudgetSchema = yup.object().shape({
  address: yup.string().nullable().required("Address is required"),
  portfolioOwnerId: yup.string().nullable().required("Portfolio owner is required"),
});

export let list = async (ownerId?: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/portfolio/list/", {
      headers: headers,
      params: { ownerId: ownerId },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let get = async (id?: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/portfolio/get/", {
      headers: headers,
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let create = async (portfolio?: IPortfolioBudget) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/portfolio/create",
      { data: portfolio },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let update = async (portfolio?: IPortfolioBudget) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/portfolio/update",
      { data: portfolio },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let deleteOne = async (portfolio?: IPortfolioBudget) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/portfolio/delete",
      { data: portfolio },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export const getAnnualPropertyManagementFee = (portfolio: IPortfolioBudget) => {
  let annualPropertyManagementFee =
    portfolio?.defaultRentYeild === "High"
      ? portfolio?.weeklyHigherRent
        ? portfolio?.weeklyHigherRent * numberOfWeeks * (portfolio?.propertyManagement ? portfolio?.propertyManagement / 100 : 0)
        : 0
      : portfolio?.weeklyLowerRent
      ? portfolio?.weeklyLowerRent * numberOfWeeks * (portfolio?.propertyManagement ? portfolio?.propertyManagement / 100 : 0)
      : 0;

  return annualPropertyManagementFee;
};

export const getAnnualInitialLettingFee = (portfolio: IPortfolioBudget) => {
  let annualInitialLettingFee =
    portfolio?.defaultRentYeild === "High"
      ? portfolio?.weeklyHigherRent
        ? portfolio?.weeklyHigherRent * 1.1
        : 0
      : portfolio?.weeklyLowerRent
      ? portfolio?.weeklyLowerRent * 1.1
      : 0;
  return annualInitialLettingFee;
};

export const getAnnualVacancy = (portfolio: IPortfolioBudget) => {
  let annualVacancy =
    portfolio?.defaultRentYeild === "High"
      ? portfolio?.weeklyHigherRent
        ? portfolio?.weeklyHigherRent * 1.5
        : 0
      : portfolio?.weeklyLowerRent
      ? portfolio?.weeklyLowerRent * 1.5
      : 0;
  return annualVacancy;
};

export const getCalculations = (portfolio: IPortfolioBudget) => {
  let loanPercentage = portfolio?.deposit ? 100 - portfolio?.deposit : 100;

  let loanPreLMI = portfolio?.purchasePrice ? portfolio.purchasePrice * (loanPercentage / 100) : 0;
  let loanPostLMI = portfolio?.lendersMortgageInsurance ? loanPreLMI + portfolio?.lendersMortgageInsurance : loanPreLMI;

  let annualInterestPayment = (portfolio?.interestRate ? portfolio?.interestRate / 100 : 0) * loanPostLMI;

  return { loanPercentage, loanPreLMI, loanPostLMI, annualInterestPayment };
};
