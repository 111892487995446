import axios from "axios";
import { headers, jwt } from "./constants";
import * as yup from "yup";

export interface IPropertyValuationGroup {
  parent: IPropertyValuation;
  valuations: IPropertyValuation[];
}

export interface IPropertyValuation {
  id?: string;
  name?: string;
  value?: string;
  userPropertyValuationId?: string;
  parentPropertyValuationId?: string;
  sortOrder?: number;
}

export const propertyValuationSchema = yup.object().shape({
  name: yup.string().nullable().required("Name is required"),
});

export let list = async (userPropertyValuationId: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/propertyValuation/list/", {
      headers: headers,
      params: { userPropertyValuationId: userPropertyValuationId },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let get = async (id: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/propertyValuation/get/", {
      headers: headers,
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let create = async (propertyValuation: IPropertyValuation) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/propertyValuation/create",
      { data: propertyValuation },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let update = async (propertyValuation: IPropertyValuation) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/propertyValuation/update",
      { data: propertyValuation },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let deleteOne = async (propertyValuation: IPropertyValuation) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/propertyValuation/delete",
      { data: propertyValuation },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};
