import React, { useEffect, useState } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave, faStop } from "@fortawesome/free-solid-svg-icons";
import { IPropertyValuation, propertyValuationSchema, create, list, update, IPropertyValuationGroup } from "../../../models/propertyValuation";
import * as v from "../../../models/valuationVariable";
import { PropertyValuationControl } from "./controls";

interface PropertyValuationPageProps extends BaseProps {
  userPropertyValuationId: string;
}

export const PropertyValuationPage = (props: PropertyValuationPageProps) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [propertyValuation, setPropertyValuation] = useState<IPropertyValuation>();
  const [propertyValuations, setPropertyValuations] = useState<IPropertyValuation[]>([]);
  const [valuationRows, setValuationRows] = useState<IPropertyValuationGroup[]>([]);
  const [valuationVariables, setValuationVariables] = useState<v.IValuationVariable[]>([]);
  const [errors, setErrors] = useState<IPropertyValuation>({});

  useEffect(() => {
    if (props.profile?.id) {
      list(props.userPropertyValuationId).then((response) => {
        let propertyValuations: IPropertyValuation[] = response.data.propertyValuations;
        let parents = propertyValuations.filter((x) => x.parentPropertyValuationId === null);
        setPropertyValuations(propertyValuations);
        let group: IPropertyValuationGroup[] = [];
        parents?.map((x) => {
          let valuations: IPropertyValuation[] = propertyValuations.filter((y) => y.parentPropertyValuationId === x.id);
          valuations.unshift(x);
          group.push({ parent: x, valuations });
        });
        setValuationRows(group);
      });

      v.list().then((response) => {
        setValuationVariables(response.data.valuationVariables);
      });
    }
  }, [props.profile]);

  return (
    <>
      <Modal isOpen={modalIsOpen} toggle={(x) => setModalIsOpen(x != x)}>
        <ModalHeader>Add a property address</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="txtName">Name</Label>
              {errors?.name && (
                <>
                  <br />
                  <span className="text-danger">{errors.name}</span>
                </>
              )}
              <Input
                id="txtName"
                value={propertyValuation?.name}
                onChange={(e) => {
                  e.persist();
                  setPropertyValuation((x) => ({ ...x, name: e.target.value }));
                }}
              ></Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await propertyValuationSchema.validateSync(propertyValuation, {
                  abortEarly: false,
                });
                if (propertyValuation?.id) {
                  await update(propertyValuation);
                } else {
                  await create(propertyValuation);
                }
                window.location.reload();
              } catch (yupErrors) {
                let errors: IPropertyValuation = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
            Save
          </Button>
          <Button color="secondary" onClick={() => setModalIsOpen(false)}>
            <FontAwesomeIcon icon={faStop} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Button
        color="primary"
        className="mb-2"
        onClick={() => {
          setErrors({});
          let sortOrder = propertyValuations.length + 1;
          setPropertyValuation({ sortOrder, userPropertyValuationId: props.userPropertyValuationId });
          setModalIsOpen(true);
        }}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>Add a new property address
      </Button>
      <Table responsive striped>
        <thead>
          <tr>
            {valuationRows[0]?.valuations?.map((x, i) => {
              return (
                <th className="bg-dark text-light align-top" style={{ minWidth: "200px" }} key={i}>
                  {x.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {valuationVariables &&
            valuationVariables.length > 0 &&
            valuationRows?.map((z, j) => {
              return (
                <tr key={j}>
                  {z?.valuations.map((x, i) => {
                    let valuationVariable = valuationVariables.find((y) => y.name === x.name);

                    return (
                      <PropertyValuationControl
                        key={i}
                        OnChange={async (value: string) => {
                          if (x.value !== value) {
                            x.value = value;
                            await update(x);
                          }
                        }}
                        valuationVariable={valuationVariable}
                        propertyValuation={x}
                      />
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};
