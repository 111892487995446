import React, { useEffect, useState } from "react";

import { Badge, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import {
  IPortfolioBudget,
  getAnnualInitialLettingFee,
  getAnnualPropertyManagementFee,
  getAnnualVacancy,
  getCalculations,
} from "../../../models/portfolioBudget";

import { IUserAssumption } from "../../../models/userAssumption";
import { Chart } from "react-google-charts";
import { Amortisation } from "./portfolio-projection";

interface ProjectionComponentProps extends BaseProps {
  portfolio: IPortfolioBudget;
  assumption: IUserAssumption;
}

interface ICalculatedRow {
  myAge?: number;
  year?: number;
  appreciation?: number;
  value?: number;
  loanBalance?: number;
  rent?: number;
  expense?: number;
}

export const ProjectionComponent = (props: ProjectionComponentProps) => {
  const numberOfMonths: number = 12;
  const annualMaintanance: number = 1500;
  const [rows, setRows] = useState<ICalculatedRow[]>([]);
  const [estimateYears, setEstimateYears] = useState<number>(10);
  var positiveRow = -1;

  useEffect(() => {
    let { annualInterestPayment } = getCalculations(props.portfolio);

    let expense =
      (props.portfolio?.annualCouncilRate || 0) +
      (props.portfolio?.annualBuildingInsurance || 0) +
      (props.portfolio?.annualLandlordInsurance || 0) +
      (getAnnualPropertyManagementFee(props.portfolio) || 0) +
      (getAnnualInitialLettingFee(props.portfolio) || 0) +
      (props.portfolio?.annualAdvertisingFee || 0) +
      (props.portfolio?.monthlyAdminFee ? props.portfolio.monthlyAdminFee * numberOfMonths : 0) +
      (getAnnualVacancy(props.portfolio) || 0) +
      (props.portfolio?.annualPoolMaintananceFee || 0) +
      (annualInterestPayment || 0) +
      annualMaintanance +
      (props.portfolio?.annualBankCharges || 0) +
      (props.portfolio?.annualBankProPackFee || 0);

    let rows: ICalculatedRow[] = [];
    var today = new Date();
    var birthDate = new Date(props.profile.dateOfBirth);
    var ageNow = today.getFullYear() - birthDate.getFullYear();
    let ammortisation = GetAmortization();
    for (let i = 0; i < estimateYears; i++) {
      let appreciation = i === 0 ? 0 : Math.round((rows[i - 1].value * props.assumption?.annualAppreciation) / 100);
      let rentAppreciation = i === 0 ? 0 : Math.round((rows[i - 1].rent * props.assumption?.annualRentRise) / 100);
      let value = i === 0 ? props.portfolio?.valuation : Math.round(rows[i - 1].value + appreciation);
      let rent = i === 0 ? Math.round(props.portfolio?.weeklyHigherRent * 52) : rows[i - 1].rent + rentAppreciation;
      let loanBalance = 0;
      if (props.portfolio?.interestOnlyPeriod > 0 && props.portfolio?.interestOnlyPeriod > i) {
        loanBalance = props.portfolio?.purchasePrice;
      } else {
        if (i < props.portfolio?.pAndIPeriod + props.portfolio?.interestOnlyPeriod - 1) {
          loanBalance = Math.round(ammortisation[i - props?.portfolio?.interestOnlyPeriod].balance);
        }
      }
      let row: ICalculatedRow = { myAge: ageNow + i, year: i + 1, appreciation, value, loanBalance, rent, expense };
      rows.push(row);
    }
    setRows(rows);
  }, [props.portfolio, props.assumption, estimateYears]);

  const GetAmortization = () => {
    let totalPeriod = props.portfolio?.pAndIPeriod;
    let monthlyInterestRate = props.portfolio?.interestRate / 100;
    let monthlyPayment =
      (props.portfolio?.purchasePrice * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPeriod)) /
      (Math.pow(1 + monthlyInterestRate, totalPeriod) - 1);
    let result: Amortisation[] = [];
    let balance = props.portfolio?.purchasePrice;
    let totalInterest = 0;
    for (let i = 0; i < totalPeriod - 1; i++) {
      let interest = balance * monthlyInterestRate;
      let principal = monthlyPayment - interest;
      balance -= principal;
      totalInterest += interest;
      result.push({ balance, principal, interest, monthlyPayment });
    }
    return result;
  };

  const getCapitalGrowthData = () => {
    let d: any[] = [];
    if (rows?.length > 0) {
      d.push(["Year", "Capital Growth", "Loan balance"]);
      var today = new Date();
      let y = today.getFullYear();
      rows.map((x, i) => {
        d.push([(y + i).toString(), x.value, x.loanBalance]);
      });
    }
    return d;
  };

  const getCashflow = () => {
    let d: any[] = [];
    if (rows?.length > 0) {
      d.push(["Year", "Rent", "Expense"]);
      var today = new Date();
      let y = today.getFullYear();
      rows.map((x, i) => {
        d.push([(y + i).toString(), x.rent, x.expense]);
      });
    }
    return d;
  };

  const options = {
    curveType: "function",
    legend: { position: "bottom" },
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <Badge color="dark text-light" pill>
            Investment Property
          </Badge>
        </Col>
      </Row>
      <Table striped responsive className="mt-3" style={{ maxWidth: "50%" }}>
        <thead>
          <tr>
            <th className="bg-dark text-light">Initial loan amount</th>
            <td>{`$ ${props.portfolio.purchasePrice?.toLocaleString()}`}</td>
          </tr>
          <tr>
            <th className="bg-dark text-light">Estimated current value</th>
            <td>{`$ ${props.portfolio.valuation?.toLocaleString()}`}</td>
          </tr>
          <tr>
            <th className="bg-dark text-light">Estimated current rent</th>
            <td>{`$ ${props.portfolio.weeklyHigherRent?.toLocaleString()}`}</td>
          </tr>
          <tr>
            <th className="bg-dark text-light">Estimate for years</th>
            <td>
              <select
                className="form-control"
                onChange={(e) => {
                  e.persist();
                  setEstimateYears(parseInt(e.target.value));
                }}
              >
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
              </select>
            </td>
          </tr>
        </thead>
      </Table>

      <Table responsive striped>
        <thead>
          <tr className="bg-dark text-light">
            <th>My age</th>
            <th>Year</th>
            <th>Yearly appreciation</th>
            <th>Appreciated value</th>
            <th>Loan balance</th>
            <th>Annual rent</th>
            <th>Expense</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((r, i) => {
            if (r.rent > r.expense && positiveRow === -1) {
              positiveRow = i;
            }
            return (
              <tr key={i} className={i === positiveRow ? "bg-success text-light" : ""}>
                <td>{r?.myAge}</td>
                <td>{r?.year}</td>
                <td>{`$${r?.appreciation?.toLocaleString()}`}</td>
                <td>{`$${r?.value?.toLocaleString()}`}</td>
                <td>{`$${r?.loanBalance?.toLocaleString()}`}</td>
                <td>{`$${r?.rent?.toLocaleString()}`}</td>
                <td>{`$${r?.expense?.toLocaleString()}`}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          <Chart chartType="LineChart" width="100%" height="400px" data={getCapitalGrowthData()} options={options} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Chart chartType="LineChart" width="100%" height="400px" data={getCashflow()} options={options} />
        </Col>
      </Row>
    </>
  );
};
