import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Row, Table } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { IPortfolioOwner, list, update } from "../../../models/portfolioOwner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { IPortfolioBudget } from "../../../models/portfolioBudget";
import { IMaskInput } from "react-imask";
import { currencyBlock } from "../../../models/constants";
import { ICalculatedPortfolio } from "./portfolio";

interface PortfolioOwnerComponentProps extends BaseProps {
  portfolioOwners: IPortfolioOwner[];
  portfolios: IPortfolioBudget[];
  calculatedPortfolios: ICalculatedPortfolio[];
}

interface ICalculated {
  totalValue: number;
  totalDebt: number;
  netValue: number;
  leverageRatio: number;
  totalIncome: number;
  totalExpenses: number;
  offsetGainBack: number;
  cashFlowBeforeTax: number;
  depreciation: number;
  overallNetValue: number;
  cashFlowAfterTax: number;
}

interface ICalculatedPortfolioOwner {
  calculatedPortfolio?: ICalculated;
  ownerId?: string;
}

export const PortfolioOwnerComponent = (props: PortfolioOwnerComponentProps) => {
  const [portfolioOwners, setPortfolioOwners] = useState<IPortfolioOwner[]>(props.portfolioOwners);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [calculatedPortfolioOwners, setCalculatedPortfolioOwners] = useState<ICalculatedPortfolioOwner[]>([]);
  const [totalCalculatedPortfolioOwner, setTotalCalculatedPortfolioOwner] = useState<ICalculated>();
  const [averageTaxRate, setAverageTaxRate] = useState<number>(0);

  useEffect(() => {
    setUnsavedChanges(true);

    let divider = 0;
    portfolioOwners?.map((x) => {
      if (x.taxRate && x.taxRate > 0) {
        divider++;
      }
    });
    let averageTaxRate = portfolioOwners?.reduce((a, v) => (a = a + (v?.taxRate || 0)), 0) / divider;
    setAverageTaxRate(averageTaxRate);
  }, [portfolioOwners]);

  useEffect(() => {
    let calculatedPortfolioOwners: ICalculatedPortfolioOwner[] = [];

    portfolioOwners?.map((x) => {
      let filteredCalculatedPortfolios = props.calculatedPortfolios?.filter((y) => y?.portfolioOwnerId === x?.id);
      let filteredortfolios = props.portfolios?.filter((y) => y?.portfolioOwnerId === x?.id);
      let totalValue = filteredortfolios?.reduce((a, v) => (a = a + v.valuation), 0);
      let totalDebt = filteredCalculatedPortfolios?.reduce((a, v) => (a = a + v.loanPostLMI), 0);
      let netValue = totalValue - totalDebt;
      let leverageRatio = (totalDebt / totalValue) * 100;
      let overallNetValue = netValue + x.offsetAccount;
      let averageInterestRate = filteredortfolios?.reduce((a, v) => (a = a + v.interestRate), 0) / filteredortfolios?.length;
      let offsetGainBack = x.offsetAccount * (averageInterestRate / 100);
      let totalIncome = filteredCalculatedPortfolios?.reduce((a, v) => (a = a + v.annualHigherRent), 0);
      let totalExpenses = filteredCalculatedPortfolios?.reduce((a, v) => (a = a + v.annualTotalExpense), 0);
      let cashFlowBeforeTax = totalIncome - totalExpenses + offsetGainBack;
      let depreciation = filteredortfolios?.reduce((a, v) => (a = a + v.depreciationSchedule), 0);
      let cashFlowAfterTax =
        cashFlowBeforeTax - x.accountantExpense - x.landTax - (cashFlowBeforeTax - x.accountantExpense - depreciation - x.landTax) * (x.taxRate / 100);

      let c: ICalculatedPortfolioOwner = {
        calculatedPortfolio: {
          totalValue,
          totalDebt,
          netValue,
          leverageRatio,
          totalIncome,
          totalExpenses,
          offsetGainBack,
          cashFlowBeforeTax,
          depreciation,
          overallNetValue,
          cashFlowAfterTax,
        },
        ownerId: x?.id,
      };
      calculatedPortfolioOwners.push(c);
    });
    setCalculatedPortfolioOwners(calculatedPortfolioOwners);

    let totalCalculatedPortfolioOwners: ICalculated = {
      totalValue: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.totalValue || 0)), 0),
      totalDebt: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.totalDebt || 0)), 0),
      netValue: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.netValue || 0)), 0),
      leverageRatio:
        (calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.totalDebt || 0)), 0) /
          calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.totalValue || 0)), 0)) *
        100,
      totalIncome: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.totalIncome || 0)), 0),
      totalExpenses: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.totalExpenses || 0)), 0),
      offsetGainBack: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.offsetGainBack || 0)), 0),
      cashFlowBeforeTax: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.cashFlowBeforeTax || 0)), 0),
      depreciation: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.depreciation || 0)), 0),
      overallNetValue: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.overallNetValue || 0)), 0),
      cashFlowAfterTax: calculatedPortfolioOwners?.reduce((a, v) => (a = a + (v.calculatedPortfolio?.cashFlowAfterTax || 0)), 0),
    };
    setTotalCalculatedPortfolioOwner(totalCalculatedPortfolioOwners);
    setUnsavedChanges(false);
  }, [props.calculatedPortfolios, props.portfolios, portfolioOwners]);

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false);
      }, 1000);
    }
  }, [saved]);

  return (
    <>
      {unsavedChanges && <Alert color="warning">You have unsaved changes</Alert>}
      <Row>
        <Col sm="12">
          <h3 className="mt-2">Summary</h3>
          <hr />
          <Alert color="info">This page shows summary for investment properties only</Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <colgroup>
              <col className="col-md-4"></col>
              {portfolioOwners?.map((x, i) => {
                return <col className="col-2" key={i}></col>;
              })}
              <col className="col-2"></col>
            </colgroup>

            <thead>
              <tr>
                <th></th>
                {portfolioOwners?.map((x, i) => {
                  return <th key={i}>{x.name}</th>;
                })}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan={portfolioOwners?.length + 2}>Balance sheet</th>
              </tr>
              <tr>
                <td>Investment properties</td>
                {portfolioOwners?.map((x, i) => {
                  return <td key={i}>{props.portfolios?.filter((y) => y.portfolioOwnerId === x.id)?.length}</td>;
                })}
                <td>{props.portfolios?.length}</td>
              </tr>
              <tr>
                <td>Total value</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtTotalValue-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.totalValue?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtTotalValue`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.totalValue?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Total debt</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtTotalDebt-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.totalDebt?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtTotalDebt`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.totalDebt?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Net value</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtNetValue-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.netValue?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtNetValue`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.netValue?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Leverage ratio</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtLeverageRatio-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.leverageRatio?.toString()
                          }
                          lazy={false}
                          mask="num%"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtLeverageRatio`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.leverageRatio?.toString()}
                    lazy={false}
                    mask="num%"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Offset account</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtOffsetAccount-${i}`}
                          className="form-control"
                          value={x.offsetAccount ? x.offsetAccount?.toString() : ""}
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          onAccept={(value: string) => {
                            let all = [...portfolioOwners];
                            let p = all.find((y) => y.id === x.id);
                            let v = value ? parseFloat(value) : null;
                            p.offsetAccount = v;
                            setPortfolioOwners(all);
                          }}
                          placeholder="$0"
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtOffsetAccount`}
                    className="form-control"
                    value={portfolioOwners?.reduce((a, v) => (a = a + (v.offsetAccount || 0)), 0)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Overall net value</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtOverallNetValue-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.overallNetValue?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtOverallNetValue`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.overallNetValue?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th colSpan={portfolioOwners?.length + 2}>Profit / Loss Statement</th>
              </tr>
              <tr>
                <td>Total income</td>
                {portfolioOwners?.length > 0 &&
                  calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtTotalIncome-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.totalIncome?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtTotalIncome`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.totalIncome?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Total expenses</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtTotalExpenses-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.totalExpenses?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtTotalExpenses`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.totalExpenses?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Offset Gain Back</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtOffsetGainBack-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.offsetGainBack?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtOffsetGainBack`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.offsetGainBack?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Estimated Cashflow before tax considerations</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtEstimatedCashflow-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.cashFlowBeforeTax?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtEstimatedCashflow`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.cashFlowBeforeTax?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Depreciation</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtDepreciation-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.depreciation?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtDepreciation`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.depreciation?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Accountant Expense</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtAccountantExpense-${i}`}
                          className="form-control"
                          value={x.accountantExpense ? x.accountantExpense.toString() : ""}
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          onAccept={(value: string) => {
                            let all = [...portfolioOwners];
                            let p = all.find((y) => y.id === x.id);
                            let v = value ? parseFloat(value) : null;
                            p.accountantExpense = v;
                            setPortfolioOwners(all);
                          }}
                          placeholder="$0"
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtAccountantExpense`}
                    className="form-control"
                    value={portfolioOwners?.reduce((a, v) => (a = a + (v.accountantExpense || 0)), 0)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Land tax</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtLandTax-${i}`}
                          className="form-control"
                          value={x.landTax ? x.landTax.toString() : ""}
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          onAccept={(value: string) => {
                            let all = [...portfolioOwners];
                            let p = all.find((y) => y.id === x.id);
                            let v = value ? parseFloat(value) : null;
                            p.landTax = v;
                            setPortfolioOwners(all);
                          }}
                          placeholder="$0"
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtLandTax`}
                    className="form-control"
                    value={portfolioOwners?.reduce((a, v) => (a = a + (v.landTax || 0)), 0)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <td>Tax rate</td>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtTaxRate-${i}`}
                          className="form-control"
                          value={x.taxRate ? x.taxRate.toString() : "0"}
                          lazy={false}
                          mask="num%"
                          unmask={true}
                          blocks={currencyBlock}
                          onAccept={(value: string) => {
                            let all = [...portfolioOwners];
                            let p = all.find((y) => y.id === x.id);
                            let v = value ? parseFloat(value) : null;
                            p.taxRate = v;
                            setPortfolioOwners(all);
                          }}
                          placeholder="0"
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtTaxRatePO`}
                    className="form-control"
                    value={averageTaxRate?.toString()}
                    lazy={false}
                    mask="num%"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>Estimated Cashflow after tax considerations</th>
                {calculatedPortfolioOwners?.length > 0 &&
                  portfolioOwners?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          id={`txtCashflowAfterTax-${i}`}
                          className="form-control"
                          value={
                            calculatedPortfolioOwners?.length > 0 &&
                            calculatedPortfolioOwners?.find((y) => y.ownerId === x.id)?.calculatedPortfolio?.cashFlowAfterTax?.toString()
                          }
                          mask="$num"
                          unmask={true}
                          blocks={currencyBlock}
                          placeholder="$0"
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                <td>
                  <IMaskInput
                    id={`txtCashflowAfterTax`}
                    className="form-control"
                    value={totalCalculatedPortfolioOwner?.cashFlowAfterTax?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-right">
          {saved && <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-success"></FontAwesomeIcon>}
          <Button
            color="primary"
            onClick={async () => {
              portfolioOwners?.map(async (portfolioOwner) => {
                await update(portfolioOwner);
                setUnsavedChanges(false);
                setSaved(true);
              });
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>Save
          </Button>
        </Col>
      </Row>
    </>
  );
};
