import React, { useEffect, useState } from "react";

import { Badge, Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { IPortfolioBudget, update } from "../../../models/portfolioBudget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { IMaskInput } from "react-imask";
import { currencyBlock, percentBlock } from "../../../models/constants";

import { IPortfolioOwner } from "../../../models/portfolioOwner";

interface ETFComponentProps extends BaseProps {
  portfolio: IPortfolioBudget;
  portfolioOwners: IPortfolioOwner[];
}

export const ETFComponent = (props: ETFComponentProps) => {
  const numberOfWeeks: number = 52;
  const numberOfMonths: number = 12;
  const [portfolio, setPortfolio] = useState<IPortfolioBudget>(props.portfolio);
  const [saved, setSaved] = useState<boolean>(false);

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false);
      }, 1000);
    }
  }, [saved]);

  return (
    <>
      <Row>
        <Col className="text-center">
          <Badge color="dark text-light" pill>
            Stocks or ETF
          </Badge>
        </Col>
      </Row>

      <h3 className="mt-2">General</h3>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtxAddress-${portfolio?.id}`}>Name</Label>
            <Input
              id={`txtxAddress-${portfolio?.id}`}
              value={portfolio?.address}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({
                  ...x,
                  address: e.target.value,
                }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtValuation-${portfolio?.id}`}>Initial deposit</Label>
            <IMaskInput
              id={`txtValuation-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.etfPrincipalAmount ? portfolio?.etfPrincipalAmount?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  etfPrincipalAmount: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtPP-${portfolio?.id}`}>Regular deposit</Label>
            <IMaskInput
              id={`txtPP-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.etfRegularAmount ? portfolio?.etfRegularAmount?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  etfRegularAmount: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtDeposit-${portfolio?.id}`}>Annual interest rate</Label>
            <IMaskInput
              id={`txtDeposit-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.interestRate ? portfolio?.interestRate?.toString() : ""}
              lazy={false}
              mask="num%"
              unmask={true}
              blocks={percentBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  interestRate: v,
                }));
              }}
              placeholder="0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtYears-${portfolio?.id}`}>Number of years</Label>
            <Input
              type="number"
              id={`txtYears-${portfolio?.id}`}
              value={portfolio?.etfInvestmentYears}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({
                  ...x,
                  etfInvestmentYears: parseInt(e.target.value),
                }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtDepositFrequency-${portfolio?.id}`}>Deposit frequency</Label>
            <select
              className="form-control"
              value={portfolio?.etfRegularAmountFrequency}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({
                  ...x,
                  etfRegularAmountFrequency: e.target.value,
                }));
              }}
            >
              <option value={"Annually"}>Annually</option>
              <option value={"Monthly"}>Monthly</option>
              <option value={"Fortnightly"}>Fortnightly</option>
              <option value={"Weekly"}>Weekly</option>
              <option value={"Daily"}>Daily</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-right">
          {saved && <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-success"></FontAwesomeIcon>}
          <Button
            color="primary"
            onClick={async () => {
              await update(portfolio);
              setSaved(true);
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>Save
          </Button>
        </Col>
      </Row>
    </>
  );
};
