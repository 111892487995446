import React, { useState } from "react";

import { Alert } from "reactstrap";

interface DisclaimerComponentProps {}

export const DisclaimerComponent = (props: DisclaimerComponentProps) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  return (
    <Alert color="warning">
      <h2>Terms & Conditions</h2>
      <hr />
      <p>
        © Consulting By PK - All rights reserved. Do not share, copy, reproduce or sell any part of this document unless you have written permission from
        Consulting By PK. All infringements will be prosecuted. If you are the personal owner of the Consulting By PK End User License then you may use it for
        your own use but not for any other purpose.
      </p>
      <h2>Disclaimer</h2>
      <hr />
      <p>
        The opinions expressed in all material published by Consulting By PK are not to be construed as financial or investment advice nor should be relied upon
        for any investment activities. Consulting By PK strongly recommends that you perform your own independent research and/or seek your own legal advice
        before making any financial decisions. Contents of this message are of general nature only and should not be relied upon solely when making an
        investment decision. Consulting By PK nor any of its directors, associates, staff, or associated companies bear any liability from any actions derived
        from in this document. One should always seek third party investment information from relevant parties such as legal, finance, and accountancy
        enquiries.
      </p>
    </Alert>
  );
};
