import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Layout } from "./layout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleLogout, useGoogleLogin, TokenResponse } from "@react-oauth/google";
import { GoogleProfile } from "./models/auth";
require("./styles/index.scss");

declare global {
  interface Window {
    $user: TokenResponse;
    $profile: GoogleProfile;
  }
  interface Document {
    documentMode?: any;
  }
}

ReactDOM.render(
  <GoogleOAuthProvider clientId="1028059389895-12u337r6e55qn62cpou2ev452hbe1g7d.apps.googleusercontent.com">
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  </GoogleOAuthProvider>,
  document.getElementById("acader-crm-root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
