import React, { useEffect, useState } from "react";

import { Badge, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { IPortfolioBudget } from "../../../models/portfolioBudget";

import { Chart } from "react-google-charts";
import { currencyBlock, percentBlock } from "../../../models/constants";
import { IMaskInput } from "react-imask";
import { IUserAssumption } from "../../../models/userAssumption";

interface IPortfolioBudgetPlay {
  interestRate?: number;
  etfPrincipalAmount?: number;
  etfRegularAmount?: number;
  etfRegularAmountFrequency?: string;
  etfInvestmentYears?: number;
}

interface IFinalData {
  principal?: number;
  regular?: number;
  totalInterest?: number;
}

interface ETFProjectionComponentProps extends BaseProps {
  portfolio: IPortfolioBudget;
  assumption: IUserAssumption;
}

export const ETFProjectionComponent = (props: ETFProjectionComponentProps) => {
  const [finalData, setfinalData] = useState<IFinalData>({});
  const [chartData, setChartData] = useState<any[]>([]);
  const [budgetPlay, setBudgetPlay] = useState<IPortfolioBudgetPlay>({
    interestRate: props.portfolio?.interestRate,
    etfInvestmentYears: props.portfolio?.etfInvestmentYears,
    etfPrincipalAmount: props.portfolio?.etfPrincipalAmount,
    etfRegularAmount: props.portfolio?.etfRegularAmount,
    etfRegularAmountFrequency: props.portfolio?.etfRegularAmountFrequency,
  });

  useEffect(() => {
    calculateCompoundInterest();
  }, [budgetPlay]);

  const calculateCompoundInterest = () => {
    const r = budgetPlay.interestRate / 100;

    let n;
    switch (budgetPlay.etfRegularAmountFrequency) {
      case "Daily":
        n = 365;
        break;
      case "Weekly":
        n = 52;
        break;
      case "Fortnightly":
        n = 26;
        break;
      case "Monthly":
        n = 12;
        break;
      case "Annually":
        n = 1;
        break;
      default:
        n = 1;
    }
    let data: any[] = [
      [
        "Years",
        {
          type: "string",
          role: "tooltip",
          p: { html: true },
        },
        "Initial deposit",
        "Regular deposit",
        "Total interest",
      ],
    ];

    let principal = budgetPlay?.etfPrincipalAmount;
    let totalInterest = 0;
    let totalDeposit = 0;
    let interestRateperiod = budgetPlay?.interestRate / 100 / n;
    let total = 0;
    for (let i = 0; i < budgetPlay?.etfInvestmentYears; i++) {
      let interest = 0;
      for (let j = 0; j < n; j++) {
        interest += (principal + budgetPlay?.etfRegularAmount * j) * interestRateperiod;
      }
      totalDeposit += budgetPlay?.etfRegularAmount * n;
      totalInterest += interest;
      total += budgetPlay?.etfPrincipalAmount + budgetPlay?.etfRegularAmount * n + interest;
      data.push([
        (i + 1).toString(),
        getHtmlForChatTooltip(i + 1, budgetPlay?.etfPrincipalAmount, totalDeposit, totalInterest),
        budgetPlay?.etfPrincipalAmount,
        totalDeposit,
        totalInterest,
      ]);
      principal += budgetPlay?.etfRegularAmount * n;
      if (i === budgetPlay?.etfInvestmentYears - 1) {
        setfinalData({ principal: budgetPlay?.etfPrincipalAmount, regular: totalDeposit, totalInterest });
      }
    }

    setChartData(data);
  };

  const getHtmlForChatTooltip = (year: number, principal: number, regular: number, totalInterest: number) => {
    let total = principal + regular + totalInterest;
    return `<table class="table table-responsive" style="width:200px">
              <tr>
                <th>Year</th>
                <td>${year}</td>
              </tr>
              <tr>
                <th>Initial deposit</th>
                <td>$${principal?.toLocaleString()}</td>
              </tr>
              <tr>
                <th>Regular deposit</th>
                <td>$${regular?.toLocaleString()}</td>
              </tr>
              <tr>
                <th>Total interest</th>
                <td>$${totalInterest?.toLocaleString()}</td>
              </tr>
              <tr>
                <th>Total</th>
                <td>$${total?.toLocaleString()}</td>
              </tr>
            </table>`;
  };

  const options = {
    title: "Results",
    chartArea: { width: "70%" },
    isStacked: true,
    bar: { groupWidth: "95%" },
    hAxis: {
      title: "Years",
    },
    vAxis: {
      title: "Amount",
    },
    focusTarget: "category",
    tooltip: { isHtml: true },
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <Badge color="dark text-light" pill>
            Stocks or ETF
          </Badge>
        </Col>
      </Row>
      <Table striped responsive className="mt-3">
        <thead>
          <tr>
            <th className="bg-dark text-light">Initial deposit:</th>
            <td>
              <IMaskInput
                id={`txtPrin-${props.portfolio?.id}`}
                className="form-control"
                value={budgetPlay?.etfPrincipalAmount ? budgetPlay?.etfPrincipalAmount?.toString() : "0"}
                mask="$num"
                unmask={true}
                blocks={currencyBlock}
                onAccept={(value: string) => {
                  let v = value ? parseFloat(value) : null;
                  setBudgetPlay((x) => ({
                    ...x,
                    etfPrincipalAmount: v,
                  }));
                }}
                placeholder="$0"
              ></IMaskInput>
            </td>
            <th className="bg-dark text-light">Regular deposit</th>
            <td>
              <IMaskInput
                id={`txtreg-${props.portfolio?.id}`}
                className="form-control"
                value={budgetPlay?.etfRegularAmount ? budgetPlay?.etfRegularAmount?.toString() : "0"}
                mask="$num"
                unmask={true}
                blocks={currencyBlock}
                onAccept={(value: string) => {
                  let v = value ? parseFloat(value) : null;
                  setBudgetPlay((x) => ({
                    ...x,
                    etfRegularAmount: v,
                  }));
                }}
                placeholder="$0"
              ></IMaskInput>
            </td>
          </tr>
          <tr>
            <th className="bg-dark text-light">Deposit frequency</th>
            <td>
              <select
                className="form-control"
                value={budgetPlay?.etfRegularAmountFrequency}
                onChange={(e) => {
                  e.persist();
                  setBudgetPlay((x) => ({
                    ...x,
                    etfRegularAmountFrequency: e.target.value,
                  }));
                }}
              >
                <option value={"Annually"}>Annually</option>
                <option value={"Monthly"}>Monthly</option>
                <option value={"Fortnightly"}>Fortnightly</option>
                <option value={"Weekly"}>Weekly</option>
                <option value={"Daily"}>Daily</option>
              </select>
            </td>
            <th className="bg-dark text-light">Number of years</th>
            <td>
              <IMaskInput
                id={`txtreg-${props.portfolio?.id}`}
                className="form-control"
                value={budgetPlay?.etfInvestmentYears ? budgetPlay?.etfInvestmentYears?.toString() : "0"}
                mask="num"
                unmask={true}
                blocks={currencyBlock}
                onAccept={(value: string) => {
                  let v = value ? parseInt(value) : null;
                  setBudgetPlay((x) => ({
                    ...x,
                    etfInvestmentYears: v,
                  }));
                }}
                placeholder="$0"
              ></IMaskInput>
            </td>
          </tr>
          <tr>
            <th className="bg-dark text-light">Annual interest rate</th>
            <td>
              <IMaskInput
                id={`txtint-${props.portfolio?.id}`}
                className="form-control"
                value={budgetPlay?.interestRate ? budgetPlay?.interestRate?.toString() : "0"}
                mask="num %"
                unmask={true}
                blocks={percentBlock}
                onAccept={(value: string) => {
                  let v = value ? parseFloat(value) : null;
                  setBudgetPlay((x) => ({
                    ...x,
                    interestRate: v,
                  }));
                }}
                placeholder="$0"
              ></IMaskInput>
            </td>
            <th className="bg-dark text-light"></th>
            <td></td>
          </tr>
        </thead>
      </Table>
      <Chart chartType="ColumnChart" width="100%" height="400px" data={chartData} options={options} />
      <h2 className="mt-3">Summary</h2>
      <hr />
      <Table>
        <tr>
          <th className="bg-dark text-light">Initial deposit</th>
          <td>${finalData.principal?.toLocaleString()}</td>
        </tr>
        <tr>
          <th className="bg-dark text-light">Regular deposit</th>
          <td>${finalData.regular?.toLocaleString()}</td>
        </tr>
        <tr>
          <th className="bg-dark text-light">Total interest</th>
          <td>${finalData.totalInterest?.toLocaleString()}</td>
        </tr>
        <tr>
          <th className="bg-dark text-light">Total savings</th>
          <td>${(finalData.principal + finalData.regular + finalData.totalInterest).toLocaleString()}</td>
        </tr>
      </Table>
    </>
  );
};
