import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { IUser, create, initialise, list, update, userSchema } from "../../../models/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencil, faPlus, faSave, faStop, faUsers } from "@fortawesome/free-solid-svg-icons";
import { OrbitProgress } from "react-loading-indicators";

interface UsersPageProps extends BaseProps {}

export const UsersPage = (props: UsersPageProps) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [user, setUser] = useState<IUser>({ initialised: false });
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<IUser>({
    isAdmin: false,
    isActive: false,
    initialised: false,
    aiTokens: 0,
    dataTokens: 0,
  });
  useEffect(() => {
    if (props.profile?.id) {
      list().then((response) => {
        setUsers(response.data?.users);
        setIsLoading(false);
      });
    }
  }, [props.profile]);

  if (!props.profile?.isAdmin) {
    return <Alert color="danger">Unauthorized!!!</Alert>;
  }

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }
  return (
    <>
      <Modal isOpen={modalIsOpen} toggle={(x) => setModalIsOpen(x != x)} size="lg">
        <ModalHeader>Manage Budget Variable</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="txtFirstName">First name</Label>
              {errors?.firstName && (
                <>
                  <br />
                  <span className="text-danger">{errors.firstName}</span>
                </>
              )}
              <Input
                id="txtFirstName"
                value={user?.firstName}
                onChange={(e) => {
                  e.persist();
                  setUser((x) => ({ ...x, firstName: e.target.value }));
                }}
              ></Input>
            </Col>
            <Col>
              <Label for="txtLastName">Last name</Label>
              {errors?.lastName && (
                <>
                  <br />
                  <span className="text-danger">{errors.lastName}</span>
                </>
              )}
              <Input
                id="txtLastName"
                value={user?.lastName}
                onChange={(e) => {
                  e.persist();
                  setUser((x) => ({ ...x, lastName: e.target.value }));
                }}
              ></Input>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Label for="txtEmail">Email</Label>
              {errors?.email && (
                <>
                  <br />
                  <span className="text-danger">{errors.email}</span>
                </>
              )}
              <Input
                id="txtEmail"
                value={user?.email}
                onChange={(e) => {
                  e.persist();
                  setUser((x) => ({ ...x, email: e.target.value }));
                }}
              ></Input>
            </Col>
            <Col>
              <Label for="ddlAdmin">Admin access</Label>
              {errors?.isAdmin && (
                <>
                  <br />
                  <span className="text-danger">{errors.isAdmin}</span>
                </>
              )}
              <select
                className="form-control"
                id="ddlAdmin"
                value={user?.isAdmin ? "Yes" : "No"}
                onChange={(e) => {
                  e.persist();
                  setUser((x) => ({
                    ...x,
                    isAdmin: e.target.value === "Yes" ? true : false,
                  }));
                }}
              >
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </select>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Label for="ddlActive">Active</Label>
              {errors?.isActive && (
                <>
                  <br />
                  <span className="text-danger">{errors.isActive}</span>
                </>
              )}
              <select
                className="form-control"
                id="ddlActive"
                value={user?.isActive ? "Yes" : "No"}
                onChange={(e) => {
                  e.persist();
                  setUser((x) => ({
                    ...x,
                    isActive: e.target.value === "Yes" ? true : false,
                  }));
                }}
              >
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </select>
            </Col>
            <Col>
              <Label for="ddlType">Auth source</Label>
              {errors?.type && (
                <>
                  <br />
                  <span className="text-danger">{errors.type}</span>
                </>
              )}
              <select
                className="form-control"
                id="ddlType"
                value={user?.type}
                onChange={(e) => {
                  e.persist();
                  setUser((x) => ({
                    ...x,
                    type: e.target.value,
                  }));
                }}
              >
                <option value={""}>Select...</option>
                <option value={"Google"}>Google</option>
                <option value={"Facebook"}>Facebook</option>
                <option value={"Microsoft"}>Microsoft</option>
              </select>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Label for="txtAITokens">AI Tokens</Label>
              {errors?.aiTokens && (
                <>
                  <br />
                  <span className="text-danger">{errors.aiTokens}</span>
                </>
              )}
              <Input
                id="txtAITokens"
                type="number"
                value={user?.aiTokens}
                onChange={(e) => {
                  e.persist();
                  setUser((x) => ({ ...x, aiTokens: parseInt(e.target.value) }));
                }}
              ></Input>
            </Col>
            <Col>
              <Label for="txtDataTokens">Data Tokens</Label>
              {errors?.dataTokens && (
                <>
                  <br />
                  <span className="text-danger">{errors.dataTokens}</span>
                </>
              )}
              <Input
                id="txtDataTokens"
                type="number"
                value={user?.dataTokens}
                onChange={(e) => {
                  e.persist();
                  setUser((x) => ({ ...x, dataTokens: parseInt(e.target.value) }));
                }}
              ></Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await userSchema(users).validateSync(user, {
                  abortEarly: false,
                });
                if (user?.id) {
                  await update(user);
                } else {
                  await create(user);
                }
                window.location.reload();
              } catch (yupErrors) {
                let errors: IUser = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
            Save
          </Button>
          <Button color="secondary" onClick={() => setModalIsOpen(false)}>
            <FontAwesomeIcon icon={faStop} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
          {user?.id && !user.initialised && (
            <Button
              color="success"
              onClick={async () => {
                await initialise(user);
                window.location.reload();
              }}
            >
              <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
              Initialise data
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Manage users | Asset manager</title>
      </Helmet>

      <Container>
        <h1 className="mb-5">
          <FontAwesomeIcon className="mr-2" icon={faUsers}></FontAwesomeIcon> Manager users
        </h1>
        <Button
          color="primary"
          className="mb-2"
          onClick={() => {
            setErrors({});
            setUser({ isAdmin: false, isActive: false, initialised: false });
            setModalIsOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>Add a new User
        </Button>

        <Table striped>
          <thead>
            <tr>
              <th scope="col-2">Name</th>
              <th scope="col-2">Email</th>
              <th scope="col-2">Admin</th>
              <th scope="col-2">Active</th>
              <th scope="col-2">Auth source</th>
              <th scope="col-2"></th>
            </tr>
          </thead>
          <tbody>
            {users?.map((x, i) => {
              return (
                <tr key={i}>
                  <td>{x.firstName + " " + x.lastName}</td>
                  <td>{x.email}</td>
                  <td>{x.isAdmin ? "Yes" : "No"}</td>
                  <td>{x.isActive ? "Yes" : "No"}</td>
                  <td>{x.type}</td>
                  <td scope="row">
                    <Button
                      color="primary"
                      onClick={() => {
                        setErrors({});
                        setUser(x);
                        setModalIsOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPencil} className="mr-1"></FontAwesomeIcon>
                      Manage
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
