import React, { useState } from "react";

import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface ToolInfoProps {
  id: string;
  title: string;
  tooltip: string;
}

export const ToolInfo = (props: ToolInfoProps) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  return (
    <>
      {props.title}
      <FontAwesomeIcon id={props.id} className="ml-2" icon={faInfoCircle}></FontAwesomeIcon>
      <Tooltip placement="auto" isOpen={tooltipOpen} target={props.id} toggle={() => setTooltipOpen((x) => !x)}>
        {props.tooltip}
      </Tooltip>
    </>
  );
};
