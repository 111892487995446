/* eslint-disable react/jsx-no-target-blank */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Dashboard } from "./dashboard";
import { googleLogout } from "@react-oauth/google";
import { GoogleProfile, getProfile } from "../../models/auth";
import { jwt, userInfo } from "../../models/constants";
import { Settings } from "./admin/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartColumn,
  faChartLine,
  faCog,
  faHandHoldingDollar,
  faHouseCircleCheck,
  faMapLocationDot,
  faMoneyBill,
  faSignOut,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { BudgetVariablePage } from "./admin/budget-variables";
import { UsersPage } from "./admin/users";
import { HouseholdBudgetPage } from "./household-budget";
import { PortfolioBudgetPage } from "./portfolio/portfolio-budget";
import { PortfolioValuationPage } from "./propertyValuations/valuation";
import { ValuationVariablePage } from "./admin/valuation-variables";
import { SuburbVariablePage } from "./admin/suburb-variables";
import { SuburbSelectionPage } from "./suburbSelections/suburb";
import { QuantumToolPage } from "./quantumTool/tool";
import { PortfolioProjectionPage } from "./projections/portfolio-projection";
import { OrbitProgress } from "react-loading-indicators";

export const SecureLayout = () => {
  const [profile, setProfile] = useState<GoogleProfile>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    var token = JSON.parse(localStorage.getItem(jwt));
    if (!token) {
      window.location.href = "/";
    } else {
      getProfile().then((response) => {
        if (response.hasError) {
          localStorage.setItem(jwt, null);
          if (response.statusText === "unauthorised") {
            window.location.href = "/?unauthorised=true";
          } else {
            window.location.href = "/";
          }
        } else {
          var profile: GoogleProfile = response?.data?.data?.profile;
          if (!profile) {
            window.location.href = "/";
          } else {
            setProfile(profile);
          }
        }
        setLoading(false);
      });
    }
  }, []);

  const logOut = () => {
    googleLogout();
    localStorage.setItem(jwt, null);
    window.location.href = "/";
  };
  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }
  return (
    <>
      <div id="wrapper">
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
          <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
            <div className="sidebar-brand-text mx-3">
              Portfolio <sup>mgr</sup>
            </div>
          </a>

          <hr className="sidebar-divider my-0" />

          <li className="nav-item active">
            <a className="nav-link" href="dashboard">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>

          <hr className="sidebar-divider" />

          <div className="sidebar-heading">Tools</div>

          <li className="nav-item">
            <a className="nav-link" href="/household-budget">
              <FontAwesomeIcon icon={faMoneyBill} className="mr-2"></FontAwesomeIcon>
              <span>Household budget</span>
            </a>

            <a className="nav-link" href="/portfolio-budget">
              <FontAwesomeIcon icon={faHouseCircleCheck} className="mr-2"></FontAwesomeIcon>
              <span>Portfolio budget</span>
            </a>

            <a className="nav-link" href="/portfolio-projections">
              <FontAwesomeIcon icon={faChartLine} className="mr-2"></FontAwesomeIcon>
              <span>Portfolio Projections</span>
            </a>
            <a className="nav-link" href="/property-valuation">
              <FontAwesomeIcon icon={faChartColumn} className="mr-2"></FontAwesomeIcon>
              <span>Property Valuation</span>
            </a>

            <a className="nav-link" href="/suburb-selection">
              <FontAwesomeIcon icon={faMapLocationDot} className="mr-2"></FontAwesomeIcon>
              <span>Suburb Selection</span>
            </a>

            <a className="nav-link" href="/wealth-strategy">
              <FontAwesomeIcon icon={faHandHoldingDollar} className="mr-2"></FontAwesomeIcon>
              <span>Wealth Creation Strategy</span>
            </a>
          </li>

          <hr className="sidebar-divider" />

          <div className="sidebar-heading">Profile</div>

          <li className="nav-item">
            <a className="nav-link" href="/settings">
              <FontAwesomeIcon icon={faCog} className="mr-2"></FontAwesomeIcon>
              <span>Settings</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="#" onClick={() => logOut()}>
              <FontAwesomeIcon icon={faSignOut} className="mr-2"></FontAwesomeIcon>
              <span>Logout</span>
            </a>
          </li>
          {profile?.isAdmin && (
            <>
              <hr className="sidebar-divider" />

              <div className="sidebar-heading">Admin</div>

              <li className="nav-item">
                <a className="nav-link" href="/suburb-variables">
                  <FontAwesomeIcon icon={faCog} className="mr-2"></FontAwesomeIcon>
                  <span>Suburb Variables</span>
                </a>
                <a className="nav-link" href="/valuation-variables">
                  <FontAwesomeIcon icon={faCog} className="mr-2"></FontAwesomeIcon>
                  <span>Valuation Variables</span>
                </a>
                <a className="nav-link" href="/budget-variables">
                  <FontAwesomeIcon icon={faCog} className="mr-2"></FontAwesomeIcon>
                  <span>Budget Variables</span>
                </a>
                <a className="nav-link" href="/manage-users">
                  <FontAwesomeIcon icon={faUsers} className="mr-2"></FontAwesomeIcon>
                  <span>Manage users</span>
                </a>
              </li>
            </>
          )}
        </ul>

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                <i className="fa fa-bars"></i>
              </button>

              <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>

                <li className="nav-item dropdown no-arrow">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{profile?.name}</span>
                  </a>

                  <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                    <a className="dropdown-item" href="/settings">
                      <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                      Settings
                    </a>

                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#" onClick={() => logOut()}>
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>

            <div className="container-fluid">
              <Route exact path="/dashboard" render={() => <Dashboard profile={profile} />} />

              <Route exact path="/household-budget" render={() => <HouseholdBudgetPage profile={profile} />} />

              <Route exact path="/portfolio-budget" render={() => <PortfolioBudgetPage profile={profile} />} />
              <Route exact path="/portfolio-projections" render={() => <PortfolioProjectionPage profile={profile} />} />
              <Route exact path="/property-valuation" render={() => <PortfolioValuationPage profile={profile} />} />
              <Route exact path="/suburb-selection" render={() => <SuburbSelectionPage profile={profile} />} />
              <Route exact path="/wealth-strategy" render={() => <QuantumToolPage profile={profile} />} />

              <Route exact path="/settings" render={() => <Settings profile={profile} onAccountClose={logOut} />} />

              <Route exact path="/budget-variables" render={() => <BudgetVariablePage profile={profile} />} />
              <Route exact path="/suburb-variables" render={() => <SuburbVariablePage profile={profile} />} />
              <Route exact path="/valuation-variables" render={() => <ValuationVariablePage profile={profile} />} />
              <Route exact path="/manage-users" render={() => <UsersPage profile={profile} />} />
            </div>
          </div>

          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>
                  Powered by{" "}
                  <a href="https://acader.com.au" target="_blank">
                    Acader Pty Ltd 2024
                  </a>{" "}
                </span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};
