import axios from "axios";
import { headers, jwt } from "./constants";

export interface GoogleProfile {
  id: string;
  email: string;
  name: string;
  isAdmin: boolean;
  dateOfBirth: Date;
  retireInYears: number;
  aiTokens: number;
  dataTokens: number;
}

export interface BaseProps {
  profile: GoogleProfile;
}

export let getProfile = async () => {
  let hasError = false;
  let statusText = "";
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/auth/profile", {
      headers: headers,
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
        statusText = error.response.data;
      }
    });
  return { data: response, hasError, statusText };
};

export let updateLogin = async (t: string) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/auth/updatelogin",
      { data: "11" },
      {
        headers: {
          Authorization: t,
          "Content-Type": "application/json",
        },
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};
