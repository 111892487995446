import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Col, Container, Input, Row, Table } from "reactstrap";

import { BaseProps } from "../../models/auth";

import DatePicker from "react-datepicker";
import enAu from "date-fns/locale/en-AU";
import { IUser, getUser, updateMe } from "../../models/user";

import { DisclaimerComponent } from "../../components/disclaimer";
import { IPortfolioBudget, list } from "../../models/portfolioBudget";
import { IPortfolioOwner, list as listPortfolioOwners } from "../../models/portfolioOwner";
import * as assumptions from "../../models/userAssumption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OrbitProgress } from "react-loading-indicators";
import Chart from "react-google-charts";

interface DashboardProps extends BaseProps {}
interface IData {
  value?: number;
  portfolio?: IPortfolioBudget;
}

export const Dashboard = (props: DashboardProps) => {
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(true);
  const [portfolios, setPortfolios] = useState<IPortfolioBudget[]>([]);
  const [poerfolioOwners, setPortfolioOwners] = useState<IPortfolioOwner[]>([]);
  const [assumption, setAssumption] = useState<assumptions.IUserAssumption>();
  const [cash, setCash] = useState<number>(0);
  const [data, setData] = useState<IData[]>([]);

  useEffect(() => {
    if (props.profile?.id) {
      list(props.profile?.id).then((response) => {
        let portfolios: IPortfolioBudget[] = response.data?.portfolioBudgets as IPortfolioBudget[];
        setPortfolios(portfolios);
        listPortfolioOwners(props.profile?.id).then((response) => {
          let portfolioOwners: IPortfolioOwner[] = response.data?.portfolioOwners;
          setPortfolioOwners(portfolioOwners);
        });
        assumptions.get(props.profile?.id).then((assumptionResponse) => {
          let assumption: assumptions.IUserAssumption = assumptionResponse.data.userAssumption;
          if (assumption === null) {
            assumptions.create({ ownerId: props.profile?.id }).then(() => {
              assumptions.get(props.profile?.id).then((assumptionResponse) => {
                setAssumption(assumptionResponse.data.userAssumption);
              });
            });
          } else {
            setAssumption(assumption);
          }
        });
      });
    }
  }, [props.profile]);

  useEffect(() => {
    if (props.profile?.id) {
      getUser(props.profile?.id).then((response) => {
        setUser(response.data?.user);
        setLoading(false);
      });
    }
  }, [props.profile]);

  useEffect(() => {
    calculatePortfolio();
  }, [assumption, user?.retireInYears]);

  useEffect(() => {
    if (!loading) {
      updateMe(user);
    }
  }, [user?.dateOfBirth, user?.retireInYears]);

  const calculatePortfolio = () => {
    let rows: IData[] = [];
    let cash: number = 0;
    portfolios?.map((portfolio) => {
      let value = 0;
      if (portfolio.investmentType === "Stocks/ETF") {
        value = calculateCompoundInterest(portfolio);
      } else {
        for (let i = 0; i < user?.retireInYears; i++) {
          let valuation = i === 0 ? portfolio.valuation : value;
          let appreciation = i === 0 ? 0 : Math.round((valuation * assumption?.annualAppreciation) / 100);
          value = Math.round(valuation + appreciation);
        }
      }
      rows.push({ value, portfolio });
      cash += portfolio?.offsetAmount;
    });
    poerfolioOwners?.map((x) => {
      cash += x.offsetAccount;
    });
    setData(rows);
    setCash(cash);
  };

  const calculateCompoundInterest = (portfolio: IPortfolioBudget) => {
    const r = portfolio.interestRate / 100;
    let finalValue = 0;
    let n;
    switch (portfolio.etfRegularAmountFrequency) {
      case "Daily":
        n = 365;
        break;
      case "Weekly":
        n = 52;
        break;
      case "Fortnightly":
        n = 26;
        break;
      case "Monthly":
        n = 12;
        break;
      case "Annually":
        n = 1;
        break;
      default:
        n = 1;
    }

    let principal = portfolio?.etfPrincipalAmount;
    let totalInterest = 0;
    let totalDeposit = 0;
    let interestRateperiod = portfolio?.interestRate / 100 / n;
    let total = 0;
    for (let i = 0; i < user?.retireInYears; i++) {
      let interest = 0;
      for (let j = 0; j < n; j++) {
        interest += (principal + portfolio?.etfRegularAmount * j) * interestRateperiod;
      }
      totalDeposit += portfolio?.etfRegularAmount * n;
      totalInterest += interest;
      total += portfolio?.etfPrincipalAmount + portfolio?.etfRegularAmount * n + interest;

      principal += portfolio?.etfRegularAmount * n;
      if (i === user?.retireInYears - 1) {
        finalValue = portfolio?.etfPrincipalAmount + totalDeposit + totalInterest;
        break;
      }
    }
    return finalValue;
  };
  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  const getchartData = () => {
    let chartdata = [];
    chartdata = [["Asset", "Value"]];

    data?.map((x) => {
      chartdata.push([x.portfolio?.address, x.value]);
    });
    //chartdata.push(d);
    return chartdata;
  };

  const options = {
    title: "My portfolio in 10 years",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Dashboard | Asset manager</title>
      </Helmet>

      <div className="theme theme-white">
        <Container className="mt-5">
          <h1>
            <FontAwesomeIcon className="mr-2" icon={faDashboard}></FontAwesomeIcon> Dashboard
          </h1>
          <hr />
          <Row>
            <Col>
              <h2>Getting started:</h2>

              <Alert color="info">
                <FontAwesomeIcon className="mr-2" icon={faInfoCircle}></FontAwesomeIcon> The information below helps the tools to make projections
              </Alert>

              <Table striped responsive>
                <tr>
                  <th className="bg-dark text-light">My date of birth is:</th>
                  <td>
                    <DatePicker
                      id={`txtDateOfBirth`}
                      autoComplete="off"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                      locale={enAu}
                      selected={user?.dateOfBirth && new Date(user?.dateOfBirth)}
                      onChange={(dateOfBirth: Date) => {
                        setUser((x) => ({
                          ...x,
                          dateOfBirth,
                        }));
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="bg-dark text-light">I want to retire in this number of years:</th>
                  <td>
                    <Input
                      type="number"
                      value={user?.retireInYears}
                      onChange={(e) => {
                        e.persist();
                        setUser((x) => ({
                          ...x,
                          retireInYears: parseInt(e.target.value),
                        }));
                      }}
                    />
                  </td>
                </tr>
              </Table>
              <h3>Personal information</h3>

              <Alert color="info">
                <FontAwesomeIcon className="mr-2" icon={faInfoCircle}></FontAwesomeIcon> Add your personal information here.
              </Alert>

              <ol>
                <li>
                  <a href="/household-budget">Enter your family budget</a>
                </li>
                <li>
                  <a href="/portfolio-budget">Enter your portfolio</a>
                </li>
              </ol>
              <h3>Analysis</h3>
              <Alert color="info">
                <FontAwesomeIcon className="mr-2" icon={faInfoCircle}></FontAwesomeIcon> The following tools will assist you in analysing and forecasting your
                finances.
              </Alert>
              <ol>
                <li>
                  Use the <a href="/suburb-selection">Suburb selection tool</a> to shortlist the suburbs
                </li>
                <li>
                  Use the <a href="/portfolio-valuation"> portfolio valuation tool </a>to analyse the property you intend to purchase
                </li>
                <li>
                  Use the <a href="/wealth-strategy"> Wealth quantum tool</a> forcast your passive income
                </li>
                <li>
                  Use the <a href="/portfolio-projections"> Portfolio projection tool</a> forcast your wealth in the coming years
                </li>
              </ol>
            </Col>
          </Row>
          <h3>Portfolio summary</h3> <hr />
          {data?.length === 0 && (
            <Alert color="info">
              <FontAwesomeIcon className="mr-2" icon={faInfoCircle}></FontAwesomeIcon>Add your{" "}
              <u>
                <a href="/portfolio-budget">portfolio</a>
              </u>{" "}
              to view your summary here.
            </Alert>
          )}
          {data?.length > 0 && (
            <>
              <Alert color="primary">In {user?.retireInYears} years your estimated portfolio summary is below</Alert>
              <Row>
                <Col md={6}>
                  <Table responsive>
                    <colgroup>
                      <col className="col-md-6"></col>
                      <col className="col-md-6"></col>
                    </colgroup>
                    {data?.map((x, i) => {
                      return (
                        <tr key={i}>
                          <th className="">{x?.portfolio?.address}</th>
                          <td>${x?.value.toLocaleString()}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <th className="">Cash holdings</th>
                      <td>${cash.toLocaleString()}</td>
                    </tr>
                    <tr>
                      <th className="bg-dark text-light">Total</th>
                      <td className="bg-dark text-light">${(data.reduce((a, v) => (a = a + v.value), 0) + cash).toLocaleString()}</td>
                    </tr>
                  </Table>
                </Col>
                <Col md={6}>
                  <Chart chartType="PieChart" data={getchartData()} options={options} width={"100%"} height={"400px"} />
                </Col>
              </Row>
            </>
          )}
          <DisclaimerComponent />
        </Container>
      </div>
    </>
  );
};
