export const userInfo: string = "userInfo";
export const jwt: string = "jwt";

export const currencyBlock = {
  num: {
    mask: Number,
    thousandsSeparator: ",",
    scale: 2,
    radix: ".",
    padFractionalZeros: true,
    normalizeZeros: false,
  },
};

export const currencyBlockNoScale = {
  num: {
    mask: Number,
    thousandsSeparator: ",",
    scale: 2,
    radix: ".",
    padFractionalZeros: false,
    normalizeZeros: false,
  },
};

export const percentBlock = {
  num: {
    expose: true,
    mask: Number,
    scale: 2,
    radix: ".",
    padFractionalZeros: true,
    normalizeZeros: false,
  },
};

export const headers = {
  Authorization: JSON.parse(localStorage.getItem(jwt)),
  "Content-Type": "application/json",
};
