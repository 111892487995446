import React, { useEffect, useState } from "react";

import { Alert, Badge, Col, Row, Table } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { IPortfolioBudget } from "../../../models/portfolioBudget";

import { Chart } from "react-google-charts";
import { IMaskInput } from "react-imask";
import { currencyBlock, currencyBlockNoScale, percentBlock } from "../../../models/constants";
import { Amortisation } from "./portfolio-projection";
import { IUserAssumption } from "../../../models/userAssumption";

interface PPORProjectionComponentProps extends BaseProps {
  portfolio: IPortfolioBudget;
  assumption: IUserAssumption;
}

interface IPPORPlay {
  loanBalance?: number;
  yearsRemaining?: number;
  interestRate?: number;
  initialOffsetBalance?: number;
  regularOffsetDeposit?: number;
  regularOffsetDepositFrequency?: "Weekly" | "Fortnighly" | "Monthly";
}

interface IRows {
  years?: number;
  offsetBalance?: number;
  balance?: number;
  value?: number;
}

export const PPORProjectionComponent = (props: PPORProjectionComponentProps) => {
  const [pporPlay, setPPORPlay] = useState<IPPORPlay>({
    loanBalance: props.portfolio?.purchasePrice,
    yearsRemaining: props.portfolio?.pAndIPeriod,
    interestRate: props.portfolio?.interestRate,
    initialOffsetBalance: props?.portfolio?.offsetAmount,
    regularOffsetDeposit: 0,
    regularOffsetDepositFrequency: "Fortnighly",
  });

  const [rows, setRows] = useState<IRows[]>([]);
  const [yearsReduced, setYearsReduced] = useState<number>(0);
  const [interestSaved, setInterestSaved] = useState<number>(0);

  useEffect(() => {
    GetAmortization();
  }, [pporPlay]);

  const getChartData = () => {
    let data: any[] = [["Years", "With offset", "without offset"]];
    rows?.map((x) => {
      data.push([x.years, x.offsetBalance, x.balance]);
    });
    return data;
  };

  const options = {
    title: "Offset chart",
    hAxis: { title: "Years", titleTextStyle: { color: "#333" } },
    vAxis: { minValue: 0 },
    chartArea: { width: "70%" },
    legend: { position: "top", alignment: "start" },
  };

  const GetAmortization = () => {
    let n = 12;
    switch (pporPlay.regularOffsetDepositFrequency) {
      case "Weekly":
        n = 52;
        break;
      case "Fortnighly":
        n = 26;
        break;
      case "Monthly":
        n = 12;
        break;

      default:
        n = 1;
    }

    let totalPeriod = pporPlay?.yearsRemaining;
    let monthlyInterestRate = pporPlay?.interestRate / 100;
    let monthlyPayment =
      (pporPlay?.loanBalance * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPeriod)) / (Math.pow(1 + monthlyInterestRate, totalPeriod) - 1);
    let result: Amortisation[] = [];
    let resultOffset: Amortisation[] = [];

    let balance = pporPlay?.loanBalance;
    let offsetBalance = pporPlay?.loanBalance;
    let totalInterest = 0;
    let totalOffsetInterest = 0;
    let offsetAmount = pporPlay?.initialOffsetBalance;

    let rows: IRows[] = [{ years: 0, offsetBalance, balance, value: props.portfolio?.valuation }];
    let finalYear = totalPeriod;

    for (let i = 0; i < totalPeriod; i++) {
      let appreciation = Math.round((rows[i].value * props.assumption?.annualAppreciation) / 100);
      let value = Math.round(rows[i].value + appreciation);
      let interest = balance * monthlyInterestRate;
      let principal = monthlyPayment - interest;
      balance -= principal;
      totalInterest += interest;

      offsetAmount += pporPlay?.regularOffsetDeposit;

      let offsetInterest = (balance - offsetAmount) * monthlyInterestRate;
      let offsetPrincipal = monthlyPayment - offsetInterest;
      offsetBalance -= offsetPrincipal;
      totalOffsetInterest += offsetInterest;

      if (offsetBalance < 0 && finalYear === totalPeriod) {
        finalYear = i - 1;
      }

      result.push({ balance, principal, interest, monthlyPayment });
      resultOffset.push({ balance: offsetBalance < 0 ? 0 : offsetBalance, principal: offsetPrincipal, interest: offsetInterest, monthlyPayment });
      rows.push({ years: i + 1, offsetBalance: offsetBalance < 0 ? 0 : Math.round(offsetBalance), balance: Math.round(balance), value });
    }
    setInterestSaved(totalInterest - totalOffsetInterest);
    setRows(rows);
    setYearsReduced(totalPeriod - finalYear);
    return result;
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <Badge color="dark text-light" pill>
            Primary place of residence
          </Badge>
        </Col>
      </Row>
      <Row>
        <Table responsive>
          <tr>
            <th>Loan balance</th>
            <td>
              <IMaskInput
                id={`txtloan-${props.portfolio?.id}`}
                className="form-control"
                value={pporPlay?.loanBalance ? pporPlay?.loanBalance?.toString() : "0"}
                mask="$num"
                unmask={true}
                blocks={currencyBlock}
                onAccept={(value: string) => {
                  let v = value ? parseFloat(value) : null;
                  setPPORPlay((x) => ({
                    ...x,
                    loanBalance: v,
                  }));
                }}
                placeholder="$0"
              ></IMaskInput>
            </td>
            <th>Years remainig</th>
            <td>
              <IMaskInput
                id={`txty-${props.portfolio?.id}`}
                className="form-control"
                value={pporPlay?.yearsRemaining ? pporPlay?.yearsRemaining?.toString() : "0"}
                mask="num"
                unmask={true}
                blocks={currencyBlockNoScale}
                onAccept={(value: string) => {
                  let v = value ? parseFloat(value) : null;
                  setPPORPlay((x) => ({
                    ...x,
                    yearsRemaining: v,
                  }));
                }}
                placeholder="$0"
              ></IMaskInput>
            </td>
          </tr>
          <tr>
            <th>Interest rate</th>
            <td>
              <IMaskInput
                id={`txtInterestRate-${props.portfolio?.id}`}
                className="form-control"
                value={pporPlay?.interestRate ? pporPlay?.interestRate?.toString() : "0"}
                mask="num %"
                unmask={true}
                lazy={false}
                blocks={percentBlock}
                onAccept={(value: string) => {
                  let v = value ? parseFloat(value) : null;
                  setPPORPlay((x) => ({
                    ...x,
                    interestRate: v,
                  }));
                }}
                placeholder="0%"
              ></IMaskInput>
            </td>
            <th>Initial offset balance</th>
            <td>
              <IMaskInput
                id={`txtoffsetBalance-${props.portfolio?.id}`}
                className="form-control"
                value={pporPlay?.initialOffsetBalance ? pporPlay?.initialOffsetBalance?.toString() : "0"}
                mask="$num"
                unmask={true}
                blocks={currencyBlock}
                onAccept={(value: string) => {
                  let v = value ? parseFloat(value) : null;
                  setPPORPlay((x) => ({
                    ...x,
                    initialOffsetBalance: v,
                  }));
                }}
                placeholder="$0"
              ></IMaskInput>
            </td>
          </tr>
          <tr>
            <th>Regular deposit to offset</th>
            <td>
              <IMaskInput
                id={`txtreg-${props.portfolio?.id}`}
                className="form-control"
                value={pporPlay?.regularOffsetDeposit ? pporPlay?.regularOffsetDeposit?.toString() : "0"}
                mask="$num"
                unmask={true}
                blocks={currencyBlock}
                onAccept={(value: string) => {
                  let v = value ? parseFloat(value) : null;
                  setPPORPlay((x) => ({
                    ...x,
                    regularOffsetDeposit: v,
                  }));
                }}
                placeholder="$0"
              ></IMaskInput>
            </td>
            <th>Regular deposit frequency</th>
            <td>
              <select
                className="form-control"
                value={pporPlay?.regularOffsetDepositFrequency}
                onChange={(e) => {
                  e.persist();
                  setPPORPlay((x) => ({
                    ...x,
                    regularOffsetDepositFrequency: e.target.value as any,
                  }));
                }}
              >
                <option value={"Weekly"}>Weekly</option>
                <option value={"Fortnighly"}>Fortnighly</option>
                <option value={"Annually"}>Annually</option>
              </select>
            </td>
          </tr>
        </Table>
        <Col md={5}>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="bg-dark text-light">Year</th>
                <th className="bg-dark text-light">Appreciated value</th>
                <th className="bg-dark text-light">Balance with offset</th>
                <th className="bg-dark text-light">Balance without offset</th>
              </tr>
            </thead>

            <tbody>
              {rows?.map((x, i) => {
                return (
                  <tr key={i}>
                    <td>{x.years}</td>
                    <td>${x.value?.toLocaleString()}</td>
                    <td>${x.offsetBalance?.toLocaleString()}</td>
                    <td>${x.balance?.toLocaleString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
        <Col md={7}>
          <Chart chartType="AreaChart" height="400px" data={getChartData()} options={options} />
          <br />
          <Alert color="primary">
            <p>
              <b>Total interest you could saved:</b> ${interestSaved.toLocaleString()}
            </p>
            <p>
              <b>You could save:</b> {yearsReduced.toLocaleString()} years
            </p>
          </Alert>
        </Col>
      </Row>
    </>
  );
};
