import React, { useEffect, useState } from "react";
import { ISuburbSelection, update } from "../../../models/suburbSelection";

interface HistoricControlProps {
  suburbSelection: ISuburbSelection;
}
const mapping = {
  Yes: "success",
  No: "danger",
  "Not great, not bad": "warning",
  "Data unreliable": "secondary",
};

export const HistoricControl = (props: HistoricControlProps) => {
  const [suburbSelection, setSuburbSelection] = useState<ISuburbSelection>(props.suburbSelection);
  const [colorcode, setColorCode] = useState<string>(mapping[props.suburbSelection.historicTrend]);
  useEffect(() => {
    if (props.suburbSelection.historicTrend !== suburbSelection.historicTrend) {
      update(suburbSelection);
      setColorCode(mapping[suburbSelection.historicTrend]);
    }
  }, [suburbSelection.historicTrend]);

  return (
    <td className={`bg-${colorcode}`}>
      <select
        title={suburbSelection?.historicTrend}
        className="form-control"
        id="ddlSupplyOrDemand"
        value={suburbSelection?.historicTrend}
        onChange={(e) => {
          e.persist();
          setSuburbSelection((x) => ({ ...x, historicTrend: e.target.value }));
        }}
      >
        <option value={null}>Select...</option>
        <option value={"Yes"}>Yes</option>
        <option value={"No"}>No</option>
        <option value={"Not great, not bad"}>Not great, not bad</option>
        <option value={"Data unreliable"}>Data unreliable</option>
      </select>
    </td>
  );
};
