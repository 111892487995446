import React, { useEffect, useState } from "react";
import { ISuburbSelection, update } from "../../../models/suburbSelection";

interface TargetControlProps {
  suburbSelection: ISuburbSelection;
}

const mapping = {
  Yes: "success",
  No: "danger",
  "Not great, but ok because of flexibility": "warning",
  "Data unreliable": "secondary",
  "Target 'n/a', but satisfied": "success",
  "Target 'n/a', but unsatisfied": "danger",
};

export const TargetControl = (props: TargetControlProps) => {
  const [suburbSelection, setSuburbSelection] = useState<ISuburbSelection>(props.suburbSelection);
  const [colorcode, setColorCode] = useState<string>(mapping[props.suburbSelection.meetsTarget]);
  useEffect(() => {
    if (props.suburbSelection.meetsTarget !== suburbSelection.meetsTarget) {
      update(suburbSelection);
      setColorCode(mapping[suburbSelection.meetsTarget]);
    }
  }, [suburbSelection.meetsTarget]);

  return (
    <td className={`bg-${colorcode}`}>
      <select
        title={suburbSelection?.meetsTarget}
        className="form-control"
        id="ddlSupplyOrDemand"
        value={suburbSelection?.meetsTarget}
        onChange={(e) => {
          e.persist();
          setSuburbSelection((x) => ({ ...x, meetsTarget: e.target.value }));
        }}
      >
        <option value={null}>Select...</option>
        <option value={"Yes"}>Yes</option>
        <option value={"No"}>No</option>
        <option value={"Not great, but ok because of flexibility"}>Not great, but ok because of flexibility</option>
        <option value={"Data unreliable"}>Data unreliable</option>
        <option value={"Target 'n/a', but satisfied"}>Target 'n/a', but satisfied</option>
        <option value={"Target 'n/a', but unsatisfied"}>Target 'n/a', but unsatisfied</option>
      </select>
    </td>
  );
};
