import axios from "axios";
import { headers, jwt } from "./constants";
import * as yup from "yup";

export interface IUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isAdmin?: boolean;
  isActive?: boolean;
  initialised?: boolean;
  type?: string;
  dateOfBirth?: Date;
  retireInYears?: number;
  aiTokens?: number;
  dataTokens?: number;
  lastLogin?: Date;
}

export const userSchema = (users: IUser[]) =>
  yup.object().shape({
    firstName: yup.string().nullable().required("First name is required"),
    lastName: yup.string().nullable().required("Last name is required"),
    email: yup
      .string()
      .email()
      .nullable()
      .required("Email is required")
      .test("email", "User with this email already exists", (value, context) => {
        let user = users.find((x) => x.id !== context.parent.id && x.email === value);
        return user === null || user === undefined;
      }),
    isAdmin: yup.boolean().nullable().required("Admin state is required"),
    isActive: yup.boolean().nullable().required("Active state is required"),
    initialised: yup.boolean().nullable().optional(),
    type: yup.string().nullable().required("Auth source is required"),
  });

export let getUser = async (id: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/user/get/", {
      headers: headers,
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let list = async () => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/admin/user/list/", {
      headers: headers,
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let create = async (user: IUser) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/admin/user/create",
      { data: user },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let updateMe = async (user: IUser) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/user/update",
      { data: user },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let update = async (user: IUser) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/admin/user/update",
      { data: user },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let deleteOne = async (user: IUser) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/admin/user/delete",
      { data: user },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let initialise = async (user: IUser) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/admin/user/initialise",
      { data: user },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let wipe = async (ownerId: string) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/user/wipe",
      { data: { ownerId: ownerId } },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};
