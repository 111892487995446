import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, Tooltip } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar, faInfoCircle, faMinusCircle, faPlus, faRefresh, faSave, faStop } from "@fortawesome/free-solid-svg-icons";
import { IQuantumTool, quantumToolSchema, create, list, update, IQuantumToolTotal, deleteOne } from "../../../models/quantumTool";
import { Helmet } from "react-helmet";
import * as u from "../../../models/userModelledYear";
import { IMaskInput } from "react-imask";
import { currencyBlock, currencyBlockNoScale, percentBlock } from "../../../models/constants";

import { ToolInfo } from "../../../components/toolTipInfo";
import { DisclaimerComponent } from "../../../components/disclaimer";
import { OrbitProgress } from "react-loading-indicators";

interface QuantumToolPageProps extends BaseProps {}

export const QuantumToolPage = (props: QuantumToolPageProps) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [quantumTools, setQuantumTools] = useState<IQuantumTool[]>([]);
  const [quantumTool, setQuantumTool] = useState<IQuantumTool>();
  const [total, setTotal] = useState<IQuantumToolTotal>();
  const [userModelledYear, setUserModelledYear] = useState<u.IUserModelledYear>();
  const [triggerModelledYearUpdate, setTriggermModelledYearUpdate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [toolButtonName, setToolButtonName] = useState<string>("Initialise tool");

  const [errors, setErrors] = useState<IQuantumTool>({});

  useEffect(() => {
    if (props.profile?.id) {
      u.get(props.profile.id).then((response) => {
        let userModelledYear: u.IUserModelledYear = response.data.userModelledYear;
        setUserModelledYear(userModelledYear);
      });
    }
  }, [props?.profile]);

  useEffect(() => {
    list(props.profile?.id).then((response) => {
      let quantumTools: IQuantumTool[] = response.data.quantumTools;
      calculateAll(quantumTools);
      setIsLoading(false);
    });
  }, [userModelledYear]);

  useEffect(() => {
    calculateAll(quantumTools);
    let buttonName = quantumTools.length > 0 ? "Reset tool" : "Initialise tool";
    setToolButtonName(buttonName);
  }, [quantumTools]);

  useEffect(() => {
    if (triggerModelledYearUpdate) {
      u.update(userModelledYear).then(() => setTriggermModelledYearUpdate(false));
    }
  }, [triggerModelledYearUpdate]);

  const calculateAll = (quantumTools: IQuantumTool[]) => {
    let total: IQuantumToolTotal = {
      initialValueTotal: 0,
      modelledYearLeverageRatioTotal: 0,
      modelledYearRemainingCashFromSaleTotal: 0,
      modelledYearLoansAfterSaleTotal: 0,
      modelledYearLoansBeforeSaleTotal: 0,
      modelledYearPreTaxPassiveIncomeBuyAndHoldTotal: 0,
      modelledYearPreTaxPassiveIncomeTotal: 0,
      modelledYearPropertyValueTotal: 0,
      modelledYearPropertyValueWithNoLoansTotal: 0,
      preTaxPassiveIncomeTotal: 0,
    };
    quantumTools?.map((x) => {
      calculate(x);
    });
    quantumTools?.map((x) => {
      total.initialValueTotal += x.initialValue;
      total.modelledYearPropertyValueTotal += x.modelledYearPropertyValue;
      total.modelledYearRemainingCashFromSaleTotal += x.modelledYearRemainingCashFromSale;
      total.modelledYearLoansAfterSaleTotal += x.modelledYearLoansAfterSale;
      total.modelledYearLoansBeforeSaleTotal += x.modelledYearLoansBeforeSale;
    });
    quantumTools?.map((x) => {
      total.modelledYearLeverageRatioTotal = Math.round((total.modelledYearLoansBeforeSaleTotal / total.modelledYearPropertyValueTotal) * 100);
      x.modelledYearPropertyValueWithNoLoans = getModelledYearPropertyValueWithNoLoans(x, total);
      let value = x.modelledYearPropertyValueWithNoLoans.replace("$", "").replaceAll(",", "");
      if (value != "Test Failed") {
        total.modelledYearPropertyValueWithNoLoansTotal += parseInt(value);
        x.modelledYearPreTaxPassiveIncome = Math.round((parseInt(value) * x.netYield) / 100);
        total.modelledYearPreTaxPassiveIncomeTotal += x.modelledYearPreTaxPassiveIncome;

        x.modelledYearPreTaxPassiveIncomeBuyAndHold = getmodelledYearPreTaxPassiveIncomeBuyAndHold(x);
        total.modelledYearPreTaxPassiveIncomeBuyAndHoldTotal += x.modelledYearPreTaxPassiveIncomeBuyAndHold;
      }
    });
    setTotal(total);
    setQuantumTools(quantumTools);
  };

  const calculate = (quantumTool: IQuantumTool) => {
    quantumTool.modelledYearPropertyValue = getModelledYearPropertyValue(quantumTool);
    quantumTool.initialLeverageRatio = getInitialLeverageRatio(quantumTool);
    quantumTool.modelledYearLoansBeforeSale = getModelledYearLoansBeforeSale(quantumTool);
    quantumTool.modelledYearLeverageRatio = getModelledYearLeverageRatio(quantumTool);
    quantumTool.modelledYearLoansAfterSale = getModelledYearLoansAfterSale(quantumTool);
    quantumTool.modelledYearRemainingCashFromSale = getModelledYearRemainingCashFromSale(quantumTool);

    return quantumTool;
  };

  const getmodelledYearPreTaxPassiveIncomeBuyAndHold = (quantumTool: IQuantumTool) => {
    if (quantumTool.modelledYearLoansAfterSale === 0) {
      return 0;
    } else {
      let value = quantumTool.modelledYearPreTaxPassiveIncome - (quantumTool.modelledYearLoansAfterSale * userModelledYear?.longTermInterestRate) / 100;
      return value ? Math.round(value) : 0;
    }
  };

  const getModelledYearPropertyValue = (quantumTool: IQuantumTool) => {
    if (quantumTool.purchaseYear > userModelledYear?.noOfYears) {
      return 0;
    } else {
      let value = quantumTool.initialValue * Math.pow(1 + quantumTool.growthRate / 100, userModelledYear?.noOfYears - quantumTool?.purchaseYear);
      return value ? Math.round(value) : 0;
    }
  };

  const getInitialLeverageRatio = (quantumTool: IQuantumTool) => {
    if (quantumTool.deposit === 0) {
      return 106;
    } else {
      let value = ((quantumTool.initialValue * 1.06 - quantumTool.deposit) / quantumTool.initialValue) * 100;
      return value ? Math.round(value) : 0;
    }
  };

  const getModelledYearLoansBeforeSale = (quantumTool: IQuantumTool) => {
    try {
      return quantumTool.initialValue * (quantumTool.initialLeverageRatio / 100);
    } catch {
      return 0;
    }
  };

  const getModelledYearLeverageRatio = (quantumTool: IQuantumTool) => {
    try {
      return Math.round((quantumTool.modelledYearLoansBeforeSale / quantumTool.modelledYearPropertyValue) * 100);
    } catch {
      return 0;
    }
  };

  const getModelledYearLoansAfterSale = (quantumTool: IQuantumTool) => {
    if (quantumTool.modelledYearSellOff === "Yes") {
      return 0;
    } else {
      return quantumTool.modelledYearLoansBeforeSale;
    }
  };

  const getModelledYearRemainingCashFromSale = (quantumTool: IQuantumTool) => {
    try {
      if (quantumTool.modelledYearLoansAfterSale === 0) {
        let value =
          quantumTool.modelledYearPropertyValue -
          quantumTool.modelledYearLoansBeforeSale -
          ((quantumTool.modelledYearPropertyValue - quantumTool.initialValue) / 2) * (quantumTool.taxRate / 100);
        return Math.round(value);
      } else {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const getModelledYearPropertyValueWithNoLoans = (quantumTool: IQuantumTool, t: IQuantumToolTotal) => {
    if (t?.modelledYearRemainingCashFromSaleTotal > t?.modelledYearLoansAfterSaleTotal) {
      if (quantumTool.modelledYearSellOff === "Yes") {
        return "$0";
      } else {
        let value = "$" + quantumTool.modelledYearPropertyValue?.toLocaleString();
        return value;
      }
    } else {
      return "Test Failed";
    }
  };

  const updateQuantumtool = (key: string, value: string, x: IQuantumTool) => {
    if (!isLoading) {
      let initial = [...quantumTools];
      let quantumTool = initial.find((y) => y.id === x.id);
      let index = initial.findIndex((y) => y.id === x.id);
      quantumTool[key] = value ? parseInt(value) : 0;
      initial[index] = quantumTool;
      setQuantumTool(quantumTool);
      setQuantumTools(initial);
      return quantumTool;
    } else {
      return x;
    }
  };

  const initialValueMap: any[] = [450000, 500000, 450000, 500000, 450000, 400000];
  const initialDepositMap: any[] = [81000, 30000, 30000, 30000, 60000, 60000];
  const initialPurchaseYearMap: any[] = [0, 1, 2, 3, 5, 7];

  if (isLoading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  return (
    <>
      <Modal isOpen={modalIsOpen} toggle={(x) => setModalIsOpen(x != x)}>
        <ModalHeader>Add a property</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="txtName">Name</Label>
              {errors?.name && (
                <>
                  <br />
                  <span className="text-danger">{errors.name}</span>
                </>
              )}
              <Input
                id="txtName"
                value={quantumTool?.name}
                onChange={(e) => {
                  e.persist();
                  let purchaseYear = quantumTools[quantumTools.length - 1].purchaseYear + 1;
                  setQuantumTool((x) => ({ ...x, name: e.target.value, purchaseYear }));
                }}
              ></Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await quantumToolSchema.validateSync(quantumTool, {
                  abortEarly: false,
                });
                if (quantumTool?.id) {
                  await update(quantumTool);
                } else {
                  await create(quantumTool);
                }
                window.location.reload();
              } catch (yupErrors) {
                let errors: IQuantumTool = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
            Save
          </Button>
          <Button color="secondary" onClick={() => setModalIsOpen(false)}>
            <FontAwesomeIcon icon={faStop} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Wealth creation strategy | Asset manager</title>
      </Helmet>
      <Container>
        <h1>
          <FontAwesomeIcon icon={faHandHoldingDollar} className="mr-2"></FontAwesomeIcon> Wealth creation strategy
        </h1>
        <hr />
        {quantumTools?.length > 0 && (
          <Alert color="info">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2"></FontAwesomeIcon>You can add any number of properties by clicking on the 'Add a property'
            button, or you can simply reset the tool to it's original state by clicking on the 'Reset tool' button
          </Alert>
        )}

        {quantumTools?.length > 0 && (
          <Button
            color="primary"
            className="mb-2"
            onClick={() => {
              setErrors({});
              setQuantumTool({ ownerId: props.profile?.id });
              setModalIsOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>Add a Property
          </Button>
        )}
        {quantumTools?.length === 0 && (
          <Alert color="info">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2"></FontAwesomeIcon>Click on the 'Initialise tool' button to get started
          </Alert>
        )}

        <Button
          color="primary"
          className="mb-2 ml-2"
          onClick={async () => {
            setErrors({});
            quantumTools?.map(async (x) => {
              await deleteOne(x);
            });
            let userModelledYear: u.IUserModelledYear = { noOfYears: 13, longTermInterestRate: 6, commercialReturn: 10, ownerId: props.profile.id };
            await u.create(userModelledYear);
            setUserModelledYear(userModelledYear);
            let qs: IQuantumTool[] = [];
            for (let i = 1; i <= 6; i++) {
              let quantumTool: IQuantumTool = {
                name: "Property" + i,
                initialValue: initialValueMap[i - 1],
                purchaseYear: initialPurchaseYearMap[i - 1],
                growthRate: 7,
                deposit: initialDepositMap[i - 1],
                modelledYearSellOff: i <= 3 ? "Yes" : "No",
                taxRate: 45,
                netYield: 4,
                ownerId: props.profile.id,
              };
              await create(quantumTool);
              qs.push(quantumTool);
            }
            setQuantumTools(qs);
          }}
        >
          <FontAwesomeIcon icon={faRefresh} className="mr-2"></FontAwesomeIcon>
          {toolButtonName}
        </Button>
        {quantumTools?.length > 0 && (
          <>
            <Row className="mb-2">
              <Col>
                <Label>'Modelled year' - based on paying down all debt:</Label>
              </Col>
              <Col>
                <Input
                  id="txtModelledYear"
                  value={userModelledYear?.noOfYears}
                  type="number"
                  onChange={(e) => {
                    e.persist();
                    let noOfYears = e.target.value ? parseInt(e.target.value) : 0;
                    setUserModelledYear((x) => ({ ...x, noOfYears }));
                  }}
                  onBlur={() => {
                    setTriggermModelledYearUpdate(true);
                  }}
                ></Input>
              </Col>
            </Row>

            <Table responsive striped>
              <thead>
                <tr className="bg-dark text-light">
                  <th></th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <th key={i}>
                        <a
                          href="javascript:void(0)"
                          title="Delete this property"
                          className="text-light"
                          onClick={async () => {
                            await deleteOne(x);
                            window.location.reload();
                          }}
                        >
                          {x.name}
                          <FontAwesomeIcon className="ml-2" icon={faMinusCircle}></FontAwesomeIcon>
                        </a>
                      </th>
                    );
                  })}
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <ToolInfo id="ipv" title="Initial property value" tooltip="Theoretically assumes purchase price was the same as market valuation" />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          className="form-control"
                          value={x?.initialValue?.toString()}
                          placeholder={"0%"}
                          mask="$num"
                          lazy={false}
                          unmask={true}
                          blocks={currencyBlockNoScale}
                          onAccept={async (value: string) => {
                            try {
                              if (!isLoading) {
                                let q = updateQuantumtool("initialValue", value, x);
                                await update(q);
                              }
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td>
                    <IMaskInput
                      style={{ backgroundColor: "inherit", border: "0", minWidth: "80px" }}
                      className="form-control"
                      value={total?.initialValueTotal?.toString()}
                      placeholder={"$0"}
                      mask="$num"
                      lazy={false}
                      unmask={true}
                      blocks={currencyBlockNoScale}
                      onAccept={() => {}}
                      disabled
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo id="ppr" title="Property purchase year" tooltip="If you already have investment properties insert '0'" />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <Input
                          type="number"
                          value={x.purchaseYear}
                          onChange={(e) => {
                            e.persist();
                            updateQuantumtool("purchaseYear", e.target.value, x);
                          }}
                          onBlur={async (e) => {
                            await update(quantumTool);
                          }}
                        ></Input>
                      </td>
                    );
                  })}
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="cagr"
                      title="Compounding annual growth rate"
                      tooltip="Assumes linear growth rate. If growth rate is high earlier, the compounding effect would significantly accelerate portfolio creation, vice
                versa. 7.25% is the Australian long term average (Reserve Bank of Australia)"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          className="form-control"
                          value={x?.growthRate?.toString()}
                          placeholder={"0%"}
                          mask="num %"
                          lazy={false}
                          unmask={true}
                          blocks={percentBlock}
                          onAccept={async (value: string) => {
                            try {
                              if (!isLoading) {
                                let q = updateQuantumtool("growthRate", value, x);
                                await update(q);
                              }
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="mypr"
                      title="Modelled year property value"
                      tooltip="Theoretical value; the long term growth rate will not likely apply if holding the portfolio for less that 10 years"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          style={{ backgroundColor: "inherit", border: "0" }}
                          className="form-control"
                          value={x?.modelledYearPropertyValue?.toString()}
                          placeholder={"$0"}
                          mask="$num"
                          lazy={false}
                          unmask={true}
                          blocks={currencyBlockNoScale}
                          onAccept={() => {}}
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td>
                    <IMaskInput
                      style={{ backgroundColor: "inherit", border: "0", minWidth: "80px" }}
                      className="form-control"
                      value={total?.modelledYearPropertyValueTotal?.toString()}
                      placeholder={"$0"}
                      mask="$num"
                      lazy={false}
                      unmask={true}
                      blocks={currencyBlockNoScale}
                      onAccept={() => {}}
                      disabled
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo id="d" title="Deposit" tooltip="Amount of savings used as contribution towards deposit" />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          className="form-control"
                          value={x?.deposit?.toString()}
                          placeholder={"0%"}
                          mask="$num"
                          lazy={false}
                          unmask={true}
                          blocks={currencyBlock}
                          onAccept={async (value: string) => {
                            try {
                              if (!isLoading) {
                                let q = updateQuantumtool("deposit", value, x);
                                await update(q);
                              }
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="ilr"
                      title="Initial leverage ratio when purchased"
                      tooltip="Assumes all properties incur transaction costs at 6% of initial property value"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          style={{ backgroundColor: "inherit", border: "0" }}
                          className="form-control"
                          value={x?.initialLeverageRatio?.toString()}
                          placeholder={"0%"}
                          mask="num %"
                          lazy={false}
                          unmask={true}
                          blocks={percentBlock}
                          onAccept={() => {}}
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="mylr"
                      title="Deposit"
                      tooltip="Assumes properties have been on Interest Only loan terms and no principal amount has been paid off"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          style={{ backgroundColor: "inherit", border: "0" }}
                          className="form-control"
                          value={x?.modelledYearLeverageRatio?.toString()}
                          placeholder={"0%"}
                          mask="num %"
                          lazy={false}
                          unmask={true}
                          blocks={percentBlock}
                          onAccept={() => {}}
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td>
                    <IMaskInput
                      style={{ backgroundColor: "inherit", border: "0", minWidth: "80px" }}
                      className="form-control"
                      value={total?.modelledYearLeverageRatioTotal?.toString()}
                      placeholder={"0%"}
                      mask="num %"
                      lazy={false}
                      unmask={true}
                      blocks={percentBlock}
                      onAccept={() => {}}
                      disabled
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="mylbs"
                      title="Modelled year loans before sales"
                      tooltip="Assumes Lender Mortgage Insurance is used, in order to fastrack portfolio creation"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          style={{ backgroundColor: "inherit", border: "0" }}
                          className="form-control"
                          value={x?.modelledYearLoansBeforeSale?.toString()}
                          placeholder={"$0"}
                          mask="$num"
                          lazy={false}
                          unmask={true}
                          blocks={currencyBlockNoScale}
                          onAccept={() => {}}
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td>
                    <IMaskInput
                      style={{ backgroundColor: "inherit", border: "0", minWidth: "80px" }}
                      className="form-control"
                      value={total?.modelledYearLoansBeforeSaleTotal?.toString()}
                      placeholder={"$0"}
                      mask="$num"
                      lazy={false}
                      unmask={true}
                      blocks={currencyBlockNoScale}
                      onAccept={() => {}}
                      disabled
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="mylsopl"
                      title="Modelled year sell and pay off loans?"
                      tooltip="Key strategic decision point in order to balance (1) ability to raise cash to alleviate debt off high yield properties, vs (2) selling properties that are high capital growth performers"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <select
                          className="form-control"
                          value={x?.modelledYearSellOff}
                          onChange={async (e) => {
                            if (!isLoading) {
                              e.persist();
                              let initial = [...quantumTools];
                              let quantumTool = initial.find((y) => y.id === x.id);
                              let index = initial.findIndex((y) => y.id === x.id);
                              quantumTool.modelledYearSellOff = e.target.value;
                              initial[index] = quantumTool;
                              setQuantumTool(quantumTool);
                              setQuantumTools(initial);
                              await update(quantumTool);
                            }
                          }}
                        >
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                      </td>
                    );
                  })}
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="mtr"
                      title="Marginal tax rate"
                      tooltip="Model assumes all properties are sold in one year, in reality they should be sold in separate years to reduce capital gains tax"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          className="form-control"
                          value={x?.taxRate?.toString()}
                          placeholder={"0%"}
                          mask="num %"
                          lazy={false}
                          unmask={true}
                          blocks={percentBlock}
                          onAccept={async (value: string) => {
                            try {
                              if (!isLoading) {
                                let q = updateQuantumtool("taxRate", value, x);
                                await update(q);
                              }
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="mylas"
                      title="Modelled year loans after sales"
                      tooltip="Assumes all loans are interest only and structured so that interst only period roll over onto more interest only periods"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          style={{ backgroundColor: "inherit", border: "0" }}
                          className="form-control"
                          value={x?.modelledYearLoansAfterSale?.toString()}
                          placeholder={"$0"}
                          mask="$num"
                          lazy={false}
                          unmask={true}
                          blocks={currencyBlockNoScale}
                          onAccept={() => {}}
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td>
                    <IMaskInput
                      style={{ backgroundColor: "inherit", border: "0", minWidth: "80px" }}
                      className="form-control"
                      value={total?.modelledYearLoansAfterSaleTotal?.toString()}
                      placeholder={"$0"}
                      mask="$num"
                      lazy={false}
                      unmask={true}
                      blocks={currencyBlockNoScale}
                      onAccept={() => {}}
                      disabled
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th>
                    {" "}
                    <ToolInfo
                      id="myrc"
                      title="Modelled year remaining cash from sales (after CGT)"
                      tooltip="Test to see if 'Cash from Sales' excedes 'Loans after sales', to determine if enough cash to pay Loan amount. Should be 1.02 times the Year 10 Loan amount to compensate for selling costs (agent fees). Assumes all properties are sold in one year - in reality the best appraoch would be to spread the sales over 3 years to reduce Capital Gains Tax (CGT)"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          style={{ backgroundColor: "inherit", border: "0" }}
                          className="form-control"
                          value={x?.modelledYearRemainingCashFromSale?.toString()}
                          placeholder={"$0"}
                          mask="$num"
                          lazy={false}
                          unmask={true}
                          blocks={currencyBlockNoScale}
                          onAccept={() => {}}
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td>
                    <IMaskInput
                      style={{ backgroundColor: "inherit", border: "0", minWidth: "80px" }}
                      className="form-control"
                      value={total?.modelledYearRemainingCashFromSaleTotal?.toString()}
                      placeholder={"$0"}
                      mask="$num"
                      lazy={false}
                      unmask={true}
                      blocks={currencyBlockNoScale}
                      onAccept={() => {}}
                      disabled
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo id="mypvnl" title="Modelled year property value with no loans" tooltip="Equity in remaining properties, unencumbered by debt" />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return <td key={i}>{x.modelledYearPropertyValueWithNoLoans}</td>;
                  })}
                  <td className="pl-4">{"$" + total?.modelledYearPropertyValueWithNoLoansTotal?.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="ny"
                      title="Net Yield"
                      tooltip="Long term Australian average. Includes all holding costs except interest costs, but not capitalised renovations"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          className="form-control"
                          value={x?.netYield?.toString()}
                          placeholder={"0%"}
                          mask="num %"
                          lazy={false}
                          unmask={true}
                          blocks={percentBlock}
                          onAccept={async (value: string) => {
                            try {
                              if (!isLoading) {
                                let q = updateQuantumtool("netYield", value, x);
                                await update(q);
                              }
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="myptpi"
                      title="Modelled year pre-tax passive income"
                      tooltip="Does not factor in additional income from depreciation benefits and positive cashflow property income throughout the years. Income will increase over time as capital growth compounds and rents increase"
                    />
                  </th>
                  {quantumTools?.map((x, i) => {
                    return (
                      <td key={i}>
                        <IMaskInput
                          style={{ backgroundColor: "inherit", border: "0", minWidth: "80px" }}
                          className="form-control"
                          value={x?.modelledYearPreTaxPassiveIncome?.toString()}
                          placeholder={"$0"}
                          mask="$num"
                          lazy={false}
                          unmask={true}
                          blocks={currencyBlockNoScale}
                          onAccept={() => {}}
                          disabled
                        ></IMaskInput>
                      </td>
                    );
                  })}
                  <td>
                    <IMaskInput
                      style={{ backgroundColor: "inherit", border: "0", minWidth: "80px" }}
                      className="form-control"
                      value={total?.modelledYearPreTaxPassiveIncomeTotal?.toString()}
                      placeholder={"$0"}
                      mask="$num"
                      lazy={false}
                      unmask={true}
                      blocks={currencyBlockNoScale}
                      onAccept={() => {}}
                      disabled
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th colSpan={quantumTools.length + 2} className="bg-info text-light">
                    Buy & Hold + Commercial Development Strategy Quantum
                  </th>
                </tr>
                <tr>
                  <th>
                    <ToolInfo id="lti" title="Long term Interest rate assumption" tooltip="Assumed long term Interest Rate charged by lenders" />
                  </th>
                  <td colSpan={quantumTools.length + 1}>
                    <IMaskInput
                      className="form-control"
                      value={userModelledYear?.longTermInterestRate?.toString()}
                      placeholder={"0%"}
                      mask="num %"
                      lazy={false}
                      unmask={true}
                      blocks={percentBlock}
                      onAccept={async (value: string) => {
                        try {
                          if (!isLoading) {
                            let longTermInterestRate = value ? parseInt(value) : 0;
                            setUserModelledYear((x) => ({ ...x, longTermInterestRate }));
                            setTriggermModelledYearUpdate(true);
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="cr"
                      title="Commercial return assumption assumption"
                      tooltip="Clients receive 10-15% return per annum based on a Commercial Loan arrangement from the client into the development project"
                    />
                  </th>
                  <td colSpan={quantumTools.length + 1}>
                    <IMaskInput
                      className="form-control"
                      value={userModelledYear?.commercialReturn?.toString()}
                      placeholder={"0%"}
                      mask="num %"
                      lazy={false}
                      unmask={true}
                      blocks={percentBlock}
                      onAccept={async (value: string) => {
                        try {
                          if (!isLoading) {
                            let commercialReturn = value ? parseInt(value) : 0;
                            setUserModelledYear((x) => ({ ...x, commercialReturn }));
                            setTriggermModelledYearUpdate(true);
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ToolInfo
                      id="myrcfsaacgt"
                      title="Modelled year remaining cash from sales (after CGT)"
                      tooltip="Assumes cash wasn't used to pay off remaining properties and instead is repuposed towards the Commercial Development Strategy"
                    />
                  </th>
                  <td colSpan={quantumTools.length}></td>
                  <td>
                    <IMaskInput
                      style={{ backgroundColor: "inherit", border: "0", minWidth: "80px" }}
                      className="form-control"
                      value={total?.modelledYearRemainingCashFromSaleTotal?.toString()}
                      placeholder={"$0"}
                      mask="$num"
                      lazy={false}
                      unmask={true}
                      blocks={currencyBlockNoScale}
                      onAccept={() => {}}
                      disabled
                    ></IMaskInput>
                  </td>
                </tr>
                <tr>
                  <th>Modelled year pre-tax passive income from Buy & Hold</th>
                  {quantumTools?.map((x, i) => {
                    return <td key={i}>${x.modelledYearPreTaxPassiveIncomeBuyAndHold?.toLocaleString()}</td>;
                  })}
                  <td className="pl-4">${total?.modelledYearPreTaxPassiveIncomeBuyAndHoldTotal?.toLocaleString()}</td>
                </tr>
                <tr>
                  <th>Pre-tax passive income from Commercial Development</th>
                  <td colSpan={quantumTools.length}></td>
                  <td className="pl-4">
                    ${Math.round((total?.modelledYearRemainingCashFromSaleTotal * userModelledYear?.commercialReturn) / 100)?.toLocaleString()}
                  </td>
                </tr>
                <tr className="bg-dark text-light">
                  <th>
                    <ToolInfo
                      id="tp"
                      title="Total passive pre-tax passive income"
                      tooltip="Passive income will increase over time to the extent that it is re-invested into more development projects and property rents rise"
                    />
                  </th>
                  <td colSpan={quantumTools.length}></td>
                  <td className="pl-4">
                    $
                    {Math.round(
                      total?.modelledYearPreTaxPassiveIncomeBuyAndHoldTotal +
                        (total?.modelledYearRemainingCashFromSaleTotal * userModelledYear?.commercialReturn) / 100
                    )?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
        <DisclaimerComponent />
      </Container>
    </>
  );
};
