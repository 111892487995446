import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartColumn, faPlus, faSave, faStop } from "@fortawesome/free-solid-svg-icons";
import { IUserPropertyValuation, userPropertyValuationSchema, create, list, update } from "../../../models/userPropertyValuation";
import { PropertyValuationPage } from "./property";

import { DisclaimerComponent } from "../../../components/disclaimer";
import { OrbitProgress } from "react-loading-indicators";
import { getPropertyData } from "../../../models/openai";

interface PortfolioValuationPageProps extends BaseProps {}

export const PortfolioValuationPage = (props: PortfolioValuationPageProps) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [userPropertyValuation, setUserPropertyValuation] = useState<IUserPropertyValuation>();
  const [userPropertyValuations, setUserPropertyValuations] = useState<IUserPropertyValuation[]>([]);
  const [errors, setErrors] = useState<IUserPropertyValuation>({});
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (props.profile?.id) {
      list(props.profile?.id).then((response) => {
        setUserPropertyValuations(response.data.userPropertyValuations);

        //getPropertyData("16 Haflinger way, Byford, WA 6122, Australia");

        setIsLoading(false);
      });
    }
  }, [props.profile]);

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  return (
    <>
      <Modal isOpen={modalIsOpen} toggle={(x) => setModalIsOpen(x != x)}>
        <ModalHeader>Add a Suburb</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="txtName">Name</Label>
              {errors?.name && (
                <>
                  <br />
                  <span className="text-danger">{errors.name}</span>
                </>
              )}
              <Input
                id="txtName"
                value={userPropertyValuation?.name}
                onChange={(e) => {
                  e.persist();
                  setUserPropertyValuation((x) => ({ ...x, name: e.target.value }));
                }}
              ></Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await userPropertyValuationSchema.validateSync(userPropertyValuation, {
                  abortEarly: false,
                });
                if (userPropertyValuation?.id) {
                  await update(userPropertyValuation);
                } else {
                  await create(userPropertyValuation);
                }
                window.location.reload();
              } catch (yupErrors) {
                let errors: IUserPropertyValuation = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
            Save
          </Button>
          <Button color="secondary" onClick={() => setModalIsOpen(false)}>
            <FontAwesomeIcon icon={faStop} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Comparable Market Analysis (CMA) | Asset manager</title>
      </Helmet>
      <Container>
        <h1>
          <FontAwesomeIcon className="mr-2" icon={faChartColumn}></FontAwesomeIcon> Comparable Market Analysis (CMA)
        </h1>
        <hr />
        <Button
          color="primary"
          className="mb-2"
          onClick={() => {
            setErrors({});
            let sortOrder = userPropertyValuations.length + 1;
            setUserPropertyValuation({ sortOrder, ownerId: props.profile?.id });
            setModalIsOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>Add a new Valuation Playground
        </Button>

        <>
          <Nav tabs>
            {userPropertyValuations?.map((x, i) => {
              return (
                <NavItem key={i}>
                  <NavLink href="#" active={activeTab == i} onClick={() => setActiveTab(i)}>
                    {x.name}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
          <TabContent activeTab={activeTab}>
            {userPropertyValuations.length > 0 &&
              userPropertyValuations?.map((x, i) => {
                return (
                  <TabPane key={i} tabId={i} className="mt-2">
                    <PropertyValuationPage {...props} userPropertyValuationId={x.id} />
                  </TabPane>
                );
              })}
          </TabContent>
        </>
        <DisclaimerComponent />
      </Container>
    </>
  );
};
