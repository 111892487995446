import axios from "axios";
import { headers, jwt } from "./constants";
import * as yup from "yup";

export interface IUserAssumption {
  id?: string;
  annualRentRise?: number;
  annualAppreciation?: number;
  ownerId?: string;
}

export let create = async (userAssumption: IUserAssumption) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/userAssumption/create",
      { data: userAssumption },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let get = async (ownerId: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/userAssumption/get/", {
      headers: headers,
      params: { ownerId: ownerId },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let update = async (userAssumption: IUserAssumption) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/userAssumption/update",
      { data: userAssumption },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};
