import React, { useEffect, useState } from "react";

import { Badge, Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import {
  IPortfolioBudget,
  create,
  update,
  portfolioBudgetSchema,
  getAnnualPropertyManagementFee,
  getAnnualInitialLettingFee,
  getAnnualVacancy,
  getCalculations,
  deleteOne,
} from "../../../models/portfolioBudget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClose, faMinus, faSave, faStop } from "@fortawesome/free-solid-svg-icons";
import { IMaskInput } from "react-imask";
import { currencyBlock } from "../../../models/constants";
import DatePicker from "react-datepicker";
import enAu from "date-fns/locale/en-AU";
import { IPortfolioOwner } from "../../../models/portfolioOwner";
import { StickySave } from "../../../components/sticky/stickySave";
import moment from "moment";

interface PortfolioComponentProps extends BaseProps {
  portfolio: IPortfolioBudget;
  calculatedPortfolio: ICalculatedPortfolio;
  portfolioOwners: IPortfolioOwner[];
  onChange: (portfolio: IPortfolioBudget, calculatedPortfolio: ICalculatedPortfolio) => void;
}

export interface ICalculatedPortfolio {
  id: string;
  portfolioOwnerId: string;
  loanPercentage: number;
  loanPreLMI: number;
  loanPostLMI: number;
  depositAmount: number;
  totalCapitalRequired: number;
  annualPropertyManagementFee: number;
  annualInitialLettingFee: number;
  annualVacancy: number;
  annualInterestPayment: number;
  annualMaintanance: number;
  annualEstimatedTotals: number;
  annualTotalExpense: number;
  annualLowerRentCashflow: number;
  annualHigherRentCashflow: number;
  annualLowerRentProfitBeforeTax: number;
  annualHigherRentProfitBeforeTax: number;
  annualLowerRentProfitAfterTax: number;
  annualHigherRentProfitAfterTax: number;
  annualHigherRent: number;
}

export const PortfolioComponent = (props: PortfolioComponentProps) => {
  const numberOfWeeks: number = 52;
  const numberOfMonths: number = 12;
  const [portfolio, setPortfolio] = useState<IPortfolioBudget>(props.portfolio);
  const [calculatedPortfolio, setCalculatedPortfolio] = useState<ICalculatedPortfolio>(props.calculatedPortfolio);

  const [errors, setErrors] = useState<IPortfolioBudget>({});
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  const getRentYeild = (rent: number) => {
    let v = ((rent * numberOfWeeks) / portfolio?.purchasePrice) * 100;
    return v?.toString();
  };

  useEffect(() => {
    if (JSON.stringify(props.portfolio) !== JSON.stringify(portfolio) || JSON.stringify(props.calculatedPortfolio) !== JSON.stringify(calculatedPortfolio)) {
      props.onChange(portfolio, calculatedPortfolio);
    }
  }, [calculatedPortfolio, portfolio]);

  useEffect(() => {
    setLoading(true);
    let annualLowerRentProfitBeforeTax = calculatedPortfolio?.annualLowerRentCashflow - portfolio?.annualDepreciation;
    setCalculatedPortfolio((x) => ({ ...x, annualLowerRentProfitBeforeTax }));
    setLoading(false);
  }, [calculatedPortfolio?.annualLowerRentCashflow, portfolio?.annualDepreciation]);

  useEffect(() => {
    setLoading(true);
    let annualHigherRentProfitBeforeTax = calculatedPortfolio?.annualHigherRentCashflow - portfolio?.annualDepreciation;
    setCalculatedPortfolio((x) => ({ ...x, annualHigherRentProfitBeforeTax }));
    setLoading(false);
  }, [calculatedPortfolio?.annualLowerRentCashflow, portfolio?.annualDepreciation]);

  useEffect(() => {
    setLoading(true);
    let annualLowerRentProfitAfterTax =
      calculatedPortfolio?.annualLowerRentCashflow -
      (calculatedPortfolio?.annualLowerRentCashflow + calculatedPortfolio?.annualVacancy - portfolio?.annualDepreciation) * (portfolio?.taxRate / 100);
    setCalculatedPortfolio((x) => ({ ...x, annualLowerRentProfitAfterTax }));
    setLoading(false);
  }, [calculatedPortfolio?.annualLowerRentCashflow, portfolio?.annualDepreciation, portfolio?.taxRate]);

  useEffect(() => {
    setLoading(true);
    let annualHigherRentProfitAfterTax =
      calculatedPortfolio?.annualHigherRentCashflow -
      (calculatedPortfolio?.annualHigherRentCashflow + calculatedPortfolio?.annualVacancy - portfolio?.annualDepreciation) * (portfolio?.taxRate / 100);
    setCalculatedPortfolio((x) => ({ ...x, annualHigherRentProfitAfterTax }));
    setLoading(false);
  }, [calculatedPortfolio?.annualLowerRentCashflow, portfolio?.annualDepreciation, portfolio?.taxRate]);

  useEffect(() => {
    setLoading(true);
    let annualLowerRentCashflow = (portfolio?.weeklyLowerRent * numberOfWeeks || 0) - calculatedPortfolio?.annualTotalExpense;
    setCalculatedPortfolio((x) => ({ ...x, annualLowerRentCashflow }));
    setLoading(false);
  }, [portfolio?.weeklyLowerRent, calculatedPortfolio?.annualTotalExpense]);

  useEffect(() => {
    setLoading(true);
    let annualHigherRent = portfolio?.weeklyHigherRent ? portfolio?.weeklyHigherRent * numberOfWeeks : 0;
    let annualHigherRentCashflow = (portfolio?.weeklyHigherRent * numberOfWeeks || 0) - calculatedPortfolio?.annualTotalExpense;
    setCalculatedPortfolio((x) => ({ ...x, annualHigherRentCashflow, annualHigherRent }));
    setLoading(false);
  }, [portfolio?.weeklyHigherRent, calculatedPortfolio?.annualTotalExpense]);

  useEffect(() => {
    setLoading(true);
    let { loanPercentage } = getCalculations(portfolio);
    setCalculatedPortfolio((x) => ({ ...x, loanPercentage }));
    setLoading(false);
  }, [portfolio?.deposit]);

  useEffect(() => {
    setLoading(true);
    let { loanPreLMI, loanPostLMI } = getCalculations(portfolio);
    setCalculatedPortfolio((x) => ({ ...x, loanPreLMI, loanPostLMI }));
    setLoading(false);
  }, [portfolio?.purchasePrice, portfolio?.lendersMortgageInsurance, calculatedPortfolio?.loanPercentage]);

  useEffect(() => {
    setLoading(true);
    let depositPercentage = portfolio?.deposit ? portfolio?.deposit / 100 : 0;
    let depositAmount = portfolio?.purchasePrice ? portfolio?.purchasePrice * depositPercentage : 0;
    setCalculatedPortfolio((x) => ({ ...x, depositAmount }));
    setLoading(false);
  }, [portfolio?.deposit, portfolio?.purchasePrice]);

  useEffect(() => {
    setLoading(true);
    let { annualInterestPayment } = getCalculations(portfolio);
    setCalculatedPortfolio((x) => ({ ...x, annualInterestPayment }));
    setLoading(false);
  }, [portfolio?.interestRate, calculatedPortfolio?.loanPostLMI]);

  useEffect(() => {
    setLoading(true);
    let totalCapitalRequired =
      (calculatedPortfolio?.depositAmount || 0) +
      (portfolio?.stampDuty || 0) +
      (portfolio?.legalFees || 0) +
      (portfolio?.renovationCost || 0) +
      (portfolio?.bankCheckFee || 0) +
      (portfolio?.settlementFee || 0) +
      (portfolio?.buildingAndPestInspection || 0) +
      (portfolio?.waterEfficiencyTest || 0) +
      (portfolio?.cleaningCost || 0) +
      (portfolio?.removalistFee || 0) +
      (portfolio?.buyersAgentFee || 0) +
      (portfolio?.registrationAndTransferFee || 0) +
      (portfolio?.bankLegalFee || 0) +
      (portfolio?.bankRegistrationAndDischargeFee || 0);
    setCalculatedPortfolio((x) => ({ ...x, totalCapitalRequired }));
    setLoading(false);
  }, [
    calculatedPortfolio?.depositAmount,
    portfolio?.stampDuty,
    portfolio?.legalFees,
    portfolio?.renovationCost,
    portfolio?.bankCheckFee,
    portfolio?.settlementFee,
    portfolio?.buildingAndPestInspection,
    portfolio?.waterEfficiencyTest,
    portfolio?.cleaningCost,
    portfolio?.removalistFee,
    portfolio?.buyersAgentFee,
    portfolio?.registrationAndTransferFee,
    portfolio?.bankLegalFee,
    portfolio?.bankRegistrationAndDischargeFee,
  ]);

  useEffect(() => {
    setLoading(true);
    let annualTotalExpense =
      (portfolio?.annualCouncilRate || 0) +
      (portfolio?.annualBuildingInsurance || 0) +
      (portfolio?.annualLandlordInsurance || 0) +
      (calculatedPortfolio?.annualPropertyManagementFee || 0) +
      (calculatedPortfolio?.annualInitialLettingFee || 0) +
      (portfolio?.annualAdvertisingFee || 0) +
      (portfolio?.monthlyAdminFee ? portfolio.monthlyAdminFee * numberOfMonths : 0) +
      (calculatedPortfolio?.annualVacancy || 0) +
      (portfolio?.annualPoolMaintananceFee || 0) +
      (calculatedPortfolio?.annualInterestPayment || 0) +
      (calculatedPortfolio?.annualMaintanance || 0) +
      (portfolio?.annualBankCharges || 0) +
      (portfolio?.annualBankProPackFee || 0);
    setCalculatedPortfolio((x) => ({ ...x, annualTotalExpense }));
    setLoading(false);
  }, [
    portfolio?.annualCouncilRate,
    portfolio?.annualBuildingInsurance,
    portfolio?.annualLandlordInsurance,
    calculatedPortfolio?.annualPropertyManagementFee,
    calculatedPortfolio?.annualInitialLettingFee,
    portfolio?.annualAdvertisingFee,
    portfolio?.monthlyAdminFee,
    calculatedPortfolio?.annualVacancy,
    portfolio?.annualPoolMaintananceFee,
    calculatedPortfolio?.annualInterestPayment,
    calculatedPortfolio?.annualMaintanance,
    portfolio?.annualBankCharges,
    portfolio?.annualBankProPackFee,
  ]);

  useEffect(() => {
    if (JSON.stringify(props.portfolio) !== JSON.stringify(portfolio)) {
      setUnsavedChanges(true);
    }
  }, [portfolio]);

  useEffect(() => {
    setLoading(true);
    if (saved) {
      setTimeout(() => {
        setSaved(false);
      }, 1000);
    }
    setLoading(false);
  }, [saved]);

  useEffect(() => {
    setLoading(true);
    let annualVacancy = getAnnualVacancy(portfolio);
    let annualPropertyManagementFee = getAnnualPropertyManagementFee(portfolio);
    let annualInitialLettingFee = getAnnualInitialLettingFee(portfolio);

    setCalculatedPortfolio((x) => ({ ...x, annualVacancy, annualPropertyManagementFee, annualInitialLettingFee }));
    setLoading(false);
  }, [portfolio?.weeklyLowerRent, portfolio?.weeklyHigherRent, portfolio?.defaultRentYeild]);

  const getMonthlyPropertyManagementFee = () => {
    return ((calculatedPortfolio?.annualPropertyManagementFee || 0) / numberOfMonths)?.toString();
  };

  const getWeeklyPropertyManagementFee = () => {
    return ((calculatedPortfolio?.annualPropertyManagementFee || 0) / numberOfWeeks)?.toString();
  };

  const getMonthlyInitialLettingFee = () => {
    return ((calculatedPortfolio?.annualInitialLettingFee || 0) / numberOfMonths)?.toString();
  };

  const getweeklyInitialLettingFee = () => {
    return ((calculatedPortfolio?.annualInitialLettingFee || 0) / numberOfWeeks)?.toString();
  };

  const getMonthlyVacancy = () => {
    return (calculatedPortfolio?.annualVacancy / numberOfMonths)?.toString();
  };

  const getWeeklyVacancy = () => {
    return (calculatedPortfolio?.annualVacancy / numberOfWeeks)?.toString();
  };

  return (
    <>
      <Modal isOpen={deleteModalIsOpen} toggle={(x) => setDeleteModalIsOpen(x != x)} size="lg">
        <ModalHeader>Delete this investment property</ModalHeader>
        <ModalBody>Warning: This action cannot be undone. This data will be permanently deleted. Are you sure you want to continue?</ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={async () => {
              await deleteOne(props?.portfolio);
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={faClose} className="mr-2"></FontAwesomeIcon>
            Delete this investment
          </Button>
          <Button
            color="secondary"
            onClick={async () => {
              setDeleteModalIsOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faMinus} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {unsavedChanges && (
        <StickySave
          onClick={async () => {
            await update(portfolio);
            setUnsavedChanges(false);
            setSaved(true);
          }}
        ></StickySave>
      )}
      <Row>
        <Col className="text-center">
          <Badge color="dark text-light" pill>
            Investment Property
          </Badge>
        </Col>
      </Row>

      <h3 className="mt-2">Yield on purchase (calculated)</h3>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtLowRentalYield-${portfolio?.id}`}>Low rent</Label>
            <IMaskInput
              id={`txtLowRentalYield-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.weeklyLowerRent ? getRentYeild(portfolio?.weeklyLowerRent) : ""}
              mask="num%"
              unmask={true}
              blocks={currencyBlock}
              placeholder="0"
              disabled
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtHighRentalYield-${portfolio?.id}`}>High rent</Label>
            <IMaskInput
              id={`txtHighRentalYield-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.weeklyHigherRent ? getRentYeild(portfolio?.weeklyHigherRent)?.toString() : ""}
              mask="num%"
              unmask={true}
              blocks={currencyBlock}
              placeholder="0"
              disabled
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>

      <h3 className="mt-2">General</h3>
      <hr />

      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtxAddress-${portfolio?.id}`}>Address</Label>
            <Input
              id={`txtxAddress-${portfolio?.id}`}
              value={portfolio?.address}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({
                  ...x,
                  address: e.target.value,
                }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`ddlRentalYeild-${portfolio?.id}`}>Rental yield</Label>
            <select
              className="form-control"
              id={`ddlRentalYeild-${portfolio?.id}`}
              value={portfolio?.defaultRentYeild}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({
                  ...x,
                  defaultRentYeild: e.target.value,
                }));
              }}
            >
              <option value={"High"}>High</option>
              <option value={"Low"}>Low</option>
            </select>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`ddlPortfolioOwner-${portfolio?.id}`}>Portfolio owner</Label>
            <select
              className="form-control"
              id={`ddlPortfolioOwner-${portfolio?.id}`}
              value={portfolio?.portfolioOwnerId}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({
                  ...x,
                  portfolioOwnerId: e.target.value,
                }));
              }}
            >
              {props.portfolioOwners?.map((x, i) => {
                return (
                  <option key={i} value={x?.id}>
                    {x.name}
                  </option>
                );
              })}
            </select>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtValuation-${portfolio?.id}`}>Valuation (site + improvements)</Label>
            <IMaskInput
              id={`txtValuation-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.valuation ? portfolio?.valuation?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  valuation: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtPP-${portfolio?.id}`}>Purchase price</Label>
            <IMaskInput
              id={`txtPP-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.purchasePrice ? portfolio?.purchasePrice?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  purchasePrice: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`tstLegalFees-${portfolio?.id}`}>Legal fees</Label>
            <IMaskInput
              id={`tstLegalFees-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.legalFees ? portfolio?.legalFees?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  legalFees: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtStampDuty-${portfolio?.id}`}>Stamp duty</Label>
            <IMaskInput
              id={`txtStampDuty-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.stampDuty ? portfolio?.stampDuty?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  stampDuty: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtLMI-${portfolio?.id}`}>Lenders mortgage insurance</Label>
            <IMaskInput
              id={`txtLMI-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.lendersMortgageInsurance ? portfolio?.lendersMortgageInsurance?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  lendersMortgageInsurance: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtLoanPreLMI-${portfolio?.id}`}>Loan amount pre-LMI</Label>
            <IMaskInput
              id={`txtLoanPreLMI-${portfolio?.id}`}
              className="form-control"
              value={calculatedPortfolio?.loanPreLMI?.toString()}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              placeholder="$0"
              disabled
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtLoanPostLMI-${portfolio?.id}`}>Loan amount post-LMI</Label>
            <IMaskInput
              id={`txtLoanPostLMI-${portfolio?.id}`}
              className="form-control"
              value={calculatedPortfolio?.loanPostLMI?.toString()}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              placeholder="$0"
              disabled
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtDepositAmount-${portfolio?.id}`}>Deposit amount</Label>
            <IMaskInput
              id={`txtDepositAmount-${portfolio?.id}`}
              className="form-control"
              value={calculatedPortfolio?.depositAmount?.toString()}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              placeholder="$0"
              disabled
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtRenovationCost-${portfolio?.id}`}>Estimated renovation cost</Label>
            <IMaskInput
              id={`txtRenovationCost-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.renovationCost ? portfolio?.renovationCost?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  renovationCost: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtBankCheckFee-${portfolio?.id}`}>Bank check fee</Label>
            <IMaskInput
              id={`txtBankCheckFee-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.bankCheckFee ? portfolio?.bankCheckFee?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  bankCheckFee: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtSettlementFee-${portfolio?.id}`}>Settlement fee</Label>
            <IMaskInput
              id={`txtSettlementFee-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.settlementFee ? portfolio?.settlementFee?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  settlementFee: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtDepreciationSchedule-${portfolio?.id}`}>Depreciation schedule</Label>
            <IMaskInput
              id={`txtDepreciationSchedule-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.depreciationSchedule ? portfolio?.depreciationSchedule?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  depreciationSchedule: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtPest-${portfolio?.id}`}>Building & pest Inspection</Label>
            <IMaskInput
              id={`txtPest-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.buildingAndPestInspection ? portfolio?.buildingAndPestInspection?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  buildingAndPestInspection: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtWater-${portfolio?.id}`}>Water efficiency test</Label>
            <IMaskInput
              id={`txtWater-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.waterEfficiencyTest ? portfolio?.waterEfficiencyTest?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  waterEfficiencyTest: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtCleaning-${portfolio?.id}`}>Cleaning cost</Label>
            <IMaskInput
              id={`txtCleaning-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.cleaningCost ? portfolio?.cleaningCost?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  cleaningCost: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtRemovalist-${portfolio?.id}`}>Removalist fee</Label>
            <IMaskInput
              id={`txtRemovalist-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.removalistFee ? portfolio?.removalistFee?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  removalistFee: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtBuyersAgent-${portfolio?.id}`}>Buyers agent Fee</Label>
            <IMaskInput
              id={`txtBuyersAgent-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.buyersAgentFee ? portfolio?.buyersAgentFee?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  buyersAgentFee: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtRegistration-${portfolio?.id}`}>Registration fee & transfer Fee</Label>
            <IMaskInput
              id={`txtRegistration-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.registrationAndTransferFee ? portfolio?.registrationAndTransferFee?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  registrationAndTransferFee: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtBankLegalFee-${portfolio?.id}`}>Bank legal fee</Label>
            <IMaskInput
              id={`txtBankLegalFee-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.bankLegalFee ? portfolio?.bankLegalFee?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  bankLegalFee: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtBankRegistration-${portfolio?.id}`}>Bank registration discharge fee</Label>
            <IMaskInput
              id={`txtBankRegistration-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.bankRegistrationAndDischargeFee ? portfolio?.bankRegistrationAndDischargeFee?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  bankRegistrationAndDischargeFee: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtCaptalRequired-${portfolio?.id}`}>Total capital required</Label>
            <IMaskInput
              id={`txtCaptalRequired-${portfolio?.id}`}
              className="form-control"
              value={calculatedPortfolio?.totalCapitalRequired?.toString()}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              placeholder="$0"
              disabled
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>
      <h3 className="mt-2">Assumptions</h3>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtDeposit-${portfolio?.id}`}>Deposit</Label>
            <IMaskInput
              id={`txtDeposit-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.deposit ? portfolio?.deposit?.toString() : ""}
              lazy={false}
              mask="num%"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  deposit: v,
                }));
              }}
              placeholder="0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtLoan-${portfolio?.id}`}>Loan</Label>
            <IMaskInput
              id={`txtLoan-${portfolio?.id}`}
              className="form-control"
              value={(calculatedPortfolio?.loanPercentage || 0).toString()}
              lazy={false}
              mask="num%"
              unmask={true}
              blocks={currencyBlock}
              placeholder="0"
              disabled
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtPropertyManagement-${portfolio?.id}`}>Property mgt % (inc. GST)</Label>
            <IMaskInput
              id={`txtPropertyManagement-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.propertyManagement ? portfolio?.propertyManagement?.toString() : ""}
              lazy={false}
              mask="num%"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  propertyManagement: v,
                }));
              }}
              placeholder="0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtInterestRate-${portfolio?.id}`}>Interest rate</Label>
            <IMaskInput
              id={`txtInterestRate-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.interestRate ? portfolio?.interestRate?.toString() : ""}
              lazy={false}
              mask="num%"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  interestRate: v,
                }));
              }}
              placeholder="0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtTaxRate-${portfolio?.id}`}>Tax rate</Label>
            <IMaskInput
              id={`txtTaxRate-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.taxRate ? portfolio?.taxRate?.toString() : ""}
              lazy={false}
              mask="num%"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  taxRate: v,
                }));
              }}
              placeholder="0"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>
      <h3 className="mt-2">Finance</h3>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtSettlementDate-${portfolio?.id}`}>Settlement date</Label>
            <DatePicker
              id={`txtSettlementDate-${portfolio?.id}`}
              autoComplete="off"
              className="form-control"
              dateFormat="dd-MM-yyyy"
              locale={enAu}
              selected={portfolio?.settlementDate && new Date(portfolio?.settlementDate)}
              onChange={(date: Date) => {
                setPortfolio((x) => ({
                  ...x,
                  settlementDate: date,
                }));
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtBank-${portfolio?.id}`}>Bank name</Label>
            <Input
              id={`txtBank-${portfolio?.id}`}
              value={portfolio?.bankName || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, bankName: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtInterestOnlyPeriod-${portfolio?.id}`}>Interest only period</Label>
            <IMaskInput
              id={`txtInterestOnlyPeriod-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.interestOnlyPeriod ? portfolio?.interestOnlyPeriod?.toString() : ""}
              mask="num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  interestOnlyPeriod: v,
                }));
              }}
              placeholder="0.00"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtPAndIPeriod-${portfolio?.id}`}>Principal and interest period</Label>
            <IMaskInput
              id={`txtPAndIPeriod-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.pAndIPeriod ? portfolio?.pAndIPeriod?.toString() : ""}
              mask="num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  pAndIPeriod: v,
                }));
              }}
              placeholder="0.00"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>

      <h3 className="mt-2">Insurance info</h3>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtBuildingInsuranceNumber-${portfolio?.id}`}>Building insurance number</Label>
            <Input
              id={`txtBuildingInsuranceNumber-${portfolio?.id}`}
              value={portfolio?.buildingInsuranceNumber || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, buildingInsuranceNumber: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtLandlordInsuranceNumber-${portfolio?.id}`}>Landlord insurance number</Label>
            <Input
              id={`txtLandlordInsuranceNumber-${portfolio?.id}`}
              value={portfolio?.landLordInsuranceNumber || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, landLordInsuranceNumber: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
      </Row>

      <h3 className="mt-2">Property management</h3>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtAgencyName-${portfolio?.id}`}>Agency name</Label>
            <Input
              id={`txtAgencyName-${portfolio?.id}`}
              value={portfolio?.propertyManagementAgency || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, propertyManagementAgency: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtPropertyManagerName-${portfolio?.id}`}>Property manager</Label>
            <Input
              id={`txtPropertyManagerName-${portfolio?.id}`}
              value={portfolio?.propertyManager}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, propertyManager: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtAgencyNumber-${portfolio?.id}`}>Agency number</Label>
            <Input
              id={`txtAgencyNumber-${portfolio?.id}`}
              value={portfolio?.agencyNumber || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, agencyNumber: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtAgentNumber-${portfolio?.id}`}>Agent number</Label>
            <Input
              id={`txtAgentNumber-${portfolio?.id}`}
              value={portfolio?.agentNumber || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, agentNumber: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtAgentEmail-${portfolio?.id}`}>Email</Label>
            <Input
              id={`txtAgentEmail-${portfolio?.id}`}
              value={portfolio?.agentEmail || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, agentEmail: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtTerminationPeriod-${portfolio?.id}`}>Termination period</Label>
            <Input
              id={`txtTerminationPeriod-${portfolio?.id}`}
              value={portfolio?.terminationPeriod || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, terminationPeriod: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3 className="mt-2">Estimated expenses</h3>
          <Table>
            <colgroup>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>Weekly</th>
                <th>Monthly</th>
                <th>Annualy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <small>Council Rates (inc Water/Sewer)</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyCouncilRates-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualCouncilRate ? (portfolio?.annualCouncilRate / numberOfWeeks).toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyCouncilRates-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualCouncilRate ? (portfolio?.annualCouncilRate / numberOfMonths).toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualCouncilRates-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualCouncilRate ? portfolio?.annualCouncilRate?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        annualCouncilRate: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Building insurance</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyBuildingInsurance-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualBuildingInsurance ? (portfolio?.annualBuildingInsurance / numberOfWeeks).toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyBuildingInsurance-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualBuildingInsurance ? (portfolio?.annualBuildingInsurance / numberOfMonths).toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualBuildingInsurance-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualBuildingInsurance ? portfolio?.annualBuildingInsurance?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        annualBuildingInsurance: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Landlord insurance</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyLandlordInsurance-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualLandlordInsurance ? (portfolio?.annualLandlordInsurance / numberOfWeeks).toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMoanthlyLandlordInsurance-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualLandlordInsurance ? (portfolio?.annualLandlordInsurance / numberOfMonths).toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualLandlordInsurance-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualLandlordInsurance ? portfolio?.annualLandlordInsurance?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        annualLandlordInsurance: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Property management</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyPropertyManagement-${portfolio?.id}`}
                    className="form-control"
                    value={getWeeklyPropertyManagementFee()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyPropertyManagement-${portfolio?.id}`}
                    className="form-control"
                    value={getMonthlyPropertyManagementFee()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualPropertyManagement-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualPropertyManagementFee || 0).toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Initial leasing fee</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyInitialLeasingFee-${portfolio?.id}`}
                    className="form-control"
                    value={getweeklyInitialLettingFee()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyInitialLeasingFee-${portfolio?.id}`}
                    className="form-control"
                    value={getMonthlyInitialLettingFee()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualInitialLeasingFee-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualInitialLettingFee || 0).toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Advertising fee</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyAdvertisingFee-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualAdvertisingFee ? (portfolio?.annualAdvertisingFee / numberOfWeeks)?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyAdvertisingFee-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualAdvertisingFee ? (portfolio?.annualAdvertisingFee / numberOfMonths)?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualAdvertisingFee-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualAdvertisingFee ? portfolio?.annualAdvertisingFee?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        annualAdvertisingFee: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Admin fee</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyAdminFee-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.monthlyAdminFee ? ((portfolio?.monthlyAdminFee * numberOfMonths) / numberOfWeeks)?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyAdminFee-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.monthlyAdminFee ? portfolio?.monthlyAdminFee?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        monthlyAdminFee: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualAdminFee-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.monthlyAdminFee ? (portfolio?.monthlyAdminFee * numberOfMonths)?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Vacancy</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyVacancy-${portfolio?.id}`}
                    className="form-control"
                    value={getWeeklyVacancy()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyVacancy-${portfolio?.id}`}
                    className="form-control"
                    value={getMonthlyVacancy()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualVacancy-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualVacancy || 0).toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Pool maintanance</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyPoolMaintanance-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualPoolMaintananceFee ? (portfolio?.annualPoolMaintananceFee / numberOfWeeks)?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyPoolMaintanance-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualPoolMaintananceFee ? (portfolio?.annualPoolMaintananceFee / numberOfMonths)?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualPoolMaintanance-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualPoolMaintananceFee ? portfolio?.annualPoolMaintananceFee?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        annualPoolMaintananceFee: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Interest payments</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyInterestPayments-${portfolio?.id}`}
                    className="form-control"
                    value={(((portfolio?.interestRate ? portfolio?.interestRate / 100 : 0) * calculatedPortfolio?.loanPostLMI) / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyInterestPayments-${portfolio?.id}`}
                    className="form-control"
                    value={(((portfolio?.interestRate ? portfolio?.interestRate / 100 : 0) * calculatedPortfolio?.loanPostLMI) / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualInterestPayments-${portfolio?.id}`}
                    className="form-control"
                    value={calculatedPortfolio?.annualInterestPayment?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Maintanance</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyMaintanance-${portfolio?.id}`}
                    className="form-control"
                    value={(1500 / numberOfWeeks).toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyMaintanance-${portfolio?.id}`}
                    className="form-control"
                    value={(1500 / numberOfMonths).toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualMaintanance-${portfolio?.id}`}
                    className="form-control"
                    value={"1500"}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Bank charges</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyBankCharges-${portfolio?.id}`}
                    className="form-control"
                    value={((portfolio?.annualBankCharges || 0) / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyBankCharges-${portfolio?.id}`}
                    className="form-control"
                    value={((portfolio?.annualBankCharges || 0) / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualBankCharges-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualBankCharges ? portfolio?.annualBankCharges?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        annualBankCharges: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Bank pro pack fee</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyBankProPackFee-${portfolio?.id}`}
                    className="form-control"
                    value={((portfolio?.annualBankProPackFee || 0) / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyBankProPackFee-${portfolio?.id}`}
                    className="form-control"
                    value={((portfolio?.annualBankProPackFee || 0) / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualBankProPackFee-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualBankProPackFee ? portfolio?.annualBankProPackFee?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        annualBankProPackFee: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>
                    <b>Estimated totals</b>
                  </small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyEstimatedTotals-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualTotalExpense / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMontlyEstimatedTotals-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualTotalExpense / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualEstimatedTotals-${portfolio?.id}`}
                    className="form-control"
                    value={calculatedPortfolio?.annualTotalExpense?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
          <h3 className="mt-2">Income comparables</h3>
          <Table>
            <colgroup>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>Weekly</th>
                <th>Monthly</th>
                <th>Annualy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <small>Lower rent</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyLowerRentICLR-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.weeklyLowerRent ? portfolio?.weeklyLowerRent?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        weeklyLowerRent: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyLowerRentICLR-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.weeklyLowerRent ? ((portfolio?.weeklyLowerRent * numberOfWeeks) / numberOfMonths).toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualLowerRentICLR-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.weeklyLowerRent ? (portfolio?.weeklyLowerRent * numberOfWeeks).toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Higher rent</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyHigherRentICHR-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.weeklyHigherRent ? portfolio?.weeklyHigherRent?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        weeklyHigherRent: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyHigherRentICHR-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.weeklyHigherRent ? ((portfolio?.weeklyHigherRent * numberOfWeeks) / numberOfMonths).toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualHigherRentICHR-${portfolio?.id}`}
                    className="form-control"
                    value={calculatedPortfolio?.annualHigherRent?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
            </tbody>
          </Table>
          <h3 className="mt-2">Estimated cashflow before tax considerations</h3>
          <Table>
            <colgroup>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>Weekly</th>
                <th>Monthly</th>
                <th>Annualy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <small>Lower rent</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyLowerRentCashflowLRBT-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualLowerRentCashflow / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyLowerRentCashflowLRBT-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualLowerRentCashflow / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualLowerRentCashflowLRBT-${portfolio?.id}`}
                    className="form-control"
                    value={calculatedPortfolio?.annualLowerRentCashflow?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Higher rent</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyHigherRentHRBT-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualHigherRentCashflow / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyHigherRentHRBT-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualHigherRentCashflow / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualHigherRentHRBT-${portfolio?.id}`}
                    className="form-control"
                    value={calculatedPortfolio?.annualHigherRentCashflow?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr>
                <th>
                  <small>Depreciation</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyDeprecialtion-${portfolio?.id}`}
                    className="form-control"
                    value={(portfolio?.annualDepreciation / numberOfWeeks || 0)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyDeprecialtion-${portfolio?.id}`}
                    className="form-control"
                    value={(portfolio?.annualDepreciation / numberOfMonths || 0)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualDeprecialtion-${portfolio?.id}`}
                    className="form-control"
                    value={portfolio?.annualDepreciation ? portfolio?.annualDepreciation?.toString() : ""}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    onAccept={(value: string) => {
                      let v = value ? parseFloat(value) : null;
                      setPortfolio((x) => ({
                        ...x,
                        annualDepreciation: v,
                      }));
                    }}
                    placeholder="$0"
                  ></IMaskInput>
                </td>
              </tr>
            </tbody>
          </Table>

          <h3 className="mt-2">Estimated profit before tax considerations</h3>
          <Table>
            <colgroup>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>Weekly</th>
                <th>Monthly</th>
                <th>Annualy</th>
              </tr>
            </thead>
            <tbody>
              <tr className={calculatedPortfolio?.annualLowerRentProfitBeforeTax > 0 ? "table-success" : "table-danger"}>
                <th>
                  <small>Lower rent</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyLowerRentProfitLRP-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualLowerRentProfitBeforeTax / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyLowerRentProfitLRP-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualLowerRentProfitBeforeTax / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualLowerRentProfitLRP-${portfolio?.id}`}
                    className="form-control"
                    value={calculatedPortfolio?.annualLowerRentProfitBeforeTax?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr className={calculatedPortfolio?.annualHigherRentProfitBeforeTax > 0 ? "table-success" : "table-danger"}>
                <th>
                  <small>Higher rent</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyHigherRentProfitHRP-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualHigherRentProfitBeforeTax / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyHigherRentProfitHRP-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualHigherRentProfitBeforeTax / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualHigherRentProfitHRP-${portfolio?.id}`}
                    className="form-control"
                    value={calculatedPortfolio?.annualHigherRentProfitBeforeTax?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
            </tbody>
          </Table>

          <h3 className="mt-2">Estimated cashflow after tax considerations</h3>
          <Table>
            <colgroup>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
              <col className="col-md-3"></col>
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>Weekly</th>
                <th>Monthly</th>
                <th>Annualy</th>
              </tr>
            </thead>
            <tbody>
              <tr className={calculatedPortfolio?.annualLowerRentProfitAfterTax > 0 ? "table-success" : "table-danger"}>
                <th>
                  <small>Lower rent</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyLowerRentProfitTaxLRAT-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualLowerRentProfitAfterTax / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyLowerRentProfitTaxLRAT-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualLowerRentProfitAfterTax / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualLowerRentProfitTaxLRAT-${portfolio?.id}`}
                    className="form-control"
                    value={calculatedPortfolio?.annualLowerRentProfitAfterTax?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
              <tr className={calculatedPortfolio?.annualHigherRentProfitAfterTax > 0 ? "table-success" : "table-danger"}>
                <th>
                  <small>Higher rent</small>
                </th>
                <td>
                  <IMaskInput
                    id={`txtWeeklyHigherRentProfitTaxHRAT-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualHigherRentProfitAfterTax / numberOfWeeks)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtMonthlyHigherRentProfitTaxHRAT-${portfolio?.id}`}
                    className="form-control"
                    value={(calculatedPortfolio?.annualHigherRentProfitAfterTax / numberOfMonths)?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
                <td>
                  <IMaskInput
                    id={`txtAnnualHigherRentProfitTaxHRAT-${portfolio?.id}`}
                    className="form-control"
                    value={calculatedPortfolio?.annualHigherRentProfitAfterTax?.toString()}
                    mask="$num"
                    unmask={true}
                    blocks={currencyBlock}
                    placeholder="$0"
                    disabled
                  ></IMaskInput>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <h3 className="mt-2">Estimated cashflow after tax considerations</h3>
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtInsuranceFromDate-${portfolio?.id}`}>Insurance from</Label>
            <DatePicker
              id={`txtInsuranceFromDate-${portfolio?.id}`}
              autoComplete="off"
              className="form-control"
              dateFormat="dd-MM-yyyy"
              locale={enAu}
              selected={portfolio?.insuranceStartDate && new Date(portfolio?.insuranceStartDate)}
              onChange={(date: Date) => {
                setPortfolio((x) => ({
                  ...x,
                  insuranceStartDate: date,
                }));
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtInsuranceToDate-${portfolio?.id}`}>Insurance to</Label>
            <DatePicker
              id={`txtInsuranceToDate-${portfolio?.id}`}
              autoComplete="off"
              className="form-control"
              dateFormat="dd-MM-yyyy"
              locale={enAu}
              selected={portfolio?.insuranceEndDate && new Date(portfolio?.insuranceEndDate)}
              onChange={(date: Date) => {
                setPortfolio((x) => ({
                  ...x,
                  insuranceEndDate: date,
                }));
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtLeaseStartDate-${portfolio?.id}`}>Lease start date</Label>
            <DatePicker
              id={`txtLeaseStartDate-${portfolio?.id}`}
              autoComplete="off"
              className="form-control"
              dateFormat="dd-MM-yyyy"
              locale={enAu}
              selected={portfolio?.leaseStartDate && new Date(portfolio?.leaseStartDate)}
              onChange={(date: Date) => {
                setPortfolio((x) => ({
                  ...x,
                  leaseStartDate: date,
                  leaseEndDate: moment(date).add(1, "year").toDate(),
                }));
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtLeaseEndDate-${portfolio?.id}`}>Lease end date</Label>
            <DatePicker
              id={`txtLeaseEndDate-${portfolio?.id}`}
              autoComplete="off"
              className="form-control"
              dateFormat="dd-MM-yyyy"
              locale={enAu}
              selected={portfolio?.leaseEndDate && new Date(portfolio?.leaseEndDate)}
              onChange={(date: Date) => {
                setPortfolio((x) => ({
                  ...x,
                  leaseEndDate: date,
                }));
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtTermiteInspectionDurDate-${portfolio?.id}`}>Termite inspection due</Label>
            <DatePicker
              id={`txtTermiteInspectionDurDate-${portfolio?.id}`}
              autoComplete="off"
              className="form-control"
              dateFormat="dd-MM-yyyy"
              locale={enAu}
              selected={portfolio?.termiteInspectionDueDate && new Date(portfolio?.termiteInspectionDueDate)}
              onChange={(date: Date) => {
                setPortfolio((x) => ({
                  ...x,
                  termiteInspectionDueDate: date,
                }));
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtLeaseInspectionDate-${portfolio?.id}`}>Last Lease inspection</Label>
            <DatePicker
              id={`txtLeaseInspectionDate-${portfolio?.id}`}
              autoComplete="off"
              className="form-control"
              dateFormat="dd-MM-yyyy"
              locale={enAu}
              selected={portfolio?.lastInspectionDate && new Date(portfolio?.lastInspectionDate)}
              onChange={(date: Date) => {
                setPortfolio((x) => ({
                  ...x,
                  lastInspectionDate: date,
                }));
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtAirconServicingDate-${portfolio?.id}`}>Last Aircon servicing</Label>
            <DatePicker
              id={`txtAirconServicingDate-${portfolio?.id}`}
              autoComplete="off"
              className="form-control"
              dateFormat="dd-MM-yyyy"
              locale={enAu}
              selected={portfolio?.lastAirConServiceDate && new Date(portfolio?.lastAirConServiceDate)}
              onChange={(date: Date) => {
                setPortfolio((x) => ({
                  ...x,
                  lastAirConServiceDate: date,
                }));
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-right">
          {saved && <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-success"></FontAwesomeIcon>}
          <Button
            color="primary"
            onClick={async () => {
              await update(portfolio);
              setUnsavedChanges(false);
              setSaved(true);
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>Save
          </Button>
          <Button
            className="ml-2"
            color="danger"
            onClick={() => {
              setDeleteModalIsOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>Delete
          </Button>
        </Col>
      </Row>
    </>
  );
};
