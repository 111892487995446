import axios from "axios";
import { headers, jwt } from "./constants";
import * as yup from "yup";

export interface ISuburbVariable {
  id?: string;
  name?: string;
  type?: string;
  supplyOrDemand?: string;
  priority?: string;
  sortOrder?: number;
}

export const periorityMapping = {
  "1": "Layer 3 | Suburb Growth Factors | 1-5 Years | Priority 1st",
  "2": "Layer 3 | Suburb Growth Factors | 6-15 Years | Priority 2nd",
  "3": "Layer 2 | City/ Town Growth Factors | 1-5 Years | Priority 3rd",
  "4": "Layer 2 | City/ Town Growth Factors | 6-15 Years | Priority 4th",
};

export const suburbVariableSchema = yup.object().shape({
  name: yup.string().nullable().required("Name is required"),
});

export let list = async () => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/suburbVariable/list/", {
      headers: headers,
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let get = async (id: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/suburbVariable/get/", {
      headers: headers,
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let create = async (suburbVariable: ISuburbVariable) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/suburbVariable/create",
      { data: suburbVariable },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let update = async (suburbVariable: ISuburbVariable) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/suburbVariable/update",
      { data: suburbVariable },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let deleteOne = async (suburbVariable: ISuburbVariable) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/suburbVariable/delete",
      { data: suburbVariable },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};
