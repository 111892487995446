import axios from "axios";
import { headers, jwt } from "./constants";
import * as yup from "yup";

export interface ISuburbSelection {
  id?: string;
  name?: string;
  type?: string;
  supplyOrDemand?: string;
  priority?: string;
  meetsTarget?: string;
  targetRationale?: string;
  historicTrend?: string;
  historicTrendRationale?: string;
  userSuburbSelectionId?: string;
  sortOrder?: number;
}

export const suburbSelectionSchema = yup.object().shape({
  name: yup.string().nullable().required("Name is required"),
});

export let list = async (userSuburbSelectionId: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/suburbSelection/list/", {
      headers: headers,
      params: { userSuburbSelectionId: userSuburbSelectionId },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let get = async (id: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/suburbSelection/get/", {
      headers: headers,
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let create = async (suburbSelection: ISuburbSelection) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/suburbSelection/create",
      { data: suburbSelection },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let update = async (suburbSelection: ISuburbSelection) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/suburbSelection/update",
      { data: suburbSelection },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let deleteOne = async (suburbSelection: ISuburbSelection) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/suburbSelection/delete",
      { data: suburbSelection },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};
