import axios from "axios";
import { headers, jwt } from "./constants";
import * as yup from "yup";

export interface IQuantumTool {
  id?: string;
  name?: string;
  initialValue?: number;
  purchaseYear?: number;
  growthRate?: number;
  deposit?: number;
  modelledYearSellOff?: string;
  taxRate?: number;
  netYield?: number;
  ownerId?: string;

  //calculated
  modelledYearPropertyValue?: number;
  initialLeverageRatio?: number;
  modelledYearLeverageRatio?: number;
  modelledYearLoansBeforeSale?: number;
  modelledYearLoansAfterSale?: number;
  modelledYearRemainingCashFromSale?: number;
  modelledYearPropertyValueWithNoLoans?: string;
  modelledYearPreTaxPassiveIncome?: number;
  modelledYearPreTaxPassiveIncomeBuyAndHold?: number;
}

export interface IQuantumToolTotal {
  initialValueTotal?: number;
  modelledYearPropertyValueTotal?: number;
  modelledYearLeverageRatioTotal?: number;
  modelledYearLoansBeforeSaleTotal?: number;
  modelledYearLoansAfterSaleTotal?: number;
  modelledYearRemainingCashFromSaleTotal?: number;
  modelledYearPropertyValueWithNoLoansTotal?: number;
  modelledYearPreTaxPassiveIncomeTotal?: number;
  modelledYearPreTaxPassiveIncomeBuyAndHoldTotal?: number;
  preTaxPassiveIncomeTotal?: number;
}

export const quantumToolSchema = yup.object().shape({
  name: yup.string().nullable().required("Name is required"),
});

export let list = async (ownerId: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/quantumTool/list/", {
      headers: headers,
      params: { ownerId: ownerId },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let get = async (id: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/quantumTool/get/", {
      headers: headers,
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let create = async (quantumTool: IQuantumTool) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/quantumTool/create",
      { data: quantumTool },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let update = async (quantumTool: IQuantumTool) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/quantumTool/update",
      { data: quantumTool },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let deleteOne = async (quantumTool: IQuantumTool) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/quantumTool/delete",
      { data: quantumTool },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};
