import React, { useEffect, useState } from "react";
import { BaseProps } from "../../../models/auth";
import { IValuationVariable } from "../../../models/valuationVariable";
import { Input } from "reactstrap";
import { IPropertyValuation, update } from "../../../models/propertyValuation";
import { IMaskInput } from "react-imask";
import { currencyBlock } from "../../../models/constants";

interface PropertyValuationControlProps {
  valuationVariable: IValuationVariable;
  propertyValuation: IPropertyValuation;
  OnChange: (value: string) => void;
}

export const PropertyValuationControl = (props: PropertyValuationControlProps) => {
  const [propertyValuation, setPropertyValuation] = useState<IPropertyValuation>(props.propertyValuation);
  if (props.valuationVariable) {
    if (props.valuationVariable.type === "Simple") {
      if (!props.valuationVariable.mask || props.valuationVariable.mask === "") {
        return (
          <td>
            <Input
              placeholder={props.valuationVariable.placeholder}
              id={`txt-${props.propertyValuation.value}`}
              value={propertyValuation?.value}
              onChange={(e) => {
                e.persist();
                setPropertyValuation((x) => ({ ...x, value: e.target.value }));
              }}
              onBlur={(e) => {
                props.OnChange(e.target.value);
              }}
              title={propertyValuation.value}
            ></Input>
          </td>
        );
      } else {
        return (
          <td>
            <IMaskInput
              className="form-control"
              id={`txt-${propertyValuation.value}`}
              value={propertyValuation?.value}
              placeholder={props.valuationVariable.placeholder}
              mask={props.valuationVariable?.mask}
              onAccept={(value: string) => {
                try {
                  setPropertyValuation((x) => ({ ...x, value }));
                  props.OnChange(value);
                } catch (e) {
                  console.log(e);
                }
              }}
              title={propertyValuation.value}
            ></IMaskInput>
          </td>
        );
      }
    } else if (props.valuationVariable.type === "Currency") {
      return (
        <td>
          <IMaskInput
            className="form-control"
            id={`txt-${propertyValuation.value}`}
            value={propertyValuation?.value}
            placeholder={props.valuationVariable.placeholder}
            mask="$num"
            unmask={true}
            blocks={currencyBlock}
            onAccept={(value: string) => {
              try {
                setPropertyValuation((x) => ({ ...x, value }));
                props.OnChange(value);
              } catch (e) {
                console.log(e);
              }
            }}
            title={propertyValuation.value}
          ></IMaskInput>
        </td>
      );
    }
  } else {
    return (
      <td>
        <Input
          placeholder="Property address"
          id={`txt-${props.propertyValuation.value}`}
          value={propertyValuation?.value}
          onChange={(e) => {
            e.persist();
            setPropertyValuation((x) => ({ ...x, value: e.target.value }));
          }}
          onBlur={(e) => {
            props.OnChange(e.target.value);
          }}
          title={propertyValuation.value}
        ></Input>
      </td>
    );
  }
};
