import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { ISuburbVariable, create, deleteOne, list, update, suburbVariableSchema, periorityMapping } from "../../../models/suburbVariable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faPencil, faPlus, faSave, faStop, faTimes } from "@fortawesome/free-solid-svg-icons";
import { OrbitProgress } from "react-loading-indicators";

interface SuburbVariablePageProps extends BaseProps {}

export const SuburbVariablePage = (props: SuburbVariablePageProps) => {
  const [variables, setVariables] = useState<ISuburbVariable[]>([]);
  const [variable, setVariable] = useState<ISuburbVariable>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<ISuburbVariable>({});
  useEffect(() => {
    if (props.profile?.id) {
      list().then((response) => {
        setVariables(response.data?.suburbVariables);
        setIsLoading(false);
      });
    }
  }, [props.profile]);

  if (!props.profile?.isAdmin) {
    return <Alert color="danger">Unauthorized!!!</Alert>;
  }
  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }
  return (
    <>
      <Modal isOpen={modalIsOpen} toggle={(x) => setModalIsOpen(x != x)}>
        <ModalHeader>Manage Suburb Variable</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="txtName">Name</Label>
              {errors?.name && (
                <>
                  <br />
                  <span className="text-danger">{errors.name}</span>
                </>
              )}
              <Input
                id="txtName"
                value={variable?.name}
                onChange={(e) => {
                  e.persist();
                  setVariable((x) => ({ ...x, name: e.target.value }));
                }}
              ></Input>
              <Label for="ddlType">Type</Label>
              {errors?.type && (
                <>
                  <br />
                  <span className="text-danger">{errors.type}</span>
                </>
              )}
              <select
                className="form-control"
                id="ddlType"
                value={variable?.type}
                onChange={(e) => {
                  e.persist();
                  setVariable((x) => ({ ...x, type: e.target.value }));
                }}
              >
                <option value={""}>Select...</option>
                <option value={"Qualitative"}>Qualitative</option>
                <option value={"Quantitative"}>Quantitative</option>
              </select>
              <Label for="ddlSupplyOrDemand">Supply or demand</Label>
              {errors?.supplyOrDemand && (
                <>
                  <br />
                  <span className="text-danger">{errors.supplyOrDemand}</span>
                </>
              )}
              <select
                className="form-control"
                id="ddlSupplyOrDemand"
                value={variable?.supplyOrDemand}
                onChange={(e) => {
                  e.persist();
                  setVariable((x) => ({ ...x, supplyOrDemand: e.target.value }));
                }}
              >
                <option value={""}>Select...</option>
                <option value={"Supply"}>Supply</option>
                <option value={"Demand"}>Demand</option>
                <option value={"Both"}>Both</option>
              </select>
              <Label for="ddlPriority">Priority</Label>
              {errors?.priority && (
                <>
                  <br />
                  <span className="text-danger">{errors.priority}</span>
                </>
              )}
              <select
                className="form-control"
                id="ddlPriority"
                value={variable?.priority}
                onChange={(e) => {
                  e.persist();
                  setVariable((x) => ({ ...x, priority: e.target.value }));
                }}
              >
                <option value={""}>Select...</option>
                {Object.keys(periorityMapping).map((key, i) => {
                  return (
                    <option key={i} value={key}>
                      {periorityMapping[key]}
                    </option>
                  );
                })}
              </select>
              <Label for="txtSortOrder">Sort order</Label>
              {errors?.sortOrder && (
                <>
                  <br />
                  <span className="text-danger">{errors.sortOrder}</span>
                </>
              )}
              <Input
                type="number"
                id="txtSortOrder"
                value={variable?.sortOrder}
                onChange={(e) => {
                  e.persist();
                  setVariable((x) => ({ ...x, sortOrder: e.target.value ? parseInt(e.target.value) : null }));
                }}
              ></Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await suburbVariableSchema.validateSync(variable, {
                  abortEarly: false,
                });
                if (variable?.id) {
                  await update(variable);
                } else {
                  await create(variable);
                }
                window.location.reload();
              } catch (yupErrors) {
                let errors: ISuburbVariable = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
            Save
          </Button>
          <Button color="secondary" onClick={() => setModalIsOpen(false)}>
            <FontAwesomeIcon icon={faStop} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
          {variable?.id && (
            <Button
              color="danger"
              onClick={() => {
                deleteOne(variable);
                window.location.reload();
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Suburb Variables | Asset manager</title>
      </Helmet>

      <Container className="mt-5">
        <h1>
          <FontAwesomeIcon icon={faCog} className="mr-2"></FontAwesomeIcon> Suburb Variables
        </h1>
        <hr />
        <Button
          color="primary"
          className="mb-2"
          onClick={() => {
            setErrors({});
            setVariable({ name: null });
            setModalIsOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>Add a new suburb Variable
        </Button>
        {Object.keys(periorityMapping).map((key, i) => {
          let filteredVariable = variables.filter((x) => x.priority === key);
          return (
            <div key={i}>
              <p className="bg-dark p-3 mb-0 text-light">{periorityMapping[key]}</p>
              <Table key={i} striped responsive>
                <colgroup>
                  <col className="col-md-4"></col>
                  <col className="col-md-2"></col>
                  <col className="col-md-2"></col>
                  <col className="col-md-2"></col>
                  <col className="col-2"></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Supply or demand</th>
                    <th>Order</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredVariable?.map((x, i) => {
                    return (
                      <tr key={i}>
                        <td>{x.name}</td>
                        <td>{x.type}</td>
                        <td>{x.supplyOrDemand}</td>
                        <td>{x.sortOrder}</td>
                        <td>
                          <Button
                            color="primary"
                            onClick={() => {
                              setErrors({});
                              setVariable(x);
                              setModalIsOpen(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPencil} className="mr-1"></FontAwesomeIcon>
                            Manage
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          );
        })}
      </Container>
    </>
  );
};
