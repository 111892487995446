import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { IBudgetVariable, create, deleteOne, list, update, budgetVariableSchema } from "../../../models/budgetVariable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faPencil, faPlus, faSave, faStop, faTimes } from "@fortawesome/free-solid-svg-icons";
import { OrbitProgress } from "react-loading-indicators";

interface BudgetVariablePageProps extends BaseProps {}

export const BudgetVariablePage = (props: BudgetVariablePageProps) => {
  const [variables, setVariables] = useState<IBudgetVariable[]>([]);
  const [variable, setVariable] = useState<IBudgetVariable>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<IBudgetVariable>({});
  useEffect(() => {
    if (props.profile?.id) {
      list().then((response) => {
        setVariables(response.data?.budgetVariables);
        setIsLoading(false);
      });
    }
  }, [props.profile]);

  if (!props.profile?.isAdmin) {
    return <Alert color="danger">Unauthorized!!!</Alert>;
  }

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }
  return (
    <>
      <Modal isOpen={modalIsOpen} toggle={(x) => setModalIsOpen(x != x)}>
        <ModalHeader>Manage Budget Variable</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="txtName">Name</Label>
              {errors?.name && (
                <>
                  <br />
                  <span className="text-danger">{errors.name}</span>
                </>
              )}
              <Input
                id="txtName"
                value={variable?.name}
                onChange={(e) => {
                  e.persist();
                  setVariable((x) => ({ ...x, name: e.target.value }));
                }}
              ></Input>
              <Label for="ddlCategory">Category</Label>
              {errors?.category && (
                <>
                  <br />
                  <span className="text-danger">{errors.category}</span>
                </>
              )}
              <select
                className="form-control"
                id="ddlCategory"
                value={variable?.category}
                onChange={(e) => {
                  e.persist();
                  setVariable((x) => ({ ...x, category: e.target.value }));
                }}
              >
                <option value={""}>Select...</option>
                <option value={"1. Income"}>Income</option>
                <option value={"2. Living Expenses"}>Living Expenses</option>
                <option value={"3. Leisure/Entertainment Expenses"}>Leisure/Entertainment Expenses</option>
                <option value={"4. Car/Transport Expenses"}>Car/Transport Expenses</option>
                <option value={"5. House Expenses"}>House Expenses</option>
                <option value={"6. Other Expenses"}>Other Expenses</option>
              </select>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await budgetVariableSchema.validateSync(variable, {
                  abortEarly: false,
                });
                if (variable?.id) {
                  await update(variable);
                } else {
                  await create(variable);
                }
                window.location.reload();
              } catch (yupErrors) {
                let errors: IBudgetVariable = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
            Save
          </Button>
          <Button color="secondary" onClick={() => setModalIsOpen(false)}>
            <FontAwesomeIcon icon={faStop} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
          {variable?.id && (
            <Button
              color="danger"
              onClick={() => {
                deleteOne(variable);
                window.location.reload();
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Budget Variables | Asset manager</title>
      </Helmet>

      <Container className="mt-5">
        <h1>
          <FontAwesomeIcon icon={faCog} className="mr-2"></FontAwesomeIcon> Budget Variables
        </h1>
        <hr />
        <Button
          color="primary"
          className="mb-2"
          onClick={() => {
            setErrors({});
            setVariable({ name: null, category: null });
            setModalIsOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>Add a new Budget Variable
        </Button>

        <Table striped>
          <thead>
            <tr>
              <th scope="col-4">Name</th>
              <th scope="col-4">Category</th>
              <th scope="col-4"></th>
            </tr>
          </thead>
          <tbody>
            {variables?.map((x, i) => {
              return (
                <tr key={i}>
                  <td>{x.name}</td>
                  <td>{x.category}</td>
                  <td scope="row">
                    <Button
                      color="primary"
                      onClick={() => {
                        setErrors({});
                        setVariable(x);
                        setModalIsOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPencil} className="mr-1"></FontAwesomeIcon>
                      Manage
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
