import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { jwt } from "../../models/constants";
import { Alert } from "reactstrap";
import { OrbitProgress } from "react-loading-indicators";
import { updateLogin } from "../../models/auth";

export const HomePage = () => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  useEffect(() => {
    var token = JSON.parse(localStorage.getItem(jwt));
    if (token) {
      window.location.href = "/dashboard";
    } else {
      if (query.get("unauthorised") === "true") {
        setErrorMessage("Login failed!");
      }
    }
  }, []);

  if (isLoading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Asset Manager</title>
      </Helmet>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image">
                    <div className="p-5">
                      <Alert color="warning">
                        <p>
                          Disclaimer: The IP of the web site belongs to P.K Gupta. If you wish to get access to this web-site, please visit{" "}
                          <a href="https://consultingbypk.com.au/" target="_blank">
                            https://consultingbypk.com.au/
                          </a>
                        </p>
                        <p>At this time, this web site is not meant for commercial use.</p>
                      </Alert>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Please login</h1>
                      </div>
                      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                      <GoogleLogin
                        ux_mode="popup"
                        onSuccess={async (credentialResponse: CredentialResponse) => {
                          localStorage.setItem(jwt, JSON.stringify(credentialResponse.credential));
                          await updateLogin(credentialResponse.credential);
                          window.location.href = "/dashboard";
                        }}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                        text="signin"
                        theme="filled_blue"
                        type="standard"
                        size="large"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">Powered by Acader Pty Ltd. All rights Reserved</div>
        </div>
      </footer>
    </>
  );
};
