import React, { useEffect, useState } from "react";

import { Badge, Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { BaseProps } from "../../../models/auth";
import { IPortfolioBudget, update } from "../../../models/portfolioBudget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faSave, faStop } from "@fortawesome/free-solid-svg-icons";
import { IMaskInput } from "react-imask";
import { currencyBlock } from "../../../models/constants";
import DatePicker from "react-datepicker";
import enAu from "date-fns/locale/en-AU";
import { IPortfolioOwner } from "../../../models/portfolioOwner";
import { StickySave } from "../../../components/sticky/stickySave";
import moment from "moment";

interface PPORComponentProps extends BaseProps {
  portfolio: IPortfolioBudget;
  portfolioOwners: IPortfolioOwner[];
}

export const PPORComponent = (props: PPORComponentProps) => {
  const [portfolio, setPortfolio] = useState<IPortfolioBudget>(props.portfolio);
  const [saved, setSaved] = useState<boolean>(false);

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false);
      }, 1000);
    }
  }, [saved]);

  return (
    <>
      <Row>
        <Col className="text-center">
          <Badge color="dark text-light" pill>
            Primary place of residence
          </Badge>
        </Col>
      </Row>

      <h3 className="mt-2">General</h3>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtxAddress-${portfolio?.id}`}>Address</Label>
            <Input
              id={`txtxAddress-${portfolio?.id}`}
              value={portfolio?.address}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({
                  ...x,
                  address: e.target.value,
                }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtValuation-${portfolio?.id}`}>Valuation (site + improvements)</Label>
            <IMaskInput
              id={`txtValuation-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.valuation ? portfolio?.valuation?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  valuation: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtPP-${portfolio?.id}`}>Purchase price</Label>
            <IMaskInput
              id={`txtPP-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.purchasePrice ? portfolio?.purchasePrice?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  purchasePrice: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtoffset-${portfolio?.id}`}>Offset balance</Label>
            <IMaskInput
              id={`txtoffset-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.offsetAmount ? portfolio?.offsetAmount?.toString() : ""}
              mask="$num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  offsetAmount: v,
                }));
              }}
              placeholder="$0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtDeposit-${portfolio?.id}`}>Deposit</Label>
            <IMaskInput
              id={`txtDeposit-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.deposit ? portfolio?.deposit?.toString() : ""}
              lazy={false}
              mask="num%"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  deposit: v,
                }));
              }}
              placeholder="0"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtInterestRate-${portfolio?.id}`}>Interest rate</Label>
            <IMaskInput
              id={`txtInterestRate-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.interestRate ? portfolio?.interestRate?.toString() : ""}
              lazy={false}
              mask="num%"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  interestRate: v,
                }));
              }}
              placeholder="0"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>

      <h3 className="mt-2">Finance</h3>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtSettlementDate-${portfolio?.id}`}>Settlement date</Label>
            <DatePicker
              id={`txtSettlementDate-${portfolio?.id}`}
              autoComplete="off"
              className="form-control"
              dateFormat="dd-MM-yyyy"
              locale={enAu}
              selected={portfolio?.settlementDate && new Date(portfolio?.settlementDate)}
              onChange={(date: Date) => {
                setPortfolio((x) => ({
                  ...x,
                  settlementDate: date,
                }));
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtBank-${portfolio?.id}`}>Bank name</Label>
            <Input
              id={`txtBank-${portfolio?.id}`}
              value={portfolio?.bankName || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, bankName: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtInterestOnlyPeriod-${portfolio?.id}`}>Interest only period</Label>
            <IMaskInput
              id={`txtInterestOnlyPeriod-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.interestOnlyPeriod ? portfolio?.interestOnlyPeriod?.toString() : ""}
              mask="num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  interestOnlyPeriod: v,
                }));
              }}
              placeholder="0.00"
            ></IMaskInput>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for={`txtPAndIPeriod-${portfolio?.id}`}>Principal and interest period</Label>
            <IMaskInput
              id={`txtPAndIPeriod-${portfolio?.id}`}
              className="form-control"
              value={portfolio?.pAndIPeriod ? portfolio?.pAndIPeriod?.toString() : ""}
              mask="num"
              unmask={true}
              blocks={currencyBlock}
              onAccept={(value: string) => {
                let v = value ? parseFloat(value) : null;
                setPortfolio((x) => ({
                  ...x,
                  pAndIPeriod: v,
                }));
              }}
              placeholder="0.00"
            ></IMaskInput>
          </FormGroup>
        </Col>
      </Row>

      <h3 className="mt-2">Insurance info</h3>
      <hr />
      <Row>
        <Col>
          <FormGroup>
            <Label for={`txtBuildingInsuranceNumber-${portfolio?.id}`}>Building insurance number</Label>
            <Input
              id={`txtBuildingInsuranceNumber-${portfolio?.id}`}
              value={portfolio?.buildingInsuranceNumber || undefined}
              onChange={(e) => {
                e.persist();
                setPortfolio((x) => ({ ...x, buildingInsuranceNumber: e.target.value }));
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col></Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-right">
          {saved && <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-success"></FontAwesomeIcon>}
          <Button
            color="primary"
            onClick={async () => {
              await update(portfolio);
              setSaved(true);
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>Save
          </Button>
        </Col>
      </Row>
    </>
  );
};
