import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { BaseProps } from "../../models/auth";
import { IUserBudget, create, deleteOne, list, update, userBudgetSchema } from "../../models/userBudget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave, faStop, faTimes } from "@fortawesome/free-solid-svg-icons";
import { IMaskInput } from "react-imask";
import { currencyBlock } from "../../models/constants";

import { DisclaimerComponent } from "../../components/disclaimer";
import { OrbitProgress } from "react-loading-indicators";

interface HouseholdBudgetPageProps extends BaseProps {}

export const HouseholdBudgetPage = (props: HouseholdBudgetPageProps) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [budgets, setBudgets] = useState<IUserBudget[]>([]);
  const [budget, setBudget] = useState<IUserBudget>();
  const [grandTotal, setGrandTotal] = useState<number>();
  const [errors, setErrors] = useState<IUserBudget>({});

  useEffect(() => {
    if (props.profile?.id) {
      list(props.profile?.id).then((response) => {
        setBudgets(response.data.userBudgets);
        let categories: string[] = [];
        response.data.userBudgets.map((x) => {
          if (categories.indexOf(x.category) === -1) {
            categories.push(x.category);
          }
        });
        setCategories(categories);
        setIsLoading(false);
      });
    }
  }, [props.profile]);

  useEffect(() => {
    let total = 0;
    budgets?.map((x) => {
      if (x.category === "1. Income" && x.monthlyExpense) {
        total += x.monthlyExpense;
      } else {
        total -= x.monthlyExpense;
      }
    });
    setGrandTotal(total);
  }, [budgets]);

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  return (
    <>
      <Modal isOpen={modalIsOpen} toggle={(x) => setModalIsOpen(x != x)}>
        <ModalHeader>Add a new budget</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label for="txtName">Name</Label>
              {errors?.name && (
                <>
                  <br />
                  <span className="text-danger">{errors.name}</span>
                </>
              )}
              <Input
                id="txtName"
                value={budget?.name}
                onChange={(e) => {
                  e.persist();
                  setBudget((x) => ({ ...x, name: e.target.value }));
                }}
              ></Input>
              <Label for="ddlCategory">Category</Label>
              {errors?.category && (
                <>
                  <br />
                  <span className="text-danger">{errors.category}</span>
                </>
              )}
              <select
                className="form-control"
                id="ddlCategory"
                value={budget?.category}
                onChange={(e) => {
                  e.persist();
                  setBudget((x) => ({ ...x, category: e.target.value }));
                }}
              >
                <option value={""}>Select...</option>
                <option value={"1. Income"}>Income</option>
                <option value={"2. Living Expenses"}>Living Expenses</option>
                <option value={"3. Leisure/Entertainment Expenses"}>Leisure/Entertainment Expenses</option>
                <option value={"4. Car/Transport Expenses"}>Car/Transport Expenses</option>
                <option value={"5. House Expenses"}>House Expenses</option>
                <option value={"6. Other Expenses"}>Other Expenses</option>
              </select>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await userBudgetSchema.validateSync(budget, {
                  abortEarly: false,
                });
                if (budget?.id) {
                  await update(budget);
                } else {
                  await create(budget);
                }
                window.location.reload();
              } catch (yupErrors) {
                let errors: IUserBudget = {};
                yupErrors.inner.map((x: any) => {
                  errors[x.path] = x.message;
                });
                setErrors(errors);
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
            Save
          </Button>
          <Button color="secondary" onClick={() => setModalIsOpen(false)}>
            <FontAwesomeIcon icon={faStop} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Household budget | Asset manager</title>
      </Helmet>
      <h1>Household budget</h1>

      <Container className="mt-5">
        <Button
          color="primary"
          className="mb-2"
          onClick={() => {
            setErrors({});
            let sortOrder = budgets.length + 1;
            setBudget({ sortOrder, ownerId: props.profile?.id });
            setModalIsOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>Add a new Budget
        </Button>

        {categories?.map((x, i) => {
          let totalExpense: number = 0;
          return (
            <Table key={i}>
              <colgroup>
                <col className="col-md-6"></col>
                <col className="col-md-2"></col>
                <col className="col-md-2"></col>
                <col className="col-md-2"></col>
              </colgroup>
              <thead>
                <tr className="table-secondary">
                  <th>{x}</th>
                  <th>Month</th>
                  <th>Year</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {budgets
                  ?.filter((y) => y.category === x)
                  .map((z, k) => {
                    totalExpense += z.monthlyExpense ? z.monthlyExpense : 0;
                    return (
                      <tr key={k}>
                        <td>{z.name}</td>
                        <td>
                          <IMaskInput
                            className="form-control"
                            value={z.monthlyExpense ? z.monthlyExpense?.toString() : ""}
                            mask="$num"
                            unmask={true}
                            blocks={currencyBlock}
                            onAccept={(value: string) => {
                              let all = [...budgets];
                              let b = all.find((d) => d.id === z.id);
                              try {
                                b.monthlyExpense = value && value !== "" ? parseFloat(value) : null;
                                setBudgets(all);
                                setBudget(b);
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                            onBlur={async () => {
                              await update(budget);
                            }}
                            placeholder="$0"
                          ></IMaskInput>
                        </td>
                        <td>
                          <IMaskInput
                            className="form-control"
                            value={z.monthlyExpense && (z.monthlyExpense * 12).toString()}
                            placeholder="$0"
                            mask="$num"
                            blocks={currencyBlock}
                            disabled
                          ></IMaskInput>
                        </td>
                        <td>
                          <Button
                            color="danger"
                            onClick={async () => {
                              await deleteOne(z);
                              let all = [...budgets];
                              let remaining = all.filter((x) => x.id !== z.id);
                              setBudgets(remaining);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                <tr className="table-secondary">
                  <th>Total</th>
                  <th>
                    <IMaskInput value={totalExpense.toString()} placeholder="$0" mask="$num" blocks={currencyBlock} disabled></IMaskInput>
                  </th>
                  <th>
                    <IMaskInput value={(totalExpense * 12).toString()} placeholder="$0" mask="$num" blocks={currencyBlock} disabled></IMaskInput>
                  </th>
                  <th></th>
                </tr>
                {categories?.length - 1 === i && (
                  <tr className={grandTotal > 0 ? "table-success" : "table-danger"}>
                    <th>Surplus or Deficit</th>
                    <th>
                      <IMaskInput value={grandTotal?.toString()} placeholder="$0" mask="$num" blocks={currencyBlock} disabled></IMaskInput>
                    </th>
                    <th>
                      <IMaskInput
                        value={grandTotal ? (grandTotal * 12).toString() : "0"}
                        placeholder="$0"
                        mask="$num"
                        blocks={currencyBlock}
                        disabled
                      ></IMaskInput>
                    </th>
                    <th></th>
                  </tr>
                )}
              </tbody>
            </Table>
          );
        })}
        <DisclaimerComponent />
      </Container>
    </>
  );
};
