/* eslint-disable react/jsx-no-target-blank */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { SecureLayout } from "./pages/secure";
import { HomePage } from "./pages/public/home";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";

export interface LayoutProps {}

export const Layout = (props: LayoutProps) => {
  useEffect(() => {
    login();
  }, []);
  const login = useGoogleLogin({
    onSuccess: (codeResponse: TokenResponse) => {
      window.$user = codeResponse;
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <>
      <div>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/:route" component={SecureLayout}></Route>
        </Switch>
      </div>
    </>
  );
};
