import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { IUser, getUser, wipe } from "../../../models/user";
import { BaseProps } from "../../../models/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCog, faMinus, faSave, faUser } from "@fortawesome/free-solid-svg-icons";
import { OrbitProgress } from "react-loading-indicators";

interface SettingsProps extends BaseProps {
  onAccountClose: () => void;
}

export const Settings = (props: SettingsProps) => {
  const [user, setUser] = useState<IUser>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (props.profile?.id) {
      getUser(props.profile?.id).then((response) => {
        setUser(response.data?.user);
        setIsLoading(false);
      });
    }
  }, [props.profile]);

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Settings | Asset manager</title>
      </Helmet>

      <Modal isOpen={modalIsOpen} toggle={(x) => setModalIsOpen(x != x)} size="lg">
        <ModalHeader>Delete my account</ModalHeader>
        <ModalBody>Warning: This action cannot be undone. All your data will be permanently deleted. Are you sure you want to continue?</ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={async () => {
              await wipe(props.profile?.id);
              props.onAccountClose();
            }}
          >
            <FontAwesomeIcon icon={faClose} className="mr-2"></FontAwesomeIcon>
            Delete my account
          </Button>
          <Button
            color="secondary"
            onClick={async () => {
              setModalIsOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faMinus} className="mr-2"></FontAwesomeIcon>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Container className="mt-5">
        <h1>
          <FontAwesomeIcon icon={faUser} className="mr-2"></FontAwesomeIcon> My Profile
        </h1>
        <hr />
        <Row>
          <Col>{!user?.isActive && <Alert color="warning">Your account is currently inactive</Alert>}</Col>
        </Row>
        <Row>
          <Col>
            <Label for="txtName">Name</Label>
            <Input readOnly value={props.profile?.name} id="txtName"></Input>
          </Col>
          <Col>
            <Label for="txtEmail">Email</Label>
            <Input readOnly value={props.profile?.email} id="txtEmail"></Input>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            {!props.profile?.isAdmin && (
              <Button
                color="danger"
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faClose} className="mr-2"></FontAwesomeIcon>
                Delete account
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
