import axios from "axios";
import { headers, jwt } from "./constants";
import * as yup from "yup";

export interface IUserBudget {
  id?: string;
  name?: string;
  category?: string;
  ownerId?: string;
  monthlyExpense?: number;
  sortOrder?: number;
}

export const userBudgetSchema = yup.object().shape({
  name: yup.string().nullable().required("Name is required"),
  category: yup.string().nullable().required("Category is required"),
});

export let list = async (ownerId: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/budget/list/", {
      headers: headers,
      params: { ownerId: ownerId },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let get = async (id: string) => {
  let hasError = false;
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/budget/get/", {
      headers: headers,
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { data: response.data, hasError };
};

export let create = async (budget: IUserBudget) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/budget/create",
      { data: budget },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let update = async (budget: IUserBudget) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/budget/update",
      { data: budget },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};

export let deleteOne = async (budget: IUserBudget) => {
  let hasError = false;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/budget/delete",
      { data: budget },
      {
        headers: headers,
      }
    )
    .catch(function (error) {
      if (error.response) {
        hasError = true;
      }
    });
  return { hasError };
};
